import React, { useState } from "react";
import PropTypes from "prop-types";
import * as S from "./styles";
import { RowResultReclamaciones } from "../RowResultReclamaciones";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSort,
  faSortUp,
  faSortDown,
} from "@fortawesome/free-solid-svg-icons";

export const ModuleResultReclamaciones = ({
  title,
  metadata,
  data,
  selectedIds,
  allSelected,
  handleChangeSelection,
  handleChangeAllSelection,
  sorting,
}) => {
  const [currentSort, setCurrentSort] = useState({});
  const infoRecords = data.informacionRegistros;

  const textTitle = () => {
    const records = infoRecords
      ? `(${infoRecords.registrosMostrados} de ${infoRecords.totalRegistros})`
      : "";
    return `${title} - ${data.desTipoDocumento || "Cargando..."} ${records}`;
  };

  const textFoot = () => {
    if (infoRecords === undefined) return "";
    if (infoRecords?.totalRegistros === 0)
      return "No se han encontrado resultados";
    return "No hay más resultados";
  };

  const sort = (field) => {
    let direction;
    if (field !== currentSort.field) direction = 1;
    else direction = currentSort.direction === 1 ? 2 : 1;
    const newSort = { field, direction };
    setCurrentSort(newSort);
    sorting(newSort);
  };

  return (
    <S.Wrapper>
      <S.Header>
        <p>{textTitle()}</p>
      </S.Header>
      <S.Content>
        <table>
          <thead>
            <S.TrColumnTitles>
              <th>
                <input
                  type="checkbox"
                  id="all"
                  checked={allSelected}
                  onChange={handleChangeAllSelection}
                  ref={(input) =>
                    input && (input.indeterminate = allSelected === 2)
                  }
                />
              </th>
              {metadata.map((e) => (
                <S.ButtonTh
                  key={e.label}
                  scope="col"
                  active={e.name === currentSort.field}
                >
                  {e.label}
                  <button
                    onClick={() => sort(e.name)}
                    disabled={!infoRecords || infoRecords?.totalRegistros <= 1}
                  >
                    <FontAwesomeIcon
                      icon={
                        e.name !== currentSort.field
                          ? faSort
                          : currentSort.direction === 1
                          ? faSortUp
                          : faSortDown
                      }
                    />
                  </button>
                </S.ButtonTh>
              ))}
              <th />
            </S.TrColumnTitles>
          </thead>
          <tbody>
            {data.reclamaciones?.map((reclamacion) => (
              <RowResultReclamaciones
                key={reclamacion.idTarea}
                metadata={metadata}
                data={reclamacion}
                selected={selectedIds.includes(reclamacion.idTarea)}
                handleChangeSelection={handleChangeSelection}
              />
            ))}
          </tbody>
          <tfoot>
            <tr>
              <S.TdFoot colSpan={metadata.length + 2}>
                <p>{textFoot()}</p>
              </S.TdFoot>
            </tr>
          </tfoot>
        </table>
      </S.Content>
    </S.Wrapper>
  );
};

ModuleResultReclamaciones.propTypes = {
  title: PropTypes.string,
  metadata: PropTypes.array.isRequired,
  data: PropTypes.object.isRequired,
  selectedIds: PropTypes.array,
  allSelected: PropTypes.number,
  handleChangeSelection: PropTypes.func,
  handleChangeAllSelection: PropTypes.func,
  sorting: PropTypes.func,
};
