import React from "react";
import PropTypes from "prop-types";
import * as S from "./styles";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare } from "@fortawesome/free-solid-svg-icons";

export const RowResultNotarios = ({ metadata, data }) => {
  return (
    <S.Tr>
      {metadata.map((e) => {
        return <td key={e.name}>{data[e.name]?.etiqueta || data[e.name]}</td>;
      })}
      <S.TdButtons>
        <Link to={"/Notario/" + data.id}>
          <button>
            <FontAwesomeIcon icon={faPenToSquare} />
          </button>
        </Link>
      </S.TdButtons>
    </S.Tr>
  );
};

RowResultNotarios.propTypes = {
  metadata: PropTypes.array.isRequired,
  data: PropTypes.object.isRequired,
};
