import React, { useState } from "react";
import PropTypes from "prop-types";
import * as S from "./styles";
import * as API from "../../api";
import { COLORES } from "../../constants";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleInfo,
  faCalendarDays,
  faBriefcase,
  faFile,
  faDownload,
} from "@fortawesome/free-solid-svg-icons";

export const ModuleEstadosDocumentacion = ({ data, setLoading, toast }) => {
  const [openedState, setOpenedState] = useState("");

  const arrayEstados = data.estados;
  const numStates = arrayEstados?.length;

  const minStates = 2;
  const maxStates = 7;
  const minWidth = 50 - 1;
  const maxWidth = 100 / (1 / (maxStates - 1) + 1) - 1;
  const slope = (maxWidth - minWidth) / (maxStates - minStates);
  const yIntercept = minWidth - minStates * slope;

  const divWidth = yIntercept + arrayEstados?.length * slope;
  const divWidthTexts =
    (divWidth + divWidth / (arrayEstados?.length - 1)).toString() + "%";
  const divStateHeight = 3;
  const cx = (i) => (i * (100 / (numStates - 1))).toString() + "%";
  const cy = ((divStateHeight * 16) / 2).toString();
  const xText = (i) => (i * (100 / (numStates - 1)) - 0.5).toString() + "%";
  const yText = ((divStateHeight * 16) / 2 + 4.5).toString();

  const handleStateClick = (state) => {
    if (openedState === state) setOpenedState("");
    else setOpenedState(state);
  };

  const downloadDocument = ({ idDocumento, nombreDocumento }) => {
    setLoading(true);
    const data = { id: idDocumento, nombreDocumento };

    API.descargarDocumento(data)
      .then((res) => {
        const url = URL.createObjectURL(new Blob([res.data]));
        const filename = res.headers["content-disposition"]
          .split(";")[1]
          .split("=")[1];
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", filename);
        link.click();
      })
      .catch((error) => {
        toast.error("Error al descargar documento: " + error.msg?.Message);
        API.DEVELOP && console.log(error);
      })
      .finally(() => setLoading(false));
  };

  return (
    <S.Wrapper>
      <S.Header>
        <p>Estados y Documentación de la Tarea</p>
      </S.Header>
      <S.Content>
        <S.Names divWidth={divWidthTexts}>
          {arrayEstados?.map((estado, index) => (
            <div key={estado.descripcionProceso}>
              <p className={estado.estaActivo ? "highlighted" : undefined}>
                {estado.descripcionProceso}
              </p>
            </div>
          ))}
        </S.Names>
        <S.States
          divWidth={divWidth.toString() + "%"}
          divHeight={divStateHeight.toString() + "rem"}
        >
          <svg width="100%" height="50" xmlns="http://www.w3.org/2000/svg">
            <line
              x1="0"
              y1={cy}
              x2="100%"
              y2={cy}
              strokeWidth="1"
              stroke={COLORES.STATUS_CARGA.BORDER}
            />
            {arrayEstados?.map((estado, index) => (
              <React.Fragment key={index}>
                <circle
                  cx={cx(index)}
                  cy={cy}
                  r="10"
                  strokeWidth="1"
                  stroke={COLORES.STATUS_CARGA.BORDER}
                  fill={
                    estado.estaActivo
                      ? COLORES.STATUS_CARGA.ACTIVE_FILL
                      : COLORES.STATUS_CARGA.NO_ACTIVE_FILL
                  }
                  onClick={() =>
                    estado.puedeAbrir
                      ? handleStateClick(estado.descripcionProceso)
                      : {}
                  }
                />
                <text
                  x={xText(index)}
                  y={yText}
                  fill={
                    estado.estaActivo
                      ? COLORES.STATUS_CARGA.ACTIVE_FONT
                      : COLORES.STATUS_CARGA.NO_ACTIVE_FONT
                  }
                  onClick={() =>
                    estado.puedeAbrir
                      ? handleStateClick(estado.descripcionProceso)
                      : {}
                  }
                >
                  {estado.estaCompletado ? "✓" : index + 1}
                </text>
              </React.Fragment>
            ))}
          </svg>
        </S.States>
        <S.StateInfo divWidth={divWidthTexts}>
          {arrayEstados?.map((estado, index) =>
            openedState === estado.descripcionProceso ? (
              <div key={estado.descripcionProceso} className="opened">
                <p className="title">
                  <FontAwesomeIcon icon={faCircleInfo} />
                  Estado {estado.descripcionProceso}
                </p>
                <p>
                  <FontAwesomeIcon icon={faCalendarDays} />
                  Fecha Estado: {estado.fechaEstado}
                </p>
                {estado.expedientes.map((exp) => (
                  <React.Fragment key={exp.idExpedienteSae}>
                    <hr />
                    <p>
                      <FontAwesomeIcon icon={faBriefcase} />
                      {`Expediente: ${exp.idExpedienteSae} - Tipo de Procedimiento: ${exp.tipoProcedimiento}`}
                    </p>
                    {exp.documentos.length > 0 && (
                      <>
                        <p>
                          <FontAwesomeIcon icon={faFile} />
                          Documentos Asociados al Expediente:
                        </p>
                        <div id="documentos">
                          {exp.documentos?.map((doc) => (
                            <p key={doc.idDocumento}>
                              {`${doc.tipoDocumento} - Fecha Subida: ${doc.fechaSubida}`}
                              <FontAwesomeIcon
                                icon={faDownload}
                                onClick={() => downloadDocument(doc)}
                              />
                            </p>
                          ))}
                        </div>
                      </>
                    )}
                  </React.Fragment>
                ))}
              </div>
            ) : (
              <div key={estado.descripcionProceso}></div>
            )
          )}
        </S.StateInfo>
      </S.Content>
    </S.Wrapper>
  );
};

ModuleEstadosDocumentacion.propTypes = {
  data: PropTypes.object,
  setLoading: PropTypes.func,
  toast: PropTypes.func,
};
