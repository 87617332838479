import styled from "styled-components/macro";
import { COLORES } from "../../constants";

export const Tr = styled.tr`
  height: 1.5rem;

  :hover {
    background-color: ${COLORES.ROW_HOVER};
  }

  input[type="checkbox"] {
    vertical-align: bottom;
  }
`;
