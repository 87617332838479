import React from "react";
import PropTypes from "prop-types";
import * as S from "./styles";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCirclePlus,
  faEye,
  faRectangleXmark,
} from "@fortawesome/free-solid-svg-icons";

export const ModuleRevisionTareasTitularidad = ({
  data,
  metadata,
  requestAdd,
  requestCancel,
  access,
}) => {
  const navigate = useNavigate();

  return (
    <S.Wrapper>
      <S.Header>
        <span>Revisión titularidad</span>
        {requestAdd && (
          <S.DivButton onClick={requestAdd} disabled={!access?.Editar}>
            <FontAwesomeIcon icon={faCirclePlus} size="lg" />
          </S.DivButton>
        )}
      </S.Header>
      <S.Content>
        <table>
          <thead>
            <S.TrColumnTitles>
              {metadata.map((e) => (
                <th key={e.name}>{e.label}</th>
              ))}
            </S.TrColumnTitles>
          </thead>
          <tbody>
            {data.map((register) => (
              <tr key={register.idCarga}>
                {metadata.map((field) => (
                  <td key={field.name}>
                    {register[field.name]}
                    {field.name === "estado" && (
                      <>
                        {register.puedeAbrir && (
                          <FontAwesomeIcon
                            icon={faEye}
                            onClick={() =>
                              navigate(`/Carga/${register.idCarga}`)
                            }
                            size="lg"
                          />
                        )}
                        {register.esCancelable && (
                          <FontAwesomeIcon
                            icon={faRectangleXmark}
                            onClick={() => requestCancel(register.idCarga)}
                            size="lg"
                          />
                        )}
                      </>
                    )}
                  </td>
                ))}
              </tr>
            ))}
            {data.length === 0 && (
              <tr>
                <td>No hay registros</td>
              </tr>
            )}
          </tbody>
        </table>
      </S.Content>
    </S.Wrapper>
  );
};

ModuleRevisionTareasTitularidad.propTypes = {
  data: PropTypes.array,
  metadata: PropTypes.array,
  requestAdd: PropTypes.func,
  requestCancel: PropTypes.func,
  access: PropTypes.object,
};
