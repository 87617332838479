import React from "react";
import PropTypes from "prop-types";
import * as S from "./styles";
import * as API from "../../api";
import AsyncCreatableSelect from "react-select/async-creatable";
import { COLORES } from "../../constants";

export const InputEmail = ({
  listName,
  label,
  defaultOptions,
  selectedOptions,
  handleChange,
  canBeEmpty,
}) => {
  const promiseOptions = (e) => {
    const data = {};
    data.filtro = e;
    return new Promise((resolve) => {
      API.obtenerDireccionesCorreo(data)
        .then((res) => {
          const values = res.valores;
          if (values.length > 0) return resolve([...convertOptions(values)]);
          else return resolve([]);
        })
        .catch((error) => API.DEVELOP && console.log(error));
    });
  };

  const convertOptions = (options) =>
    options.map((opt) => ({ value: opt.etiqueta, label: opt.etiqueta }));

  const validEmail = (email) => {
    if (email.includes(" ")) return false;
    const emailSplit = email.split("@");
    if (emailSplit.length !== 2) return false;
    if (emailSplit[0] === "") return false;
    if (emailSplit[1] === "") return false;
    if (!emailSplit[1].includes(".")) return false;
    const emailDomainSplit = emailSplit[1].split(".");
    if (emailDomainSplit[0] === "") return false;
    if (emailDomainSplit[emailDomainSplit.length - 1] === "") return false;
    return true;
  };

  return (
    <S.Container>
      {label && <S.Title>{label}</S.Title>}
      <S.SelectContainer>
        <AsyncCreatableSelect
          name={listName}
          styles={customStyles}
          value={selectedOptions}
          defaultOptions={defaultOptions}
          loadOptions={promiseOptions}
          onChange={(e) => handleChange(listName, e)}
          isMulti={true}
          isClearable={canBeEmpty}
          loadingMessage={() => "Cargando..."}
          formatCreateLabel={(e) => `Añadir <${e}>`}
          noOptionsMessage={() => "No hay opciones"}
          isValidNewOption={(e) => validEmail(e)}
        />
      </S.SelectContainer>
    </S.Container>
  );
};

const customStyles = {
  control: (provided, state) => ({
    ...provided,
    minHeight: "1rem",
    boxShadow: "none",
    "&:hover": {
      borderColor: COLORES.MULTISELECT_LIGHT_BORDER,
    },
    borderColor: COLORES.MULTISELECT_LIGHT_BORDER,
    backgroundColor: COLORES.MULTISELECT_BACKGROUND,
  }),
  menu: (provided, state) => ({
    ...provided,
    margin: 0,
  }),
  menuList: (provided, state) => ({
    ...provided,
    padding: 0,
    borderRadius: "5px",
  }),
  indicatorSeparator: (provided, state) => ({}),
  indicatorsContainer: (provided, state) => ({
    ...provided,
    "&.indicatorContainer": {
      padding: "0 8px",
    },
  }),
  dropdownIndicator: (provided, state) => ({
    ...provided,
    padding: "0 8px",
  }),
  clearIndicator: (provided, state) => ({
    ...provided,
    padding: "0 8px",
  }),
  option: (provided, state) => ({
    ...provided,
    padding: "6px 12px",
    borderBottom: `1px solid lightgrey`,
    backgroundColor: COLORES.MULTISELECT_BACKGROUND,
    "&:hover": {
      backgroundColor: COLORES.MULTISELECT_OPTION_HOVER,
    },
    overflow: "hidden",
  }),
};

InputEmail.propTypes = {
  listName: PropTypes.string.isRequired,
  label: PropTypes.string,
  defaultOptions: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
    })
  ),
  selectedOptions: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
    })
  ),
  handleChange: PropTypes.func,
  canBeEmpty: PropTypes.bool,
};
