import React, { useState} from "react";
import PropTypes from "prop-types";
import * as S from "./styles";

import { Button } from "../Button";
import { InputListMultiple } from "../InputListMultiple";
import { InputList } from "../InputList";

export const ModalAsignacionGestoria = ({
  type,
  title,
  message,
  labelConfirm,
  values,
  confirm,
  cancel,
  listsModal,
}) => {
  const [options, setOptions] = useState([]);


  const handleListChange = (field, option) => {
    const tmpOptions = { ...options };
    tmpOptions[field.name] = option;
      setOptions(tmpOptions);
    
  };

  const summary = () =>
    !values[0].etiqueta
      ? values.length + " reservas"
      : "reserva " + values[0].etiqueta;

  const body = () => {
    switch (type) {
      case "reasignReserva":
      case "massiveReasignReservas":
        return (
          <>
            <p className="centered">
              Asignar tipos de tarea y gestoria a {summary()}
            </p>

            <InputList
              field={{ label: "Gestoria", name: "Gestoria" }}
              options={convertOptions(listsModal.Gestoria) || []}
              value={options.Gestoria || ""}
              handleChange={handleListChange}
              isClearable={true}
            />
            <InputListMultiple
              field={{ label: "BloqueTarea", name: "BloqueTarea" }}
              options={convertOptions(listsModal.BloqueTarea) || []}
              currentOptions={options.BloqueTarea || []}
              handleChange={handleListChange}
              isClearable={true}
              disabled={options.TipoTareaGestoria?.length}
            />
            <InputListMultiple
              field={{ label: "Tipos Tarea", name: "TipoTareaGestoria" }}
              options={convertOptions(listsModal.TipoTareaGestoria) || []}
              currentOptions={options.TipoTareaGestoria || []}
              handleChange={handleListChange}
              isClearable={true}
              disabled={options.BloqueTarea?.length}
            />
          </>
        );

      default:
        return <p>{message}</p>;
    }
  };

  const convertOptions = (list) =>
    list.valores.map((opt) => ({
      value: opt.valor,
      label: opt.etiqueta,
    }));

  const buttons = () => {
    switch (type) {
      case "reasignReserva":
        return (
          <>
            <Button
              label={labelConfirm}
              onClick={() => confirm([values[0]?.valor], options)}
            />
            <Button label="Cancelar" onClick={cancel} type="cancel" />
          </>
        );

      case "massiveReasignReservas":
        return (
          <>
            <Button
              label={labelConfirm}
              onClick={() => confirm(values, options)}
            />
            <Button label="Cancelar" onClick={cancel} type="cancel" />
          </>
        );

      default:
        return <Button label="Cancelar" onClick={cancel} type="cancel" />;
    }
  };

  return (
    <S.Wrapper onClick={cancel}>
      <S.Content onClick={(event) => event.stopPropagation()}>
        <S.Header>
          <p>{title}</p>
        </S.Header>
        <S.Body>
          {body()}
          <S.Buttons>{buttons()}</S.Buttons>
        </S.Body>
      </S.Content>
    </S.Wrapper>
  );
};

ModalAsignacionGestoria.propTypes = {
  type: PropTypes.string,
  title: PropTypes.string,
  message: PropTypes.string,
  labelConfirm: PropTypes.string,
  values: PropTypes.array,
  summary: PropTypes.object,
  confirm: PropTypes.func,
  cancel: PropTypes.func,
};
