import React from "react";
import PropTypes from "prop-types";
import * as S from "./styles";
import * as API from "../../api";
import { InputText } from "../InputText";
import { InputNumber } from "../InputNumber";
import { InputTextArea } from "../InputTextArea";
import { InputList } from "../InputList";
import { InputListMultiple } from "../InputListMultiple";
import { InputDate } from "../InputDate";
import { InputCheckbox } from "../InputCheckbox";

export const ModuleDatosUsuario = ({
  data,
  metadata,
  lists,
  values,
  setValues,
  options,
  setOptions,
  label = "Datos Usuario",
  columns,
  idUsuario,
}) => {
  const convertOptions = (options) =>
    options.map((opt) => ({
      value: opt.valor,
      label: opt.etiqueta,
    }));

  const handleChange = (prop, value) => {
    const tmpValues = { ...values };
    tmpValues[prop] = value;
    setValues(tmpValues);
  };

  const handleListChange = (field, value) => {
    const tmpOptions = { ...options };
    const tmpValues = { ...values };
    tmpValues[field.name] = value;

    if (lists[field.list].listasHijas.length === 0) {
      setValues(tmpValues);
    } else
      lists[field.list].listasHijas.forEach((list) => {
        const listaRelacional = {
          nombreLista: field.list,
          idEntidadRelacional: value,
        };
        const data = {
          lista: list,
          listaRelacional,
        };
        API.obtenerLista(data).then((res) => {
          tmpOptions[list] = convertOptions(res.valores);
          setOptions(tmpOptions);
          setValues(tmpValues);
        });
      });
  };

  return (
    <S.Wrapper>
      <S.Header>
        <p>{label}</p>
      </S.Header>
      <S.Content columns={columns}>
        {metadata.map((field) => {
          switch (field.type) {
            case "text":
              return (
                <InputText
                  key={field.name}
                  field={field}
                  value={values[field.name] || ""}
                  handleChange={handleChange}
                />
              );

            case "number":
              return (
                <InputNumber
                  key={field.name}
                  field={field}
                  value={values[field.name] || ""}
                  handleChange={handleChange}
                />
              );

            case "textarea":
              return (
                <InputTextArea
                  key={field.name}
                  field={field}
                  rows={field.rows}
                  value={values[field.name] || ""}
                  handleChange={handleChange}
                  colSpan={field.colSpan}
                />
              );

            case "list":
              return (
                <InputList
                  key={field.name}
                  field={field}
                  value={values[field.name] || ""}
                  options={options[field.list] || []}
                  handleChange={handleListChange}
                  isClearable={lists[field.list]?.mostrarVacio || false}
                />
              );

            case "multiSelectList":
              return (
                <InputListMultiple
                  key={field.name}
                  field={field}
                  currentOptions={values[field.name] || []}
                  options={options[field.list] || []}
                  handleChange={handleListChange}
                />
              );

            case "date":
            case "datetime-local":
              return (
                <InputDate
                  key={field.name}
                  field={field}
                  value={values[field.name] || ""}
                  handleChange={handleChange}
                />
              );

            case "checkbox":
              return (
                <InputCheckbox
                  key={field.name}
                  field={field}
                  value={values[field.name] || false}
                  handleChange={handleChange}
                />
              );
            case "readOnly":
            default:
              return idUsuario === "Nuevo" && field.name === "nombreUsuario" ? (
                <InputText
                  key={field.name}
                  field={field}
                  value={values[field.name] || ""}
                  handleChange={handleChange}
                />
              ) : (
                <S.ReadOnly key={field.name} colSpan={field.colSpan}>
                  <p className="bold">{field.label}</p>
                  <p>{data[field.name]}</p>
                </S.ReadOnly>
              );
          }
        })}
      </S.Content>
    </S.Wrapper>
  );
};

ModuleDatosUsuario.propTypes = {
  data: PropTypes.object.isRequired,
  metadata: PropTypes.array.isRequired,
  lists: PropTypes.object.isRequired,
  values: PropTypes.object.isRequired,
  setValues: PropTypes.func,
  options: PropTypes.object.isRequired,
  setOptions: PropTypes.func,
  label: PropTypes.string,
  columns: PropTypes.number,
  idUsuario: PropTypes.string,
};
