import styled, { css } from "styled-components/macro";
import { COLORES } from "../../constants";

export const Wrapper = styled.div``;

export const Solapas = styled.div`
  display: flex;
`;

export const Solapa = styled.div`
  font-weight: bold;
  padding: 0.5rem 0.75rem;
  border-radius: 0.5rem 0.5rem 0 0;

  ${(props) =>
    props.active
      ? css`
          color: ${COLORES.MENU_FONT};
          background-color: ${COLORES.MENU_BACKGROUND};
          border: thin solid ${COLORES.MENU_BACKGROUND};
          border-bottom: none;
          font-weight: bold;
          cursor: default;
        `
      : css`
          color: black;
          background-color: white;
          border: thin solid lightgrey;
          border-bottom: none;
          cursor: pointer;
        `}
`;

export const Content = styled.div`
  background-color: ${COLORES.DEFAULT_MODULE_BACKGROUND};
  min-height: 3rem;
`;

export const Formalizacion = styled.div`
  background-color: white;
`;

export const ModulesRow = styled.div`
  display: flex;
  gap: 2rem;
`;
