import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import * as S from "./styles";
import * as API from "../../api";
import { toast } from "react-toastify";
import { ModuleSearch } from "../../components/ModuleSearch";
import { Loading } from "../../components/Loading";
import { Modal } from "../../components/Modal";
import {
  RESERVAS_BY_PAGE,
  METADATA_SEARCH_BUSCADOR_RESERVAGESTORIA,
  METADATA_RESULT_BUSCADOR_RESERVAGESTORIA,
} from "../../constants";
import { ModuleResultBuscadorGestoria } from "../../components/ModuleResultBuscadorAsignacionGestoria";
import { ModalAsignacionGestoria } from "../../components/ModalAsignacionGestoria";
import { Link } from "react-router-dom";
import { Button } from "../../components/Button";

export const BuscadorAsignacionGestoria = ({ access }) => {
  const [lists, setLists] = useState({});
  const [result, setResult] = useState({});
  const [formData, setFormData] = useState({});
  const [page, setPage] = useState(1);
  const [currentSort, setCurrentSort] = useState({});
  const [visibleModal, setVisibleModal] = useState("");
  const [listFilters, setListFilters] = useState([]);
  const [currentFilter, setCurrentFilter] = useState("");
  const [dataFilter, setDataFilter] = useState("");
  const [loading, setLoading] = useState(true);
  const [selectedIds, setSelectedIds] = useState([]);
  const [currentReserva, setCurrentReserva] = useState("");
  const [listsModal, setListsModal] = useState({});

  useEffect(() => {
    let isMounted = true;
    API.obtenerFiltros({ modulo: "BuscadorReservas" })
      .then((res) => isMounted && setListFilters(res))
      .catch((error) => API.DEVELOP && console.log(error));

    API.obtenerListas("BuscadorAsignacionGestoria", 0)
      .then((res) => isMounted && setLists(res))
      .catch((error) => API.DEVELOP && console.log(error))
      .finally(() => setLoading(false));

    API.obtenerListas("AsignacionGestoria", 0)
      .then((res) => isMounted && setListsModal(res))
      .catch((error) => API.DEVELOP &&  console.log(error))
      .finally(() => setLoading(false));
    return () => (isMounted = false);
  }, []);

  const searchTareas = (data = structuredClone(formData)) => {
    setLoading(true);
    setFormData(data);

    const ordenTmp = data.orden;
    data.orden = order(currentSort);
    data.paginacion = pagination(1);
    data.tipoBusqueda = "2";
    API.buscadordeReservas(data)
      .then((res) => {
        setResult(res || {});
        setPage(2);
        setSelectedIds([]);
      })
      .catch((error) => API.DEVELOP && console.log(error))
      .finally(() => {
        setLoading(false);
        data.orden = ordenTmp;
      });
  };

  const moreTareas = () => {
    setLoading(true);
    const data = structuredClone(formData);
    data.orden = order();
    data.paginacion = pagination(page);
    API.buscadordeReservas(data)
      .then((res) => {
        setResult({
          informacionRegistros: res.informacionRegistros,
          registros: [...result.registros, ...res.registros],
        });
        setPage(page + 1);
        setLoading(false);
      })
      .catch((error) => API.DEVELOP && console.log(error));
  };

  const sort = (field) => {
    setCurrentFilter("");
    setLoading(true);
    let direction;
    if (field !== currentSort.field) direction = 1;
    else direction = currentSort.direction === 1 ? 2 : 1;
    const newSort = { field, direction };
    setCurrentSort(newSort);

    const data = structuredClone(formData);
    data.orden = order(newSort);
    data.paginacion = pagination(1);
    API.buscadordeReservas(data)
      .then((res) => {
        setResult(res || {});
        setPage(2);
        setLoading(false);
      })
      .catch((error) => API.DEVELOP && console.log(error));
  };

  const pagination = (page) => {
    return {
      pagina: page,
      cantidadRegistros: RESERVAS_BY_PAGE,
    };
  };

  const order = (data = currentSort) => {
    return {
      campoOrden: data.field,
      direccion: data.direction,
    };
  };

  const requestSaveFilter = (data) => {
    data.orden = order(currentSort);
    setDataFilter(data);
    setVisibleModal("saveFilter");
  };

  const saveFilter = (name) => {
    setLoading(true);
    const data = {};
    data.modulo = "BuscadorReservas";
    data.nombre = name;
    data.filtro = dataFilter;

    API.guardarFiltro(data)
      .then((res) => {
        if (res.resultadoPeticion === 1) {
          const tmpListFilters = [...listFilters];
          data.id = res.idFiltro;
          tmpListFilters.push(data);
          setListFilters(tmpListFilters);
          setCurrentFilter(res.idFiltro);
          toast.success("Filtro Guardado");
        } else
          toast.error("No se pudo guardar el filtro\n" + res.mensajeUsuario);
      })
      .catch((error) => {
        toast.error("No se pudo guardar el filtro");
        API.DEVELOP && console.log(error);
      })
      .finally(() => {
        setLoading(false);
        setVisibleModal("");
      });
  };

  const requestDeleteFilter = () => {
    setVisibleModal("deleteFilter");
  };

  const deleteFilter = () => {
    setLoading(true);
    const data = {};
    data.idFiltro = currentFilter;

    API.eliminarFiltro(data)
      .then((res) => {
        if (res.resultadoPeticion === 1) {
          const tmpListFilters = [...listFilters].filter(
            (filter) => filter.id !== currentFilter
          );
          setListFilters(tmpListFilters);
          toast.success("Filtro Eliminado");
        } else
          toast.error("No se pudo eliminar el filtro\n" + res.mensajeUsuario);
      })
      .catch((error) => {
        toast.error("No se pudo eliminar el filtro");
        API.DEVELOP && console.log(error);
      })
      .finally(() => {
        setLoading(false);
        setVisibleModal("");
        setCurrentFilter("");
      });
  };

  const handleChangeSelection = (e) => {
    const { id, checked } = e.target;
    const tmpSelectedIds = [...selectedIds];
    if (checked) {
      tmpSelectedIds.push(id);
      setSelectedIds(tmpSelectedIds);
    } else {
      setSelectedIds(tmpSelectedIds.filter((e) => e !== id));
    }
  };

  const requestReasignReserva = (reserva) => {
    setCurrentReserva(reserva);
    if (reserva) {
      setVisibleModal("reasignReserva");
    } else {
      setVisibleModal("massiveReasignReserva");
    }
  };

  const massiveAssignReservaGestoria = (valores, options) => {
    setLoading(true);
    let ok = true;
    if (valores?.length < 1) {
      ok = false;
      toast.error(`No se han seleccionado reservas`);
    }
    if (options?.gestorias?.length < 1) {
      ok = false;
      toast.error(`No se ha seleccionado gestoria`);
    }
    if (options?.tiposTarea?.length < 1) {
      ok = false;
      toast.error(`No se han seleccionado Tipos de tarea`);
    }
    
    if (ok) {
      const data = {
        ListCodReserva: valores,
        BloqueTarea: options.BloqueTarea,
        TipoTareaGestoria: options.TipoTareaGestoria,
        Gestoria: options.Gestoria,
      };
      API.crearModificarMultiplesReservaGestoria(data)
        .then((res) => {
          if (res.resultadoPeticion === 1) {
            toast.success("Se han añadido los registros");
            setLoading(false);
            setVisibleModal("");
          } else {
            toast.error(
              `No se ha podido añadir el registro \n${res.mensajeUsuario}`
            );
          }
        })
        .catch((error) => API.DEVELOP && console.log(error))
        .finally(() => {
          setLoading(false)
          setVisibleModal("");
        });
    }
  };

  const deselectAll = () => {
    setSelectedIds([]);
  };

  const selectAll = () => {
    setSelectedIds(result.registros?.map((e) => e.reserva.valor) || []);
  };

  return (
    <S.Wrapper>
      {loading && <Loading />}

      {visibleModal === "saveFilter" && (
        <Modal
          type="saveFilter"
          title="Guardar Filtro"
          message="Indique el nombre del nuevo filtro"
          labelConfirm="Guardar"
          confirm={saveFilter}
          cancel={() => setVisibleModal("")}
        />
      )}
      {visibleModal === "deleteFilter" && (
        <Modal
          type="deleteFilter"
          title="Eliminar Filtro"
          message="¿Seguro que desea eliminar el filtro seleccionado?"
          labelConfirm="Eliminar"
          confirm={deleteFilter}
          cancel={() => setVisibleModal("")}
        />
      )}
      {visibleModal === "reasignReserva" && (
        <ModalAsignacionGestoria
          values={[currentReserva]}
          type="reasignReserva"
          title="Asignar la Reserva a la gestoria"
          message="¿Está seguro de asignar la reserva a la gestoria?"
          labelConfirm="Aceptar"
          confirm={massiveAssignReservaGestoria}
          cancel={() => setVisibleModal("")}
          lists={lists}
          listsModal={listsModal}
        />
      )}
      {visibleModal === "massiveReasignReserva" && (
        <ModalAsignacionGestoria
          values={selectedIds}
          type="massiveReasignReservas"
          title="Asignar las Reservas a gestoria"
          message="¿Está seguro de asignar las reservas a la gestoria?"
          labelConfirm="Aceptar"
          confirm={massiveAssignReservaGestoria}
          cancel={() => setVisibleModal("")}
          lists={lists}
          listsModal={listsModal}
        />
      )}
      <S.Buttons>
        <Link to={"/BuscadorGestoriaProvincia"}>
          <Button label="Buscador Gestorias Provincias" />
        </Link>
      </S.Buttons>
      <ModuleSearch
        title="Asignacion Gestoria: Filtro"
        metadata={METADATA_SEARCH_BUSCADOR_RESERVAGESTORIA}
        lists={lists}
        submit={searchTareas}
        listFilters={listFilters}
        currentFilter={currentFilter}
        setCurrentFilter={setCurrentFilter}
        requestSaveFilter={requestSaveFilter}
        requestDeleteFilter={requestDeleteFilter}
        setCurrentSort={setCurrentSort}
      />

      <ModuleResultBuscadorGestoria
        title="Resultados"
        metadata={METADATA_RESULT_BUSCADOR_RESERVAGESTORIA}
        data={result}
        formData={formData}
        moreResults={moreTareas}
        currentSort={currentSort}
        sort={sort}
        setLoading={setLoading}
        selectedIds={selectedIds}
        handleChangeSelection={handleChangeSelection}
        selectAll={selectAll}
        deselectAll={deselectAll}
        requestReasign={requestReasignReserva}
      />
    </S.Wrapper>
  );
};

BuscadorAsignacionGestoria.propTypes = {
  access: PropTypes.object,
};
