import React from "react";
import PropTypes from "prop-types";
import * as API from "../../api";
import { toast } from "react-toastify";
import * as S from "./styles";
import { RowResultReservas } from "../RowResultReservas";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSort,
  faSortUp,
  faSortDown,
  faFileExcel,
} from "@fortawesome/free-solid-svg-icons";

export const ModuleResultReservas = ({
  title,
  metadata,
  data,
  formData,
  moreResults,
  currentSort,
  sort,
  isSearchReserva,
  setLoading,
  access,
}) => {
  const infoRecords = data.informacionRegistros;

  const textTitle = () => {
    const records = infoRecords
      ? `(${infoRecords.registrosMostrados} de ${infoRecords.totalRegistros})`
      : "";
    return `${title} ${records}`;
  };

  const textFoot = () => {
    if (infoRecords === undefined) return "";
    if (infoRecords?.totalRegistros === 0)
      return "No se han encontrado resultados";
    if (infoRecords?.registrosMostrados === infoRecords?.totalRegistros)
      return "No hay más resultados";
    else return "Mostrar más resultados";
  };

  const isFootButton = () => {
    if (infoRecords === undefined) return false;
    if (infoRecords?.registrosMostrados === infoRecords?.totalRegistros)
      return false;
    return true;
  };

  const exportarReservaExcel = () => {
    setLoading(true);

    API.exportarReservaExcel(formData)
      .then((res) => {
        const url = URL.createObjectURL(new Blob([res.data]));
        const filename = res.headers["content-disposition"]
          .split(";")[1]
          .split("=")[1];
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", filename);
        link.click();
      })
      .catch((error) => {
        toast.error("Error al descargar documento: " + error.msg?.Message);
        API.DEVELOP && console.log(error);
      })
      .finally(() => setLoading(false));
  };

  return (
    <S.Wrapper>
      <S.Header>
        <p>{textTitle()}</p>
        <button
          onClick={exportarReservaExcel}
          disabled={
            !access?.Exportar || !data.registros || data.registros?.length === 0
          }
        >
          <FontAwesomeIcon icon={faFileExcel} size="lg" />
        </button>
      </S.Header>
      <S.Content>
        <table>
          <thead>
            {isSearchReserva ? (
              <S.TrColumnTitlesReserva>
                {metadata.map((e) => (
                  <S.ButtonTh
                    key={e.label}
                    scope="col"
                    active={e.name === currentSort.field}
                  >
                    {e.label}
                    <button
                      onClick={() => sort(e.name)}
                      disabled={
                        !infoRecords || infoRecords?.totalRegistros <= 1
                      }
                    >
                      <FontAwesomeIcon
                        icon={
                          e.name !== currentSort.field
                            ? faSort
                            : currentSort.direction === 1
                            ? faSortUp
                            : faSortDown
                        }
                      />
                    </button>
                  </S.ButtonTh>
                ))}
                <th />
              </S.TrColumnTitlesReserva>
            ) : (
              <S.TrColumnTitlesActivo>
                {metadata.map((e) => (
                  <S.ButtonTh
                    key={e.label}
                    scope="col"
                    active={e.name === currentSort.field}
                  >
                    {e.label}
                    <button
                      onClick={() => sort(e.name)}
                      disabled={
                        !infoRecords || infoRecords?.totalRegistros <= 1
                      }
                    >
                      <FontAwesomeIcon
                        icon={
                          e.name !== currentSort.field
                            ? faSort
                            : currentSort.direction === 1
                            ? faSortUp
                            : faSortDown
                        }
                      />
                    </button>
                  </S.ButtonTh>
                ))}
                <th />
              </S.TrColumnTitlesActivo>
            )}
          </thead>
          <tbody>
            {data.registros?.map((e, index) => (
              <RowResultReservas key={index} metadata={metadata} data={e} />
            ))}
          </tbody>
          <tfoot>
            <tr>
              <S.TdFoot colSpan={metadata.length + 2}>
                {isFootButton() ? (
                  <button onClick={moreResults}>{textFoot()}</button>
                ) : (
                  <p>{textFoot()}</p>
                )}
              </S.TdFoot>
            </tr>
          </tfoot>
        </table>
      </S.Content>
    </S.Wrapper>
  );
};

ModuleResultReservas.propTypes = {
  title: PropTypes.string,
  metadata: PropTypes.array.isRequired,
  data: PropTypes.object.isRequired,
  formData: PropTypes.object,
  moreResults: PropTypes.func,
  currentSort: PropTypes.object,
  sort: PropTypes.func,
  isSearchReserva: PropTypes.bool,
  setLoading: PropTypes.func,
  access: PropTypes.object,
};
