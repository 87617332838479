import styled from "styled-components/macro";
import { COLORES } from "../../constants";

export const Wrapper = styled.div`
  font-size: 0.75rem;
  padding: 1.5rem;
`;

export const ModuleWrapper = styled.div`
  width: 100%;
  background-color: ${COLORES.DEFAULT_MODULE_BACKGROUND};
  padding-bottom: 1rem;
  border-radius: 0.25rem;
`;

export const Header = styled.div`
  width: 100%;
  height: 2rem;
  color: ${COLORES.DEFAULT_MODULE_HEADER_FONT};
  background-color: ${COLORES.DEFAULT_MODULE_HEADER_BACKGROUND};
  font-weight: bold;
  border-radius: 0.25rem 0.25rem 0 0;

  p {
    line-height: 2rem;
    padding-left: 1rem;
  }
`;

export const Content = styled.form`
  display: grid;
  grid-template-columns: repeat(${(props) => props.columns || 6}, 1fr);
  gap: 1rem;
  padding: 1rem;

  p {
    min-height: 1rem;
  }

  .bold {
    font-weight: bold;
  }
`;

export const Permisos = styled.table`
  margin: 1rem;
  table-layout: fixed;
  border-collapse: collapse;
  width: calc(100% - 2rem);

  input {
    width: 1rem;
    height: 1rem;
  }

  tr {
    height: 2rem;
    border: thin solid lightgray;
  }

  th {
    text-align: center;
    color: ${COLORES.DEFAULT_MODULE_HEADER_FONT};
    background-color: ${COLORES.DEFAULT_MODULE_HEADER_BACKGROUND};
    :first-child {
      width: 20%;
    }
  }

  td {
    text-align: center;
  }

  th:first-child,
  td:first-child {
    text-align: left;
    font-weight: bold;
    padding-left: 1rem;
  }
`;

export const Buttons = styled.div`
  display: flex;
  column-gap: 1rem;
  justify-content: flex-end;
  padding: 1rem;
`;
