import React, { useState, useEffect } from "react";
import * as S from "./styles";
import * as API from "../../api";
import { useParams, useNavigate, Link } from "react-router-dom";
import { toast } from "react-toastify";
import { Button } from "../../components/Button";
import { Loading } from "../../components/Loading";
import { METADATA_DATOS_NOTARIO } from "../../constants";
import { ModuleDatosNotario } from "../../components/ModuleDatosNotario";

export const EdicionCreacionNotarios = () => {
  const { idNotario } = useParams();
  const navigate = useNavigate();
  const [lists, setLists] = useState({});
  const [datosNotario, setDatosNotario] = useState({});
  const [loading, setLoading] = useState(true);
  const [values, setValues] = useState({});
  const [options, setOptions] = useState({});
  useEffect(() => {
    let isMounted = true;
    const idObtenerNotario = idNotario === "Nuevo" ? "-1" : idNotario;
    API.obtenerNotario({ id: idObtenerNotario })
      .then((res) => {
        if (isMounted) {
          setDatosNotario(res);
        }
      })
      .catch((error) => API.DEVELOP && console.log(error))
      .finally(() => setLoading(false));

    API.obtenerListas("EdicionCreacionNotarios", 0)
      .then((res) => isMounted && setLists(res))
      .catch((error) => API.DEVELOP && console.log(error));

    return () => (isMounted = false);
  }, [idNotario]);

  useEffect(() => {
    const options = {};
    const values = {};
    Object.entries(lists).forEach(([list, listProps]) => {
      options[list] = convertOptions(listProps.valores);
    });
    const setInitialValue = (e) => {
      switch (e.type) {
        case "list":
          values[e.name] = datosNotario[e.name]?.valor || "";
          break;
        case "text":
          values[e.name] = datosNotario[e.name] || "";
          break;
        default:
          values[e.name] = "";
      }
    };

    METADATA_DATOS_NOTARIO.forEach((props) => {
      if (props.name === "_group")
        props.fields.forEach((props) => setInitialValue(props));
      else setInitialValue(props);
    });

    setValues(values);
    setOptions(options);
  }, [lists, datosNotario]);

  const convertOptions = (options) =>
    options.map((opt) => ({
      value: opt.valor,
      label: opt.etiqueta,
    }));

  const crearActualizarNotario = () => {
    setLoading(true);
    const idObtenerNotario = idNotario === "Nuevo" ? "-1" : idNotario;
    API.crearActualizarNotario({ id: idObtenerNotario, ...values })
      .then((res) => {
        if (res.resultadoPeticion === 1 && idNotario === "Nuevo") {
          toast.success("Notario Creado");
          navigate("/BuscadorNotarios");
        } else if (res.resultadoPeticion === 1 && idNotario !== "Nuevo") {
          toast.success("Notario Guardado");
        } else toast.error(res.mensajeUsuario);
      })
      .catch((error) => API.DEVELOP && console.log(error))
      .finally(() => setLoading(false));
  };

  return (
    <S.Wrapper>
      {loading && <Loading />}

      <ModuleDatosNotario
        data={datosNotario}
        metadata={METADATA_DATOS_NOTARIO}
        lists={lists}
        columns={2}
        values={values}
        setValues={setValues}
        options={options}
        setOptions={setOptions}
      />

      <S.Buttons>
        <Button label="Guardar" onClick={crearActualizarNotario} />
        <Link to={"/BuscadorNotarios"}>
          <Button label="Volver" />
        </Link>
      </S.Buttons>
    </S.Wrapper>
  );
};
