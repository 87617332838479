import React from "react";
import PropTypes from "prop-types";
import * as S from "./styles";
import * as API from "../../api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileLines } from "@fortawesome/free-solid-svg-icons";
import { UploadDownload } from "../UploadDownload";

export const ModuleTareaCheques = ({
  data,
  metadata,
  requestAdd,
  refreshDocs,
  setLoading,
  access,
}) => {
  return (
    <S.Wrapper>
      <S.Header>
        <p>Cheques</p>
        {requestAdd && (
          <S.DivButton onClick={requestAdd} disabled={!access?.Editar}>
            <FontAwesomeIcon icon={faFileLines} size="lg" />
          </S.DivButton>
        )}
      </S.Header>
      <S.Content>
        <table>
          <thead>
            <S.TrColumnTitles>
              {metadata.map((e) => (
                <th key={e.name}>{e.label}</th>
              ))}
            </S.TrColumnTitles>
          </thead>
          <tbody>
            {data.map((document) => (
              <tr key={document.id}>
                {metadata.map((field) => (
                  <td key={field.name}>
                    {field.type === undefined && document[field.name]}
                    {field.type === "file" && (
                      <UploadDownload
                        id={document.id}
                        nombreDocumento={document[field.name]}
                        uploadEnabled={access.Editar}
                        uploadService={API.actualizarDocumentoReserva}
                        uploadSuccess={refreshDocs}
                        setLoading={setLoading}
                      />
                    )}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </S.Content>
    </S.Wrapper>
  );
};

ModuleTareaCheques.propTypes = {
  data: PropTypes.array,
  metadata: PropTypes.array,
  requestAdd: PropTypes.func,
  refreshDocs: PropTypes.func,
  setLoading: PropTypes.func,
  access: PropTypes.object,
};
