import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import * as S from "./styles";
import * as API from "../../api";
import { toast } from "react-toastify";
import { Button } from "../Button";

export const ModalReadEmail = ({ title, idCorreo, cancel }) => {
  const [email, setEmail] = useState({});
  useEffect(() => {
    let isMounted = true;
    API.obtenerContenidoCorreo({ idCorreo })
      .then((res) => {
        if (res.resultadoPeticion === 1) isMounted && setEmail(res);
        else return Promise.reject(res.mensajeUsuario);
      })
      .catch((error) => {
        toast.error(`Error al abrir el e-mail\n${error}`);
        cancel();
        API.DEVELOP && console.log(error);
      });
    return () => (isMounted = false);
  }, [idCorreo, cancel]);

  return (
    <S.Wrapper onClick={cancel}>
      <S.Content onClick={(event) => event.stopPropagation()}>
        <S.Header>
          <p>{title}</p>
        </S.Header>
        <S.Body>
          <p className="bold">Para</p>
          <input value={email.destinatario || "Cargando..."} disabled />

          <p className="bold">Asunto</p>
          <input value={email.asunto || "Cargando..."} disabled />

          <S.Html
            dangerouslySetInnerHTML={{ __html: email.mensaje || "Cargando..." }}
          />

          <S.Buttons>
            <Button label="Cerrar" onClick={cancel} type="cancel" />
          </S.Buttons>
        </S.Body>
      </S.Content>
    </S.Wrapper>
  );
};

ModalReadEmail.propTypes = {
  title: PropTypes.string,
  idCorreo: PropTypes.string,
  cancel: PropTypes.func,
};
