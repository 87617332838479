import React from "react";
import PropTypes from "prop-types";
import * as S from "./styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBan } from "@fortawesome/free-solid-svg-icons";
import { faCircleXmark } from "@fortawesome/free-solid-svg-icons";
import { faCircleExclamation } from "@fortawesome/free-solid-svg-icons";

export const ErrorPage = ({ type, message }) => {
  const icon = () => {
    switch (type) {
      case "401":
        return faBan;
      case "404":
        return faCircleExclamation;
      case "400":
      default:
        return faCircleXmark;
    }
  };

  return (
    <S.Wrapper>
      <FontAwesomeIcon icon={icon()} size="2x" />
      <p>{message}</p>
    </S.Wrapper>
  );
};

ErrorPage.propTypes = {
  type: PropTypes.string,
  message: PropTypes.string,
};
