import styled from "styled-components/macro";
import { COLORES } from "../../constants";

export const TrHeader = styled.tr`
  background-color: ${COLORES.ACTIVE_HEADER_BACKGROUND};
  span {
    margin-left: 0.25rem;
    &.bold {
      font-weight: bold;
    }
  }
`;

export const DivButton = styled.button`
  color: ${COLORES.ACTIVE_NEW_ICON};
  background-color: transparent;
  border: none;
  cursor: pointer;

  :disabled {
    cursor: default;
    color: ${COLORES.FONT_AWESOME_ICON_DISABLED};
  }
`;

export const TrDoc = styled.tr`
  svg {
    margin-right: 0.5rem;
    color: ${COLORES.ACTIVE_DOC_LOCK};
  }
  :hover {
    background-color: ${COLORES.ROW_HOVER};
  }
`;
