import styled from "styled-components/macro";

export const Wrapper = styled.div`
  width: 100%;
  p,
  select {
    width: 100%;
    box-sizing: border-box;
  }
  p {
    height: 0.85rem;
    font-weight: bold;
  }
`;
