import styled from "styled-components/macro";
import { COLORES } from "../../constants";

export const Wrapper = styled.div`
  min-width: 5rem;
  button {
    width: 100%;
    height: 1.75rem;
    border: solid thin ${COLORES.BUTTON_BACKGROUND};
    border-radius: 0.25rem;
    color: ${COLORES.BUTTON_FONT};
    background-color: ${COLORES.BUTTON_BACKGROUND};
    box-shadow: 1.5px 1.5px 1px ${COLORES.BUTTON_SHADOW};
    cursor: pointer;
    padding: 0 0.75rem;

    :active {
      transform: translate(1px, 1px);
      box-shadow: none;
    }

    :disabled {
      transform: none;
      border: solid thin ${COLORES.BUTTON_BACKGROUND_DISABLED};
      background-color: ${COLORES.BUTTON_BACKGROUND_DISABLED};
      box-shadow: 1.5px 1.5px 1px ${COLORES.BUTTON_SHADOW};
      cursor: default;
    }
  }

  button.cancel {
    color: ${COLORES.BUTTON_CANCEL_FONT};
    border: solid thin ${COLORES.BUTTON_CANCEL_FONT};
    background-color: ${COLORES.BUTTON_CANCEL_BACKGROUND};

    :disabled {
      transform: none;
      border: solid thin ${COLORES.BUTTON_BACKGROUND_DISABLED};
      background-color: ${COLORES.BUTTON_BACKGROUND_DISABLED};
      box-shadow: 1.5px 1.5px 1px ${COLORES.BUTTON_SHADOW};
      cursor: default;
    }
  }

  button.approve {
    color: ${COLORES.BUTTON_FONT};
    border: solid thin ${COLORES.BUTTON_BACKGROUND};
    background-color: ${COLORES.BUTTON_BACKGROUND};

    :disabled {
      transform: none;
      border: solid thin ${COLORES.BUTTON_BACKGROUND_DISABLED};
      background-color: ${COLORES.BUTTON_BACKGROUND_DISABLED};
      box-shadow: 1.5px 1.5px 1px ${COLORES.BUTTON_SHADOW};
      cursor: default;
    }
  }

  button.deny {
    color: ${COLORES.BUTTON_DENY_FONT};
    border: solid thin ${COLORES.BUTTON_DENY_FONT};
    background-color: ${COLORES.BUTTON_DENY_BACKGROUND};

    :disabled {
      color: ${COLORES.BUTTON_FONT};
      transform: none;
      border: solid thin ${COLORES.BUTTON_BACKGROUND_DISABLED};
      background-color: ${COLORES.BUTTON_BACKGROUND_DISABLED};
      box-shadow: 1.5px 1.5px 1px ${COLORES.BUTTON_SHADOW};
      cursor: default;
    }
  }
`;
