import styled from "styled-components/macro";
import { COLORES } from "../../constants";

export const Wrapper = styled.div`
  position: fixed;
  background-color: ${COLORES.LOADING_BACKGROUND};
  z-index: 100;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow-y: auto;
`;

export const Content = styled.div`
  font-size: 0.85rem;
  background-color: ${COLORES.MODAL_BACKGROUND};
  border-radius: 0.25rem;
  width: 60%;
  display: flex;
  flex-direction: column;
  margin: 3rem auto;
`;

export const Header = styled.div`
  font-weight: bold;
  color: ${COLORES.MODAL_HEADER_FONT};
  background-color: ${COLORES.MODAL_HEADER_BACKGROUND};
  padding: 0 1rem;
  border-radius: 0.25rem 0.25rem 0 0;
  height: 2rem;
  line-height: 2rem;
`;

export const Body = styled.div`
  margin: 2rem;
  display: flex;
  justify-content: center;
  flex-direction: column;

  div.conCopia {
    height: 0.25rem;
    position: relative;
    align-self: end;
    z-index: 200;
    span {
      background-color: lightgrey;
      padding: 0.2rem 0.45rem;
      border-radius: 0.2rem;
      margin-left: 1rem;
      cursor: pointer;
    }
  }
`;

export const Buttons = styled.div`
  display: flex;
  margin-top: 2rem;
  justify-content: center;
  column-gap: 2rem;
`;

export const WrapperModalBody = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 0.75rem;

  p {
    margin-bottom: 0.35rem;
  }
  p.title {
    margin-bottom: 0.25rem;
  }
  p.file {
    margin-bottom: 0rem;
  }

  .ck-editor__editable_inline {
    min-height: 10rem;
  }
  .ck-dropdown {
    z-index: 0;
  }
`;

export const Attachments = styled.div`
  p.title {
    font-weight: bold;
    margin-bottom: 0.5rem;
  }
`;

export const ContentDocs = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 0.75rem;
  background-color: ${COLORES.DEFAULT_MODULE_BACKGROUND};
  padding: 1rem;
  border-radius: 0.25rem;
  margin-bottom: 1rem;

  div {
    display: flex;

    input {
      width: 1rem;
      height: 1rem;
      margin-right: 0.25rem;
    }
  }
`;

export const Active = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 0.5rem;
  margin-bottom: 1rem;

  svg {
    margin-right: 0.25rem;
  }
  span {
    margin-right: 0.25rem;
    &.bold {
      font-weight: bold;
    }
  }
`;
