import React, { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import * as S from "./styles";
import * as API from "../../api";
import { InputText } from "../InputText";
import { InputNumber } from "../InputNumber";
import { InputList } from "../InputList";
import { InputListMultiple } from "../InputListMultiple";
import { InputDate } from "../InputDate";
import { Button } from "../Button";

export const ModuleSearch = ({
  title,
  metadata,
  lists,
  submit,
  listFilters,
  currentFilter,
  setCurrentFilter,
  requestSaveFilter,
  requestDeleteFilter,
  allowSave = true,
  setCurrentSort,
}) => {
  const [values, setValues] = useState({});
  const [options, setOptions] = useState({});
  const [initValues, setInitValues] = useState({});

  const form = useRef();

  useEffect(() => {
    const values = {};
    const options = {};
    Object.entries(lists).forEach(([list, listProps]) => {
      options[list] = convertOptions(listProps.valores);
    });

    const setDefaultValue = (e) => {
      switch (e.type) {
        case "list":
          values[e.name] = lists[e.list]?.valorPorDefecto || "";
          break;

        case "multiSelectList":
          values[e.name] = lists[e.list]?.valorPorDefecto.split(",")[0]
            ? lists[e.list]?.valorPorDefecto.split(",")
            : [];
          break;

        default:
          values[e.name] = "";
      }
    };

    metadata.forEach((props) => {
      if (props.name === "_group")
        props.fields.forEach((props) => setDefaultValue(props));
      else setDefaultValue(props);
    });

    setValues(values);
    setOptions(options);
    setInitValues(values);
  }, [metadata, lists]);

  const handleChange = (prop, value) => {
    setCurrentFilter("");
    const tmpValues = { ...values };
    tmpValues[prop] = value;
    setValues(tmpValues);
  };

  const handleListChange = (field, value) => {
    setCurrentFilter("");
    const tmpOptions = { ...options };
    const tmpValues = { ...values };
    tmpValues[field.name] = value;

    if (lists[field.list].listasHijas.length > 0)
      lists[field.list].listasHijas.forEach((list) => {
        const listFieldName = metadata.find((e) => e.list === list).name;
        tmpValues[listFieldName] = "";

        const listaRelacional = {
          nombreLista: field.list,
          idEntidadRelacional: value,
        };
        const data = {
          lista: list,
          listaRelacional,
        };
        API.obtenerLista(data).then((res) => {
          tmpOptions[list] = convertOptions(res.valores);
          setOptions(tmpOptions);
        });
      });

    setValues(tmpValues);
  };

  const convertOptions = (options) =>
    options.map((opt) => ({
      value: opt.valor,
      label: opt.etiqueta,
    }));

  const search = (e) => {
    e.preventDefault();
    submit(values);
  };

  const handleChangeFilter = (idFilter) => {
    if (idFilter) {
      const filterValues = listFilters.find(
        (filter) => filter.id === idFilter
      ).filtro;
      let field = filterValues.orden.campoOrden;
      let direction = filterValues.orden.direccion;
      const newSort = { field, direction };
      setValues(filterValues);
      setCurrentSort(newSort);
    } else {
      setCurrentSort({});
    }
    setCurrentFilter(idFilter);
  };

  const handleDeleteList = () => {
    setValues(initValues);
    requestDeleteFilter();
    setCurrentSort({});
  };

  return (
    <S.Wrapper>
      <S.Header>
        <p className="first">{title}</p>
        {allowSave && listFilters && (
          <S.SaveLoad className="last">
            <div className="ml">
              <span>Filtro Actual</span>
            </div>
            <div className="ml">
              <select
                value={currentFilter}
                onChange={(e) => handleChangeFilter(e.target.value)}
              >
                <option value="">Nuevo...</option>
                {listFilters.map((filter) => (
                  <option key={filter.id} value={filter.id}>
                    {filter.nombre}
                  </option>
                ))}
              </select>
            </div>
            <div className="ml">
              <Button
                label="Guardar"
                type="cancel"
                onClick={() => requestSaveFilter(values)}
                disabled={currentFilter !== ""}
              />
            </div>
            <div className="ml">
              <Button
                label="Eliminar"
                type="cancel"
                onClick={handleDeleteList}
                disabled={currentFilter === ""}
              />
            </div>
          </S.SaveLoad>
        )}
      </S.Header>
      <S.Content ref={form} id="form" onSubmit={search}>
        {metadata.map((field) => {
          switch (field.type) {
            case "separator":
              return (
                <S.TextoSeparador key={field.name} colSpan={field.colSpan}>
                  <h2 className="bold">
                    <span>{field.label}</span>
                  </h2>
                </S.TextoSeparador>
              );
            case "list":
            case "list-filter":
              return (
                <InputList
                  key={field.name}
                  field={field}
                  value={values[field.name] || ""}
                  options={options[field.list] || []}
                  handleChange={handleListChange}
                  isClearable={
                    field.type === "list-filter" ||
                    lists[field.list]?.mostrarVacio
                  }
                  disabled={
                    field.name === "gestoriaTarea" &&
                    (values.tipoTarea === "" ||
                      options.GestoriaTarea?.length === 0)
                  }
                />
              );
            case "multiSelectList":
              return (
                <InputListMultiple
                  key={field.name}
                  field={field}
                  currentOptions={values[field.name] || []}
                  options={options[field.list] || []}
                  handleChange={handleListChange}
                  disabled={
                    (field.name === "bloque" && values.tiposTareas?.length ) 
                    ||
                    (field.name === "tiposTareas" && values.bloque?.length)
                  }
                />
              );
            case "date":
            case "datetime-local":
              return (
                <InputDate
                  key={field.name}
                  field={field}
                  value={values[field.name] || ""}
                  handleChange={handleChange}
                />
              );
            case "groupListNumber":
              return (
                <S.InputGroup key={field.name}>
                  <InputList
                    field={field.fields[0]}
                    value={values[field.fields[0]?.name] || ""}
                    options={options[field.fields[0]?.list] || []}
                    handleChange={handleListChange}
                  />
                  <InputNumber
                    field={field.fields[1]}
                    value={values[field.fields[1]?.name] || ""}
                    handleChange={handleChange}
                  />
                </S.InputGroup>
              );
            case "text":
            default:
              return (
                <InputText
                  key={field.name}
                  field={field}
                  value={values[field.name] || ""}
                  handleChange={handleChange}
                />
              );
          }
        })}
      </S.Content>
      <S.Buttons>
        <Button label="Buscar" onClick={search} form="form" type="submit" />
        <Button
          type="cancel"
          label="Limpiar"
          onClick={() => {
            setValues(initValues);
            setCurrentFilter("");
            setCurrentSort({});
          }}
        />
      </S.Buttons>
    </S.Wrapper>
  );
};

ModuleSearch.propTypes = {
  allowSave: PropTypes.bool,
  currentFilter: PropTypes.string,
  listFilters: PropTypes.array,
  lists: PropTypes.object.isRequired,
  metadata: PropTypes.array.isRequired,
  requestDeleteFilter: PropTypes.func,
  requestSaveFilter: PropTypes.func,
  setCurrentFilter: PropTypes.func,
  setCurrentSort: PropTypes.func,
  submit: PropTypes.func,
  title: PropTypes.string,
};
