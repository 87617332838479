import React from "react";
import PropTypes from "prop-types";
import * as S from "./styles";
import Select from "react-select";
import { COLORES } from "../../constants";

export const InputList = ({
  field,
  value,
  options,
  handleChange,
  isClearable,
  disabled,
}) => {
  return (
    <S.Wrapper>
      <p>{field.label}</p>
      <Select
        name={field.name}
        value={options.filter((opt) => opt.value === value)}
        options={options}
        onChange={(e) => handleChange(field, e?.value || "")}
        isClearable={isClearable}
        isDisabled={disabled}
        styles={customStyles}
      />
    </S.Wrapper>
  );
};

const customStyles = {
  control: (provided, state) => ({
    ...provided,
    minHeight: "1rem",
    boxShadow: "none",

    borderColor: state.isFocused
      ? COLORES.LIST_BORDER
      : COLORES.LIST_LIGHT_BORDER,
    "&:hover": {
      borderColor: state.isFocused
        ? COLORES.LIST_BORDER
        : COLORES.LIST_LIGHT_BORDER,
    },

    backgroundColor: state.isDisabled
      ? COLORES.DEFAULT_MODULE_BACKGROUND
      : COLORES.MULTISELECT_BACKGROUND,
    borderRadius: "2px",
  }),
  menu: (provided, state) => ({
    ...provided,
    margin: 0,
  }),
  menuList: (provided, state) => ({
    ...provided,
    padding: 0,
    borderRadius: "5px",
  }),
  multiValue: (styles) => ({
    ...styles,
    margin: "1px",
  }),
  multiValueLabel: (styles, { data }) => ({
    ...styles,
    fontSize: "0.5rem",
  }),
  valueContainer: (styles) => ({
    ...styles,
    padding: "0 .2rem",
  }),
  input: (styles) => ({
    ...styles,
    margin: 0,
  }),
  indicatorSeparator: (provided, state) => ({}),
  dropdownIndicator: (provided, state) => ({
    ...provided,
    padding: 0,
  }),
  clearIndicator: (provided, state) => ({
    ...provided,
    padding: 0,
  }),
  option: (provided, state) => ({
    ...provided,
    minHeight: "1.5rem",
    fontSize: "0.65rem",
    padding: "6px 12px",
    borderBottom: `1px solid ${COLORES.LIST_LIGHT_BORDER}`,
    backgroundColor: state.isSelected
      ? COLORES.LIST_ACTIVE_BACKGROUND
      : state.isFocused
      ? COLORES.LIST_HOVER_BACKGROUND
      : "white",
    "&:hover": {
      backgroundColor: state.isSelected
        ? COLORES.LIST_ACTIVE_BACKGROUND
        : COLORES.LIST_HOVER_BACKGROUND,
    },
    overflow: "hidden",
  }),
};

InputList.propTypes = {
  field: PropTypes.object,
  value: PropTypes.string,
  options: PropTypes.array,
  handleChange: PropTypes.func,
  isClearable: PropTypes.bool,
  disabled: PropTypes.bool,
};
