import React, { useEffect, useState } from "react";
import * as S from "./styles";
import * as API from "../../api";
import { toast } from "react-toastify";
import { ModuleSearch } from "../../components/ModuleSearch";
import { Loading } from "../../components/Loading";
import { Modal } from "../../components/Modal";
import {
  METADATA_SEARCH_ROLES,
  METADATA_RESULT_ROLES,
  ROLES_BY_PAGE,
} from "../../constants";
import { ModuleResultRoles } from "../../components/ModuleResultRoles";
import { Button } from "../../components/Button";
import { Link } from "react-router-dom";

export const BuscadorRoles = () => {
  const [lists, setLists] = useState({});
  const [result, setResult] = useState({});
  const [formData, setFormData] = useState({});
  const [page, setPage] = useState(1);
  const [currentSort, setCurrentSort] = useState({});
  const [visibleModal, setVisibleModal] = useState("");
  const [listFilters, setListFilters] = useState([]);
  const [currentFilter, setCurrentFilter] = useState("");
  const [dataFilter, setDataFilter] = useState("");
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    let isMounted = true;

    API.obtenerFiltros({ modulo: "BuscadorRoles" })
      .then((res) => isMounted && setListFilters(res))
      .catch((error) => API.DEVELOP && console.log(error));

    API.obtenerListas("BuscadorRoles", 0)
      .then((res) => isMounted && setLists(res))
      .catch((error) => API.DEVELOP && console.log(error))
      .finally(() => setLoading(false));

    return () => (isMounted = false);
  }, []);

  const searchRoles = (data = formData, update = false) => {
    setLoading(true);
    setFormData(data);
    data.paginacion = pagination(1);
    API.obtenerRoles(data)
      .then((res) => {
        setResult(res || {});
        !update && setCurrentSort({});
        setPage(2);
      })
      .catch((error) => API.DEVELOP && console.log(error))
      .finally(() => setLoading(false));
  };

  const moreRoles = () => {
    setLoading(true);
    const data = formData;
    data.paginacion = pagination(page);
    API.obtenerRoles(data)
      .then((res) => {
        setResult({
          informacionRegistros: res.informacionRegistros,
          registros: [...result.registros, ...res.registros],
        });
        setPage(page + 1);
        setLoading(false);
      })
      .catch((error) => API.DEVELOP && console.log(error));
  };

  const sort = (field) => {
    setLoading(true);
    let direction;
    if (field !== currentSort.field) direction = 1;
    else direction = currentSort.direction === 1 ? 2 : 1;
    const newSort = { field, direction };
    setCurrentSort(newSort);

    const data = formData;
    data.orden = order(newSort);
    data.paginacion = pagination(1);
    API.obtenerRoles(data)
      .then((res) => {
        setResult(res || {});
        setPage(2);
        setLoading(false);
      })
      .catch((error) => API.DEVELOP && console.log(error));
  };

  const pagination = (page) => {
    return {
      pagina: page,
      cantidadRegistros: ROLES_BY_PAGE,
    };
  };

  const order = (data = currentSort) => {
    return {
      campoOrden: data.field,
      direccion: data.direction,
    };
  };

  const requestSaveFilter = (data) => {
    setDataFilter(data);
    setVisibleModal("saveFilter");
  };

  const saveFilter = (name) => {
    setLoading(true);
    const data = {};
    data.modulo = "BuscadorRoles";
    data.nombre = name;
    data.filtro = dataFilter;

    API.guardarFiltro(data)
      .then((res) => {
        if (res.resultadoPeticion === 1) {
          const tmpListFilters = [...listFilters];
          data.id = res.idFiltro;
          tmpListFilters.push(data);
          setListFilters(tmpListFilters);
          setCurrentFilter(res.idFiltro);
          toast.success("Filtro Guardado");
        } else
          toast.error("No se pudo guardar el filtro\n" + res.mensajeUsuario);
      })
      .catch((error) => {
        toast.error("No se pudo guardar el filtro");
        API.DEVELOP && console.log(error);
      })
      .finally(() => {
        setLoading(false);
        setVisibleModal("");
      });
  };

  const requestDeleteFilter = (data) => {
    setVisibleModal("deleteFilter");
  };

  const deleteFilter = () => {
    setLoading(true);
    const data = {};
    data.idFiltro = currentFilter;

    API.eliminarFiltro(data)
      .then((res) => {
        if (res.resultadoPeticion === 1) {
          const tmpListFilters = [...listFilters].filter(
            (filter) => filter.id !== currentFilter
          );
          setListFilters(tmpListFilters);
          setCurrentFilter("");
          toast.success("Filtro Eliminado");
        } else
          toast.error("No se pudo eliminar el filtro\n" + res.mensajeUsuario);
      })
      .catch((error) => {
        toast.error("No se pudo eliminar el filtro");
        API.DEVELOP && console.log(error);
      })
      .finally(() => {
        setLoading(false);
        setVisibleModal("");
      });
  };

  return (
    <S.Wrapper>
      {loading && <Loading />}

      {visibleModal === "saveFilter" && (
        <Modal
          type="saveFilter"
          title="Guardar Filtro"
          message="Indique el nombre del nuevo filtro"
          labelConfirm="Guardar"
          confirm={saveFilter}
          cancel={() => setVisibleModal("")}
        />
      )}
      {visibleModal === "deleteFilter" && (
        <Modal
          type="deleteFilter"
          title="Eliminar Filtro"
          message="¿Seguro que desea eliminar el filtro seleccionado?"
          labelConfirm="Eliminar"
          confirm={deleteFilter}
          cancel={() => setVisibleModal("")}
        />
      )}
      <S.Buttons>
        <Link to={"/Rol/00000000-0000-0000-0000-000000000000"}>
          <Button label="Crear Nuevo Rol" />
        </Link>
      </S.Buttons>
      <ModuleSearch
        title="Buscador Roles: Filtro"
        metadata={METADATA_SEARCH_ROLES}
        lists={lists}
        submit={searchRoles}
        listFilters={listFilters}
        currentFilter={currentFilter}
        setCurrentFilter={setCurrentFilter}
        requestSaveFilter={requestSaveFilter}
        requestDeleteFilter={requestDeleteFilter}
      />

      <ModuleResultRoles
        title="Resultados"
        metadata={METADATA_RESULT_ROLES}
        data={result}
        moreResults={moreRoles}
        currentSort={currentSort}
        sort={sort}
      />
    </S.Wrapper>
  );
};
