import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import * as S from "./styles";
import * as API from "../../api";
import { Link } from "react-router-dom";
import { MAIN_MENU } from "../../constants";
import logo from "../../assets/images/logoAliseda.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCaretDown,
  faRightFromBracket,
} from "@fortawesome/free-solid-svg-icons";
import { useMsal } from "@azure/msal-react";
import { usePermissions } from "../../context/PermissionsContext";
import { Loading } from "../Loading";
const { REACT_APP_INFORMES_BASE_URL } = process.env;

export const NavMenu = ({ pathname }) => {
  const { permissions } = usePermissions();
  const [active, setActive] = useState("");
  const [userMenuOpened, setUserMenuOpened] = useState(false);
  const { instance, accounts } = useMsal();
  const username = accounts[0]?.username;
  const [loading, setLoading] = useState(false);
  useEffect(() => setActive(pathname), [pathname]);

  const openInformes = () => {
    setLoading(true);
    API.generarAutorizacionReporte()
      .then((res) => {
        const reportesPath = `${REACT_APP_INFORMES_BASE_URL}/signIn?key=${encodeURIComponent(
          res
        )}`;
        window.open(reportesPath, "_blank");
      })
      .catch((error) => console.log("generarAutorizacionReporte", error))
      .finally(() => setLoading(false));
  };

  const logout = () => {
    localStorage.clear();
    instance.logoutRedirect({ postLogoutRedirectUri: "/" });
  };

  return (
    <S.Nav>
      {loading && <Loading />}
      <Link to="/">
        <S.Img src={logo} alt="Logo" onClick={() => setUserMenuOpened(false)} />
      </Link>
      <S.MenuContainer>
        <ul>
          {MAIN_MENU.map((menu) => (
            <li
              key={menu.path}
              className={active === menu.path ? "active" : null}
            >
              {permissions[menu.path]?.Visualizar && (
                <Link to={`/${menu.path}`} onClick={() => setActive(menu.path)}>
                  {menu.label}
                </Link>
              )}
            </li>
          ))}
          {permissions.Informes?.Visualizar && (
            <li onClick={openInformes}>
              <div>Informes</div>
            </li>
          )}
        </ul>
      </S.MenuContainer>
      <S.MenuDropdownContainer>
        <S.User>{username}</S.User>
        <S.ButtonsContainer>
          <S.Dropdown onClick={() => setUserMenuOpened(!userMenuOpened)}>
            <FontAwesomeIcon icon={faCaretDown} />
          </S.Dropdown>
          {userMenuOpened && (
            <S.Ul>
              {permissions.EsAdmin?.Visualizar && (
                <Link to={"/BuscadorUsuarios"}>
                  <S.Li onClick={() => setUserMenuOpened(false)}>Usuarios</S.Li>
                </Link>
              )}
              {permissions.EsAdmin?.Visualizar && (
                <Link to={"/BuscadorRoles"}>
                  <S.Li onClick={() => setUserMenuOpened(false)}>Roles</S.Li>
                </Link>
              )}
              <S.Li id="last" onClick={logout}>
                <span>
                  <FontAwesomeIcon icon={faRightFromBracket} size="lg" />
                </span>
                Cerrar sesión
              </S.Li>
            </S.Ul>
          )}
        </S.ButtonsContainer>
      </S.MenuDropdownContainer>
    </S.Nav>
  );
};

NavMenu.propTypes = {
  pathname: PropTypes.string.isRequired,
};
