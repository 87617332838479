import React from "react";
import PropTypes from "prop-types";
import * as S from "./styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faTrash } from "@fortawesome/free-solid-svg-icons";

export const ModuleAuditoriaCampos = ({
  data = [],
  metadata,
  ocultar,
  access
}) => {
  return (
    <S.Wrapper>
      <S.Header>
        <span>Notificaciones de Campos</span>
      </S.Header>
      <S.Content>
      <table>
          <thead>
            <S.TrColumnTitles>
              {metadata.map((e) => (
                <th key={e.name}>{e.label}</th>
              ))}
            </S.TrColumnTitles>
          </thead>
          <tbody>
            {data.map((register,i) => (
              <tr key={i}>
                {metadata.map((field) => (
                  <td key={field.name}>
                    {register[field.name]}
                    {field.name === "ocultar" && (
                      <FontAwesomeIcon
                        icon={faEye}
                        onClick={()=>ocultar(register.idNotificacion,true,false)}
                        disabled={!access?.Editar}
                        size="lg"
                      />
                    )}
                    {field.name === "eliminar" && (
                      <FontAwesomeIcon
                        icon={faTrash}
                        onClick={()=>ocultar(register.idNotificacion,false,true)}
                        disabled={!access?.Editar}
                        size="lg"
                      />
                    )}
                  </td>
                ))}
              </tr>
            ))}
            {data.length === 0 && (
              <tr>
                <td>No hay registros</td>
              </tr>
            )}
          </tbody>
        </table>
      </S.Content>
    </S.Wrapper>
  );
};

ModuleAuditoriaCampos.propTypes = {
  data: PropTypes.array,
  metadata: PropTypes.array,
  ocutar:PropTypes.func,
  access:PropTypes.object
};
