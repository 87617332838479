import React from "react";
import PropTypes from "prop-types";
import * as S from "./styles";
import { Button } from "../Button";
import { METADATA_CAMPOS_OPCIONALES_DATOS_GENERALES } from "../../constants";

export const ModuleTareaDatosGenerales = ({
  data = {},
  metadata = [],
  label = "Datos Generales",
  columns,
  button,
}) => {
  const renderField = (field) => {
    switch (field.type) {
      case "textarea":
        return <textarea value={data[field.name] || ""} disabled />;

      default:
        return <p>{data[field.name]}</p>;
    }
  };

  return (
    <S.Wrapper>
      <S.Header>
        <p>{label}</p>
      </S.Header>
      <S.Content columns={columns}>
        {metadata.map((field) => (
          !(!data.checkTanteoRetractoAyuntamiento && METADATA_CAMPOS_OPCIONALES_DATOS_GENERALES.includes(field.name)) &&
          <S.Field key={field.name} colSpan={field.colSpan}>
            <p className="bold">{field.label}</p>
            {renderField(field)}
          </S.Field>
        ))}
      </S.Content>

      {button && (
        <S.Buttons>
          <Button
            label={button.label}
            onClick={button.onClick}
            disabled={button.disabled}
          />
        </S.Buttons>
      )}
    </S.Wrapper>
  );
};

ModuleTareaDatosGenerales.propTypes = {
  data: PropTypes.object,
  metadata: PropTypes.array,
  label: PropTypes.string,
  columns: PropTypes.number,
};
