import axios from "axios";
import { loginRequest } from "../authConfig";
import { printVigenciaToken } from "../App";

const { NODE_ENV, REACT_APP_BASE_URL, REACT_APP_BASE_API_URL } = process.env;
export const DEVELOP = NODE_ENV === "development";

export const APP_BASE_URL = DEVELOP
  ? "http://localhost:3000"
  : REACT_APP_BASE_URL;
const BASE_API_URL = REACT_APP_BASE_API_URL;
const URL_AUTHENTICATE = "/Autenticacion/AutenticarUsuario";

const axiosInstance = axios.create({
  baseURL: BASE_API_URL,
});

let requestInterceptorId;
let responseInterceptorId;

const isTokenValid = (url = "empty_url", account) => {
  const currentTime = Date.now();
  const msalToken = localStorage.getItem("msalToken");
  const msalTokenExpiresOn = Number(localStorage.getItem("msalTokenExpiresOn"));
  const appToken = localStorage.getItem("appToken");
  const appTokenExpiresOn = Number(localStorage.getItem("appTokenExpiresOn"));

  if (DEVELOP) {
    console.group(`%c¿isTokenValid? ${url}`, "color: yellow");
    console.log(`%c[IReq] ${new Date(currentTime)}`, "color: cyan;");
    console.log(`[IReq] account`, account?.username);
    console.log(`[IReq] msalToken`, msalToken?.substring(0, 100));
    printVigenciaToken(msalTokenExpiresOn, "msalTokenExpiresOn", "IReq");
    console.log(`[IReq] appToken`, appToken?.substring(0, 100));
    printVigenciaToken(appTokenExpiresOn, "appTokenExpiresOn", "IReq");
  }

  if (url === URL_AUTHENTICATE) {
    if (account && msalToken && currentTime < msalTokenExpiresOn) {
      if (DEVELOP) {
        console.log(
          "%c[IReq] isTokenValid",
          "color: lightgreen; font-weight: bold"
        );
        console.groupEnd();
      }
      return true;
    }

    if (DEVELOP) {
      console.log("%c[IReq] !isTokenValid", "color: red; font-weight: bold");
      console.groupEnd();
    }
    return false;
  }

  // url !== URL_AUTHENTICATE
  if (
    account &&
    msalToken &&
    currentTime < msalTokenExpiresOn &&
    appToken &&
    currentTime < appTokenExpiresOn
  ) {
    if (DEVELOP) {
      console.log(
        "%c[IReq] isTokenValid",
        "color: lightgreen; font-weight: bold"
      );
      console.groupEnd();
    }
    return true;
  }

  if (DEVELOP) {
    console.log("%c[IReq] !isTokenValid", "color: red; font-weight: bold");
    console.groupEnd();
  }
  return false;
};

const waitForLocalStorage = (key, timeout = 5000, interval = 100) => {
  return new Promise((resolve, reject) => {
    if (localStorage.getItem(key) !== null) {
      return resolve(localStorage.getItem(key));
    }

    const startTime = Date.now();
    const checkInterval = setInterval(() => {
      if (localStorage.getItem(key) !== null) {
        clearInterval(checkInterval);
        resolve(localStorage.getItem(key));
      } else if (Date.now() - startTime > timeout) {
        clearInterval(checkInterval);
        reject(new Error(`Timeout: ${key} no encontrado en localStorage`));
      }
    }, interval);
  });
};

export const configureAxios = (msalInstance, setPermissions) => {
  DEVELOP && console.log("[configureAxios] Start");
  //region requestInterceptor
  if (requestInterceptorId !== undefined)
    axiosInstance.interceptors.request.eject(requestInterceptorId);

  let tokenFetching = false;
  const pendingRequestsQueue = [];

  const resolvePendingRequests = (newToken) => {
    DEVELOP && console.log("%cresolvePendingRequests", "color: yellow");
    pendingRequestsQueue.forEach(({ resolve, config }) => {
      DEVELOP && console.log(`%cResolviendo ${config.url}`, "color: magenta");
      const newConfig = { ...config };
      newConfig.headers.Authorization = `Bearer ${newToken}`;
      resolve(newConfig);
    });
    pendingRequestsQueue.length = 0;
  };

  const rejectPendingRequests = (error) => {
    DEVELOP && console.log("%crejectPendingRequests", "color: pink");
    pendingRequestsQueue.forEach(({ reject }) => reject(error));
    pendingRequestsQueue.length = 0;
  };

  requestInterceptorId = axiosInstance.interceptors.request.use(
    async (config) => {
      const account = msalInstance.getActiveAccount();
      let appToken = localStorage.getItem("appToken") || "";
      try {
        await waitForLocalStorage("started");
      } catch (error) {
        console.error("waitForLocalStorage -> started", error);
      }

      if (isTokenValid(config.url, account)) {
        config.headers.Authorization = `Bearer ${appToken}`;
        return config;
      } else {
        if (tokenFetching) {
          // Si la renovación del token está en curso, encola la solicitud
          const promise = new Promise((resolve, reject) => {
            DEVELOP &&
              console.log(
                `%ctokenFetching, ${config.url} a la cola`,
                "color: magenta"
              );
            pendingRequestsQueue.push({ resolve, reject, config });
          });
          // Espera hasta que se resuelva la promesa para completar la llamada
          await promise;
          return config;
        } else {
          DEVELOP &&
            console.log(
              `%c!tokenFetching -> ${config.url} inicia obtención de nuevo(s) token(s)`,
              "color: magenta"
            );
          tokenFetching = true;
          try {
            const currentTime = Date.now();
            const msalTokenExtExpiresOn = Number(
              localStorage.getItem("msalTokenExtExpiresOn")
            );
            if (!msalTokenExtExpiresOn || currentTime > msalTokenExtExpiresOn) {
              DEVELOP &&
                console.log(
                  "%c[IReq] msalTokenExtExpiresOn expirado (o inexistente) => msalInstace.loginRedirect",
                  "color: pink"
                );
              msalInstance
                .loginRedirect(loginRequest)
                .catch((error) =>
                  console.error(
                    "[IReq] msalInstance.loginRedirect->error",
                    error
                  )
                );
              return new Promise(() => {});
            }

            const msalTokenExpiresOn = Number(
              localStorage.getItem("msalTokenExpiresOn")
            );
            const accessTokenRequest = { ...loginRequest, account };
            if (!msalTokenExpiresOn || currentTime > msalTokenExpiresOn) {
              DEVELOP &&
                console.log(
                  "%cmsalTokenExpiresOn expirado (o inexistente) => msalInstance.acquireTokenSilent",
                  "color: pink"
                );
              const resAcquireTokenSilent =
                await msalInstance.acquireTokenSilent(accessTokenRequest);
              const msalToken = resAcquireTokenSilent.accessToken;
              DEVELOP &&
                console.log(
                  "%cnuevo msalToken (acquireTokenSilent)",
                  "color: lightgreen",
                  msalToken.substring(0, 100)
                );
              const msalTokenExpiresOn = Number(
                resAcquireTokenSilent.expiresOn
              );
              const msalTokenExtExpiresOn = Number(
                resAcquireTokenSilent.extExpiresOn
              );
              localStorage.setItem("msalToken", msalToken);
              localStorage.setItem(
                "msalTokenExpiresOn",
                msalTokenExpiresOn.toString()
              );
              localStorage.setItem(
                "msalTokenExtExpiresOn",
                msalTokenExtExpiresOn.toString()
              );
            }

            if (config.url !== URL_AUTHENTICATE) {
              const appTokenExpiresOn = Number(
                localStorage.getItem("appTokenExpiresOn")
              );
              const msalToken = localStorage.getItem("msalToken") || "";
              if (!appToken || currentTime > appTokenExpiresOn) {
                DEVELOP &&
                  console.log(
                    "%cappToken expirado (o inexistente) => autenticar()",
                    "color: pink"
                  );
                const data = { tokenEntrada: msalToken };
                const resAutenticar = await autenticar(data);
                appToken = resAutenticar.token;
                DEVELOP &&
                  console.log(
                    "%cnuevo appToken (autenticar)",
                    "color: lightgreen",
                    msalToken.substring(0, 100)
                  );
                localStorage.setItem("appToken", resAutenticar.token);
                localStorage.setItem(
                  "appTokenExpiresOn",
                  resAutenticar.RefreshToken
                );
                setPermissions(resAutenticar.accesoModulo);
              }
            }

            config.headers.Authorization = `Bearer ${appToken}`;
            DEVELOP && console.log("%ctokenFetched", "color: magenta");
            tokenFetching = false;
            // Resuelve las promesas encoladas con el nuevo token
            resolvePendingRequests(appToken);
            return config;
          } catch (error) {
            console.error("[IReq] acquireTokenSilent | autenticar", error);
            DEVELOP && console.log("%ctokenFetching = false", "color: magenta");
            tokenFetching = false;
            // Rechaza las promesas encoladas con el error de renovación del token
            rejectPendingRequests(error);
            return Promise.reject(error);
          }
        }
      }
    },
    (error) => {
      console.error("[IReq] interceptors.request->error", error);
      return Promise.reject(error);
    }
  );

  //region responseInterceptor
  if (responseInterceptorId !== undefined)
    axiosInstance.interceptors.response.eject(responseInterceptorId);

  responseInterceptorId = axiosInstance.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      const originalRequest = error.config;
      if (error.response?.status === 401) {
        if (originalRequest.url === URL_AUTHENTICATE) {
          localStorage.setItem("appToken", "notAuthorized");
          window.location.href = `${APP_BASE_URL}/noAccess`;
        }
      }
      return Promise.reject(error);
    }
  );

  DEVELOP && console.log("[configureAxios] End");
};

export const autenticar = (data) => {
  const url = URL_AUTHENTICATE;
  return post(url, data);
};

const get = async (url) => {
  try {
    const response = await axiosInstance.get(url);
    return response.data || null;
  } catch (error) {
    if (error.response) {
      const res = {
        code: error.response.status,
        msg: error.response.data,
        error: error,
      };
      throw res;
    } else {
      const res_1 = { code: 500, msg: error.message, error: error };
      throw res_1;
    }
  }
};

const post = async (url, data, config) => {
  try {
    const response = await axiosInstance.post(url, data, config);
    if (config?.responseType === "blob") return response || null;
    return response.data || null;
  } catch (error) {
    if (error.response) {
      const res = {
        code: error.response.status,
        msg: error.response.data,
        error: error,
      };
      throw res;
    } else {
      const res_1 = { code: 500, msg: error.message, error: error };
      throw res_1;
    }
  }
};

// SERVICE ENDPOINTS

// ACCESO
export const obtenerPermisosUsuario = () => {
  const url = "/Autenticacion/ObtenerPermisosUsuario";
  return get(url);
};

//ACCESO REPORTES
export const generarAutorizacionReporte = () => {
  const url = `/Autenticacion/GenerarAutorizacionReporte`;
  return get(url);
};

// LISTAS
export const obtenerListas = (moduleName, idCartera, idTarea = null) => {
  const url = `/Lista/ObtenerListas/${moduleName}/${idCartera}/${idTarea}`;
  return get(url);
};

export const obtenerLista = (data) => {
  const url = "/Lista/ObtenerLista";
  return post(url, data);
};

//BUSCADOR TAREAS
export const buscadordeTareas = (data) => {
  const url = "/Buscadores/BuscadordeTareas";
  return post(url, data);
};

export const completarTarea = (data) => {
  const url = "/AdministradorFlujoServicio/CompletarTarea";
  return post(url, data);
};

export const asignarTarea = (data) => {
  const url = "/AdministradorFlujoServicio/AsignarTarea";
  return post(url, data);
};
export const reabrirTarea = (data) => {
  const url = "/AdministradorFlujoServicio/ReAbrirTarea";
  return post(url, data);
};
export const reAsignarTarea = (data) => {
  const url = "/AdministradorFlujoServicio/ReAsignarTarea";
  return post(url, data);
};

export const guardarFiltro = (data) => {
  const url = "/Buscadores/GuardarFiltro";
  return post(url, data);
};

export const obtenerFiltros = (data) => {
  const url = "/Buscadores/ObtenerFiltros";
  return post(url, data);
};

export const eliminarFiltro = (data) => {
  const url = "/Buscadores/EliminarFiltro";
  return post(url, data);
};

export const exportarTareasExcel = (data) => {
  const url = "/Buscadores/ExportarTareasExcel";
  return post(url, data, { responseType: "blob" });
};

//BUSCADOR RESERVAS
export const buscadordeReservas = (data) => {
  const url = "/Buscadores/BuscadordeReservas";
  return post(url, data);
};

export const exportarReservaExcel = (data) => {
  const url = "/Buscadores/ExportarReservaExcel";
  return post(url, data, { responseType: "blob" });
};

//BUSCADOR NOTARIOS
export const obtenerNotarios = (data) => {
  const url = "/Notario/ObtenerNotarios";
  return post(url, data);
};

export const obtenerNotario = (data) => {
  const url = "/Notario/ObtenerNotario";
  return post(url, data);
};

export const crearActualizarNotario = (data) => {
  const url = "/Notario/CrearActualizarNotario";
  return post(url, data);
};
//Asignar Gestoria
export const obtenerReservaGestoria = (data) => {
  const url = "/AsignacionGestoria/ObtenerReservaGestoria";
  return post(url, data);
};
export const crearModificarReservaGestoria = (data) => {
  const url = "/AsignacionGestoria/CrearModificarReservaGestoria";
  return post(url, data);
};
export const crearModificarMultiplesReservaGestoria = (data) => {
  const url = "/AsignacionGestoria/CrearModificarMultiplesReservaGestoria";
  return post(url, data);
};
export const eliminarReservaGestoria = (data) => {
  const url = "/AsignacionGestoria/EliminarReservaGestoria";
  return post(url, data);
};
export const obtenerGestoriaProvincia = (data) => {
  const url = "/AsignacionGestoria/ObtenerGestoriaProvincia";
  return post(url, data);
};
export const modificarMultipleGestoriaTarea = (data) => {
  const url = "/AsignacionGestoria/ModificarMultipleGestoriaTarea";
  return post(url, data);
};

// ROLES
export const obtenerRoles = (data) => {
  const url = "/Roles/ObtenerRoles";
  return post(url, data);
};

export const obtenerRol = (data) => {
  const url = "/Roles/ObtenerRol";
  return post(url, data);
};

export const obtenerTiposPermisos = () => {
  const url = "/Roles/ObtenerTiposPermisos";
  return get(url);
};

export const crearActualizarRol = (data) => {
  const url = "/Roles/CrearActualizarRol";
  return post(url, data);
};

//BUSCADOR USUARIOS
export const obtenerUsuarios = (data) => {
  const url = "/Autenticacion/ObtenerUsuarios";
  return post(url, data);
};

export const obtenerUsuario = (data) => {
  const url = "/Autenticacion/ObtenerUsuario";
  return post(url, data);
};

export const crearActualizarUsuario = (data) => {
  const url = "/Autenticacion/CrearActualizarUsuario";
  return post(url, data);
};

// TAREA ANÁLISIS
export const abrirTarea = (data) => {
  const url = "/AdministradorFlujoServicio/AbrirTarea";
  return post(url, data);
};

export const editarDocumentoActivo = (data) => {
  const url = "/Documentacion/EditarDocumentoActivo";
  return post(url, data);
};

export const actualizarDocumentoReserva = (data) => {
  const url = "/Documentacion/ActualizarDocumentoReserva";
  return post(url, data);
};

export const actualizarDocumentoActivo = (data) => {
  const url = "/Documentacion/ActualizarDocumentoActivo";
  return post(url, data);
};
export const eliminarDocumentoActivo = (data) => {
  const url = "/Documentacion/EliminarDocumentoActivo";
  return post(url, data);
};

export const crearDocumentoReserva = (data) => {
  const url = "/Documentacion/CrearDocumentoReserva";
  return post(url, data);
};

export const crearDocumentoActivo = (data) => {
  const url = "/Documentacion/CrearDocumentoActivo";
  return post(url, data);
};

export const descargarDocumento = (data) => {
  const url = "/Documentacion/DescargarDocumento";
  return post(url, data, { responseType: "blob" });
};

export const obtenerRevisionFondos = (data) => {
  const url = "/TareaAnalisis/ObtenerRevisionFondos";
  return post(url, data);
};

export const obtenerActivosCancelacion = (data) => {
  const url = "/TareaAnalisis/ObtenerActivosCancelacion";
  return post(url, data);
};

export const obtenerActivosTitularidad = (data) => {
  const url = "/TareaAnalisis/ObtenerActivosTitularidad";
  return post(url, data);
};
export const guardarVisibilidadNotificacionAnalisis = (data) => {
  const url = "/TareaAnalisis/GuardarVisibilidadNotificacionAnalisis";
  return post(url, data);
};
export const guardarVisibilidadNotificacionRevOp = (data) => {
  const url = "/Formalizacion/GuardarVisibilidadNotificacionRevOp";
  return post(url, data);
};

export const nuevaTareaCarga = (data) => {
  const url = "/TareaAnalisis/NuevaTareaCarga";
  return post(url, data);
};

export const nuevoFondo = (data) => {
  const url = "/TareaAnalisis/Nuevofondo";
  return post(url, data);
};

export const cancelarProcesoCarga = (data) => {
  const url = "/TareaAnalisis/CancelarProcesoCarga";
  return post(url, data);
};

export const guardarCompletarTareaAnalisis = (data) => {
  const url = "/TareaAnalisis/GuardarCompletarTareaAnalisis";
  return post(url, data);
};

// CARGA
export const obtenerCarga = (data) => {
  const url = "/TareaAnalisis/ObtenerCarga";
  return post(url, data);
};

// CONFIRMACION ASISTENCIA FIRMA
export const completarConfirmacionAsistenciaFirma = (data) => {
  const url = "/Formalizacion/CompletarTareaConfirmacionAsistenciaFirma";
  return post(url, data);
};

// TIPO FISCALIDAD
export const completarTipoFiscalidad = (data) => {
  const url = "/Formalizacion/CompletarTareaTipoFiscalidad";
  return post(url, data);
};

// VISTO BUENO SSJJ
export const guardarCompletarTareaMinutaSSJJ = (data) => {
  const url = "/Formalizacion/GuardarCompletarTareaMinutaSSJJ";
  return post(url, data);
};

// CONFIRMACION FIRMA

export const guardarCompletarTareaConfirmacionFirma = (data) => {
  const url = "/Formalizacion/GuardarCompletarTareaConfirmacionFirma";
  return post(url, data);
};

// REPORTE GESTORIA

export const guardarCompletarTareaReporteGestoria = (data) => {
  const url = "/Formalizacion/GuardarCompletarTareaReporteGestoria";
  return post(url, data);
};

export const crearChequeReporteGestoria = (data) => {
  const url = "/Formalizacion/CrearChequeReporteGestoria";
  return post(url, data);
};

// TAREA RECLAMACIÓN
export const obtenerTareaReclamacion = (data) => {
  const url = "/TareaReclamacion/ObtenerTareaReclamacion";
  return post(url, data);
};

export const obtenerListasReclamacion = (data) => {
  const url = "/Lista/ObtenerListasReclamacion";
  return post(url, data);
};

export const completarTareaReclamacion = (data) => {
  const url = "/TareaReclamacion/CompletarTareaReclamacion";
  return post(url, data);
};

// TAREA REVISIÓN OP
export const obtenerTareaRevisionOP = (data) => {
  const url = "/Formalizacion/ObtenerTareaRevisionOP";
  return post(url, data);
};

export const guardarCompletarTareaRevisionOP = (data) => {
  const url = "/Formalizacion/GuardarCompletarTareaRevisionOP";
  return post(url, data);
};

export const crearTareaConfirmacionAsistenciaFirma = (data) => {
  const url = "/Formalizacion/CrearTareaConfirmacionAsistenciaFirma";
  return post(url, data);
};

export const crearTareaMinutaSSJJ = (data) => {
  const url = "/Formalizacion/CrearTareaMinutaSSJJ";
  return post(url, data);
};

export const crearTareaTipoFiscalidad = (data) => {
  const url = "/Formalizacion/CrearTareaTipoFiscalidad";
  return post(url, data);
};

export const obtenerDireccionesCorreo = (data) => {
  const url = "/Lista/ObtenerDireccionesCorreo";
  return post(url, data);
};

export const enviarCorreo = (data) => {
  const url = "/Formalizacion/EnviarCorreo";
  return post(url, data);
};

export const solicitarEnviarAutorizacion = (data) => {
  const url = "/Formalizacion/SolicitarEnviarAutorizacion";
  return post(url, data);
};

// AUTORIZACION

export const obtenerTareaAutorizar = (data) => {
  const url = "/TareaAutorizar/ObtenerTareaAutorizar";
  return post(url, data);
};

export const completarTareaAutorizar = (data) => {
  const url = "/TareaAutorizar/CompletarTareaAutorizar";
  return post(url, data);
};

// FICHA RESERVA
export const obtenerFichaReserva = (data) => {
  const url = "/FichaReserva/ObtenerFichaReserva";
  return post(url, data);
};

export const iniciarFormalizacion = (data) => {
  const url = "/FichaReserva/IniciarFormalizacion";
  return post(url, data);
};

export const obtenerContenidoCorreo = (data) => {
  const url = "/FichaReserva/ObtenerContenidoCorreo";
  return post(url, data);
};
