import styled from "styled-components/macro";

export const Wrapper = styled.div`
  width: 100%;
  grid-column: ${(props) => props.colSpan};

  p,
  textarea {
    width: 100%;
    box-sizing: border-box;
  }

  p {
    height: 0.85rem;
    font-weight: bold;
  }

  textarea {
    border: thin solid #858585;
    border-radius: 0.1rem;
    resize: none;
    outline: none;
  }
`;
