import React from "react";
import PropTypes from "prop-types";
import * as S from "./styles";

export const InputText = ({
  field,
  value,
  placeholder,
  handleChange,
  disabled,
}) => {
  return (
    <S.Wrapper>
      <p>{field.label}</p>
      <input
        name={field.name}
        value={value}
        placeholder={placeholder}
        onChange={(e) => handleChange(field.name, e.target.value)}
        disabled={disabled}
      />
    </S.Wrapper>
  );
};

InputText.propTypes = {
  field: PropTypes.object.isRequired,
  value: PropTypes.string,
  placeholder: PropTypes.string,
  handleChange: PropTypes.func,
  disabled: PropTypes.bool,
};
