import React from "react";
import * as S from "./styles";
import logo from "../../assets/images/logoAliseda.png";

export const Loading = () => {
  return (
    <S.Wrapper>
      <img src={logo} alt="Logo" />
    </S.Wrapper>
  );
};
