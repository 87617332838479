import React, { useState, useEffect } from "react";
import * as S from "./styles";
import * as API from "../../api";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { ModuleTareaDatosGenerales } from "../../components/ModuleTareaDatosGenerales";
import { ModuleEstadosDocumentacion } from "../../components/ModuleEstadosDocumentacion";
import { Button } from "../../components/Button";
import { Loading } from "../../components/Loading";
import {
  METADATA_CARGA_DATOS_GENERALES,
  METADATA_CARGA_INFO_TAREA,
} from "../../constants";

export const Carga = () => {
  const { idCarga } = useParams();
  const [datosGenerales, setDatosGenerales] = useState({});
  const [informacionGeneral, setInformacionGeneral] = useState({});
  const [estadosDocumentacion, setEstadosDocumentacion] = useState({});
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    let isMounted = true;

    API.obtenerCarga({ idCarga })
      .then((res) => {
        if (isMounted) {
          setDatosGenerales(res.datosGenerales);
          setInformacionGeneral(res.informacionGeneral);
          setEstadosDocumentacion(res.estadosDocumentacion);
        }
      })
      .catch((error) => API.DEVELOP && console.log(error))
      .finally(() => setLoading(false));

    return () => (isMounted = false);
  }, [idCarga]);

  return (
    <S.Wrapper>
      {loading && <Loading />}
      <ModuleTareaDatosGenerales
        data={datosGenerales}
        metadata={METADATA_CARGA_DATOS_GENERALES}
      />

      <ModuleTareaDatosGenerales
        data={informacionGeneral}
        metadata={METADATA_CARGA_INFO_TAREA}
        label="Información General de la Tarea"
        columns={4}
      />

      <ModuleEstadosDocumentacion
        data={estadosDocumentacion}
        setLoading={setLoading}
        toast={toast}
      />

      <S.Buttons>
        <a
          href={`/FichaReserva/${datosGenerales.idReserva}`}
          target="_blank"
          rel="noreferrer"
        >
          <Button label="Ver Ficha" disabled={!datosGenerales.idReserva} />
        </a>
      </S.Buttons>
    </S.Wrapper>
  );
};
