import React, {  useState } from "react";
import PropTypes from "prop-types";
import * as S from "./styles";
import * as API from "../../api";
import { Button } from "../Button";
import { InputList } from "../InputList";
import { InputTextArea } from "../InputTextArea";
import { toast } from "react-toastify";

export const ModalTareaRevisionOP = ({
  type,
  title,
  labelConfirm,
  lists,
  confirm,
  cancel,
  messageFiscalidad,
}) => {
  const [listsProps, setListsProps] = useState(lists);
  const [comment, setComment] = useState("");

  const handleListChange = (field, value) => {
    const tmpListsProps = { ...listsProps };
    tmpListsProps[field.list].value = value;
    if (lists[field.list]?.listasHijas.length === 0)
      setListsProps(tmpListsProps);
    else
      lists[field.list].listasHijas.forEach((list) => {
        const listaRelacional = {
          nombreLista: field.list,
          idEntidadRelacional: value,
        };
        const data = {
          lista: list,
          listaRelacional,
        };
        API.obtenerLista(data).then((res) => {
          tmpListsProps[list].valores = res.valores;
          setListsProps(tmpListsProps);
        });
      });
  };

  
  const convertOptions = (options) =>
    options.map((opt) => ({
      value: opt.valor,
      label: opt.etiqueta,
    }));

  const body = () => {
    switch (type) {
      case "especificarMotivoCambioFechaPrevista":
        return (
          <S.WrapperModalBody columns={1}>
            <h3>Especifique el motivo del cambio de fecha prevista</h3>

            <InputList
              field={{
                label: "Motivo Principal*",
                name: "grupoTipoAplazamiento",
                list: "GrupoTipoAplazamiento",
              }}
              value={listsProps.GrupoTipoAplazamiento?.value || ""}
              options={
                convertOptions(listsProps.GrupoTipoAplazamiento?.valores) || []
              }
              handleChange={handleListChange}
              isClearable={false}
            />
            <InputList
              field={{
                label: "Submotivo Aplazamiento*",
                name: "tipoAplazamiento",
                list: "TipoAplazamiento",
              }}
              value={listsProps.TipoAplazamiento?.value || ""}
              options={
                convertOptions(listsProps.TipoAplazamiento?.valores) || []
              }
              handleChange={handleListChange}
              isClearable={false}
            />
            <InputList
              field={{
                label: "Llegada de apoderado*",
                name: "tipoDocumento",
                list: "LlegadaDeApoderado",
              }}
              value={listsProps.LlegadaDeApoderado?.value || null}
              options={
                convertOptions(listsProps.LlegadaDeApoderado?.valores) || []
              }
              handleChange={handleListChange}
              isClearable={false}
            />
            <InputTextArea
              field={{ name: "", label: "Observaciones*" }}
              value={comment}
              handleChange={(name, value) => setComment(value)}
            />
          </S.WrapperModalBody>
        );
      case "solicitarAsistenciaFirma":
        return (
          <S.WrapperModalBody columns={1}>
            <h3>¿Está seguro que quiere Solicitar Asistencia Firma?</h3>
          </S.WrapperModalBody>
        );

      case "solicitarVistoBuenoSSJJ":
        return (
          <S.WrapperModalBody columns={1}>
            <h3>¿Está seguro que quiere Solicitar Visto Bueno SSJJ?</h3>
            <InputList
              field={{
                label: "Motivo",
                name: "idTipoMotivoSSJJ",
                list: "TipoMotivoSSJJ",
              }}
              value={listsProps.TipoMotivoSSJJ?.value || ""}
              options={convertOptions(lists.TipoMotivoSSJJ?.valores || [])}
              handleChange={handleListChange}
              isClearable={lists.TipoMotivoSSJJ?.mostrarVacio || false}
            />
            <InputTextArea
              field={{ name: "", label: "Motivo Solicitud" }}
              value={comment}
              handleChange={(_name, value) => setComment(value)}
            />
          </S.WrapperModalBody>
        );

      case "solicitarFiscalidad":
        return (
          <S.WrapperModalBody columns={1}>
            <h3>{messageFiscalidad}</h3>
            {messageFiscalidad === "" ? (
              <h3>¿Desea solicitar la fiscalidad?</h3>
            ) : (
              <h3>¿Desea volver a solicitar la fiscalidad?</h3>
            )}
          </S.WrapperModalBody>
        );

      case "solicitarAutorizacion":
        return (
          <S.WrapperModalBody columns={1}>
            <h3>
              Existen cargas o titularidades incompletas, necesita la
              autorización de su responsable de negocio. ¿Desea solicitar una
              autorización para completar la tarea?
            </h3>
          </S.WrapperModalBody>
        );

      default:
        return <p>Unknown type</p>;
    }
  };

  const confirmSolicitarVistoBuenoSSJJ = () => {
    const data = {};
    data.comentarioVistoBuenoSSJJ = comment;
    data.idTipoMotivoSSJJ = listsProps.TipoMotivoSSJJ?.value;
    confirm(data);
  };

  const confirmMotivoCambioFechaPrevista = () => {
    const data = {};
    data.confirmadoDesdeModal = true;
    data.observacionesMotivoCambio = comment;

    if (listsProps.LlegadaDeApoderado?.value)
      data.llegadaDeApoderado = !!+listsProps.LlegadaDeApoderado?.value;
    data.tipoAplazamiento = listsProps.TipoAplazamiento?.value;

    if (data.llegadaDeApoderado !== undefined && data.tipoAplazamiento && data.observacionesMotivoCambio){
      confirm(data);
      delete listsProps.LlegadaDeApoderado.value
      delete listsProps.TipoAplazamiento.value
      delete listsProps.grupoTipoAplazamiento.value

    }
    else if (!data.tipoAplazamiento)
      toast.error("Especifique el tipo de motivo de aplazamiento");
    else if (data.llegadaDeApoderado === undefined)
      toast.error("Especifique si ha llegado el apoderado");
    else if (data.observacionesMotivoCambio === "")
      toast.error("Especifique las observaciones del cambio");
  };

  const buttons = () => {
    switch (type) {
      case "solicitarAsistenciaFirma":
        return (
          <>
            <Button label={labelConfirm} onClick={confirm} />
            <Button label="Cancelar" onClick={cancel} type="cancel" />
          </>
        );
      case "especificarMotivoCambioFechaPrevista":
        return (
          <>
            <Button
              label={labelConfirm}
              onClick={confirmMotivoCambioFechaPrevista}
            />
            <Button label="Cancelar" onClick={cancel} type="cancel" />
          </>
        );

      case "solicitarVistoBuenoSSJJ":
        return (
          <>
            <Button
              label={labelConfirm}
              onClick={confirmSolicitarVistoBuenoSSJJ}
              disabled={
                listsProps.TipoMotivoSSJJ?.value === "" || comment === ""
              }
            />
            <Button label="Cancelar" onClick={cancel} type="cancel" />
          </>
        );

      case "solicitarFiscalidad":
        return (
          <>
            <Button label={labelConfirm} onClick={confirm} />
            <Button label="Cancelar" onClick={cancel} type="cancel" />
          </>
        );
      case "confirmarBorradoTarea":
        return (
          <>
            <Button label={labelConfirm} onClick={confirm} />
            <Button label="Cancelar" onClick={cancel} type="cancel" />
          </>
        );

      case "solicitarAutorizacion":
        return (
          <>
            <Button label={labelConfirm} onClick={confirm} />
            <Button label="Cancelar" onClick={cancel} type="cancel" />
          </>
        );

      default:
        return <Button label="Cancelar" onClick={cancel} type="cancel" />;
    }
  };

  return (
    <S.Wrapper onClick={cancel}>
      <S.Content onClick={(event) => event.stopPropagation()}>
        <S.Header>
          <p>{title}</p>
        </S.Header>
        <S.Body>
          {body()}
          <S.Buttons>{buttons()}</S.Buttons>
        </S.Body>
      </S.Content>
    </S.Wrapper>
  );
};

ModalTareaRevisionOP.propTypes = {
  type: PropTypes.string,
  title: PropTypes.string,
  labelConfirm: PropTypes.string,
  lists: PropTypes.object,
  confirm: PropTypes.func,
  cancel: PropTypes.func,
  message: PropTypes.string,
};
