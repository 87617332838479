import styled from "styled-components/macro";
import { COLORES } from "../../constants";

export const Wrapper = styled.div`
  width: 100%;
  background-color: ${COLORES.DEFAULT_MODULE_BACKGROUND};
  margin-bottom: 2rem;
  border-radius: 0.25rem;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 2rem;
  color: ${COLORES.DEFAULT_MODULE_HEADER_FONT};
  background-color: ${COLORES.DEFAULT_MODULE_HEADER_BACKGROUND};
  font-weight: bold;
  border-radius: 0.25rem 0.25rem 0 0;
  p {
    padding-left: 1rem;
  }
`;

export const DivButton = styled.button`
  color: ${COLORES.DEFAULT_MODULE_HEADER_FONT};
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding: 0.4rem 0.5rem;
  margin-right: 0.5rem;

  :disabled {
    cursor: default;
    color: ${COLORES.FONT_AWESOME_ICON_DISABLED};
  }
`;

export const Content = styled.form`
  table {
    width: 100%;
    border-collapse: collapse;

    thead {
      tr {
        height: 1.5rem;
        font-weight: bold;
      }
      th {
        text-align: left;
        padding-left: 0.5rem;
      }
    }

    tbody {
      tr {
        height: 1.5rem;
        :hover {
          background-color: ${COLORES.ROW_HOVER};
        }
      }
      td {
        padding-left: 0.5rem;
      }
    }
  }
`;

export const TrColumnTitles = styled.tr`
  background-color: ${COLORES.HEADER_ROW_BACKGROUND};

  th:nth-child(1),
  th:nth-child(2) {
    width: 15%;
  }
  th:nth-child(3),
  th:nth-child(4),
  th:nth-child(6),
  th:nth-child(7),
  th:nth-child(8) {
    width: 13%;
  }
  th:nth-child(5) {
    width: 5%;
  }
`;
