import React, { useState, useEffect } from "react";
import * as S from "./styles";
import * as API from "../../api";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { ModuleTareaDatosGenerales } from "../../components/ModuleTareaDatosGenerales";
import { ModuloSolapas } from "../../components/ModuloSolapas";
import { ModalReadEmail } from "../../components/ModalReadEmail";
import { Loading } from "../../components/Loading";
import {
  METADATA_AUDITORIA_CAMPOS_FICHA,
  METADATA_FICHA_RESERVA_DATOS_GENERALES,
} from "../../constants";
import { ModuleAuditoriaCampos } from "../../components/ModuleAuditoriaCampos";
import { usePermissions } from "../../context/PermissionsContext";

export const FichaReserva = () => {
  const { permissions } = usePermissions();
  const { idReserva } = useParams();
  const [dataReserva, setDataReserva] = useState({});
  const [lists, setLists] = useState({});
  const [datosAuditoria, setDatosAuditoria] = useState([]);
  const [idCorreo, setIdCorreo] = useState("");
  const [modal, setModal] = useState("");
  const [triggerRefresh, setTriggerRefresh] = useState(false);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    let isMounted = true;
    API.obtenerFichaReserva({ idReserva })
      .then((res) => {
        isMounted && setDataReserva(res);
        setDatosAuditoria(res.notificacion);
      })
      .catch((error) => API.DEVELOP && console.log(error))
      .finally(() => setLoading(false));
    API.obtenerListas("FichaReserva", 0)
      .then((res) => isMounted && setLists(res))
      .catch((error) => API.DEVELOP && console.log(error));
    return () => (isMounted = false);
  }, [idReserva]);
  useEffect(() => {
    if (triggerRefresh) {
      setTriggerRefresh(false);
      API.obtenerFichaReserva({ idReserva })
        .then((res) => setDataReserva(res))
        .catch((error) => API.DEVELOP && console.log(error))
        .finally(() => setLoading(false));
    }
  }, [idReserva, triggerRefresh]);

  const iniciarFormalizacion = () => {
    setLoading(true);
    API.iniciarFormalizacion({ idReserva })
      .then((res) => {
        if (res.resultadoPeticion === 1) {
          toast.success("Formalización Iniciada");
          setTriggerRefresh(true);
        } else return Promise.reject(res.mensajeUsuario);
      })
      .catch((error) => {
        API.DEVELOP && console.log(error);
        toast.error(`No se pudo Iniciar Formalización\n${error}`);
        setLoading(false);
      });
  };

  const renderModal = () => {
    switch (modal) {
      case "email":
        return (
          <ModalReadEmail
            title="Ver e-mail"
            idCorreo={idCorreo}
            cancel={() => setModal("")}
          />
        );

      default:
        return null;
    }
  };

  const showEmail = (idCorreo) => {
    setIdCorreo(idCorreo);
    setModal("email");
  };

  return (
    <S.Wrapper>
      {loading && <Loading />}
      {modal && renderModal()}

      <ModuleAuditoriaCampos
        data={datosAuditoria}
        metadata={METADATA_AUDITORIA_CAMPOS_FICHA}
        access={permissions.TareaAnalisis}
      />

      <ModuleTareaDatosGenerales
        data={dataReserva.datosGenerales}
        metadata={METADATA_FICHA_RESERVA_DATOS_GENERALES}
        button={{
          label: "Iniciar Formalización",
          onClick: iniciarFormalizacion,
          disabled:
            !permissions.FichaReserva?.IniciarFormalizacion ||
            !dataReserva.datosGenerales?.iniciarFormalizacion,
        }}
      />

      <ModuloSolapas
        data={dataReserva}
        lists={lists}
        showEmail={showEmail}
        refresh={() => setTriggerRefresh(true)}
        setLoading={setLoading}
        access={permissions}
      />
    </S.Wrapper>
  );
};
