import React, { useState } from "react";
import PropTypes from "prop-types";
import * as API from "../../api";
import { toast } from "react-toastify";
import * as S from "./styles";
import { RowResultTareas } from "../RowResultTareas";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSort,
  faSortUp,
  faSortDown,
  faUsers,
  faSquare,
  faSquareCheck,
  faFileExcel,
  faUserPlus,
} from "@fortawesome/free-solid-svg-icons";

export const ModuleResultTareas = ({
  title,
  metadata,
  data,
  formData,
  moreResults,
  currentSort,
  sort,
  selectedIds,
  handleChangeSelection,
  selectAll,
  deselectAll,
  requestAsignTarea,
  requestReAbrirTarea,
  requestReasignTarea,
  setLoading,
  access,
}) => {
  const [openedOptionsRowReasignar, setOpenedOptionsRowReasignar] =
    useState(false);
  const [openedOptionsRowAsignar, setOpenedOptionsRowAsignar] = useState(false);
  const infoRecords = data.informacionRegistros;

  const setOptionsRowReasignar = () => {
    setOpenedOptionsRowReasignar(!openedOptionsRowReasignar);
    setOpenedOptionsRowAsignar(false);
  };
  const setOptionsRowAsignar = () => {
    setOpenedOptionsRowAsignar(!openedOptionsRowAsignar);
    setOpenedOptionsRowReasignar(false);
  };

  const textTitle = () => {
    const records = infoRecords
      ? `(${infoRecords.registrosMostrados} de ${infoRecords.totalRegistros})`
      : "";
    return `${title} ${records}`;
  };

  const textFoot = () => {
    if (infoRecords === undefined) return "";
    if (infoRecords?.totalRegistros === 0)
      return "No se han encontrado resultados";
    if (infoRecords?.registrosMostrados === infoRecords?.totalRegistros)
      return "No hay más resultados";
    else return "Mostrar más resultados";
  };

  const isFootButton = () => {
    if (infoRecords === undefined) return false;
    if (infoRecords?.registrosMostrados === infoRecords?.totalRegistros)
      return false;
    return true;
  };

  const exportarTareasExcel = () => {
    setLoading(true);

    API.exportarTareasExcel(formData)
      .then((res) => {
        const url = URL.createObjectURL(new Blob([res.data]));
        const filename = res.headers["content-disposition"]
          .split(";")[1]
          .split("=")[1];
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", filename);
        link.click();
      })
      .catch((error) => {
        toast.error("Error al descargar documento: " + error.msg?.Message);
        API.DEVELOP && console.log(error);
      })
      .finally(() => setLoading(false));
  };

  return (
    <S.Wrapper>
      <S.Header>
        <p>
          {textTitle()}
          <button
            onClick={setOptionsRowReasignar}
            disabled={!access?.Reasignar}
          >
            <FontAwesomeIcon icon={faUsers} size="lg" />
          </button>
          <button onClick={setOptionsRowAsignar} disabled={!access?.Asignar}>
            <FontAwesomeIcon icon={faUserPlus} size="lg" />
          </button>
        </p>
        <button
          onClick={exportarTareasExcel}
          disabled={
            !access?.Exportar || !data.registros || data.registros?.length === 0
          }
        >
          <FontAwesomeIcon icon={faFileExcel} size="lg" />
        </button>
      </S.Header>
      <S.Content>
        <table>
          <thead>
            <>
              {openedOptionsRowReasignar && (
                <S.TrMassiveReAsign>
                  <th colSpan={metadata.length + 2}>
                    <p>Reasignación Masiva</p>
                    <button onClick={selectAll}>
                      <FontAwesomeIcon icon={faSquareCheck} size="lg" />
                    </button>
                    <button onClick={deselectAll}>
                      <FontAwesomeIcon icon={faSquare} size="lg" />
                    </button>
                    <button
                      onClick={() => requestReasignTarea("")}
                      disabled={!selectedIds.length && !access?.Reasignar}
                    >
                      <FontAwesomeIcon icon={faUsers} size="lg" />
                    </button>
                  </th>
                </S.TrMassiveReAsign>
              )}
              {openedOptionsRowAsignar && (
                <S.TrMassiveReAsign>
                  <th colSpan={metadata.length + 2}>
                    <p>Autoasignación Masiva</p>
                    <button onClick={selectAll}>
                      <FontAwesomeIcon icon={faSquareCheck} size="lg" />
                    </button>
                    <button onClick={deselectAll}>
                      <FontAwesomeIcon icon={faSquare} size="lg" />
                    </button>
                    <button
                      onClick={() => requestReasignTarea("", true)}
                      disabled={!selectedIds.length && !access?.Asignar}
                    >
                      <FontAwesomeIcon icon={faUserPlus} size="lg" />
                    </button>
                  </th>
                </S.TrMassiveReAsign>
              )}
            </>
            <S.TrColumnTitles>
              <th />
              {metadata.map((e) => (
                <S.ButtonTh
                  key={e.label}
                  scope="col"
                  active={e.name === currentSort.field}
                >
                  {e.label}
                  <button
                    onClick={() => sort(e.name)}
                    disabled={!infoRecords || infoRecords?.totalRegistros <= 1}
                  >
                    <FontAwesomeIcon
                      icon={
                        e.name !== currentSort.field
                          ? faSort
                          : currentSort.direction === 1
                          ? faSortUp
                          : faSortDown
                      }
                    />
                  </button>
                </S.ButtonTh>
              ))}
              <th />
            </S.TrColumnTitles>
          </thead>
          <tbody>
            {data.registros?.map((e, index) => (
              <RowResultTareas
                key={index}
                metadata={metadata}
                data={e}
                selected={selectedIds.includes(e.tarea.valor)}
                handleChangeSelection={handleChangeSelection}
                requestAsignTarea={requestAsignTarea}
                requestReAbrirTarea={requestReAbrirTarea}
                requestReasignTarea={requestReasignTarea}
              />
            ))}
          </tbody>
          <tfoot>
            <tr>
              <S.TdFoot colSpan={metadata.length + 2}>
                {isFootButton() ? (
                  <button onClick={moreResults}>{textFoot()}</button>
                ) : (
                  <p>{textFoot()}</p>
                )}
              </S.TdFoot>
            </tr>
          </tfoot>
        </table>
      </S.Content>
    </S.Wrapper>
  );
};

ModuleResultTareas.propTypes = {
  title: PropTypes.string,
  metadata: PropTypes.array.isRequired,
  data: PropTypes.object.isRequired,
  formData: PropTypes.object,
  moreResults: PropTypes.func,
  currentSort: PropTypes.object,
  sort: PropTypes.func,
  selectedIds: PropTypes.array,
  handleChangeSelection: PropTypes.func,
  selectAll: PropTypes.func,
  deselectAll: PropTypes.func,
  requestAsignTarea: PropTypes.func,
  requestReAbrirTarea: PropTypes.func,
  requestReasignTarea: PropTypes.func,
  setLoading: PropTypes.func,
  access: PropTypes.object,
};
