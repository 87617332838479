import styled from "styled-components/macro";
import { COLORES } from "../../constants";

export const Wrapper = styled.span`
  svg {
    margin-right: 0.3rem !important;
    color: ${COLORES.UPLOAD_ICON} !important;
    cursor: pointer;
  }

  span {
    color: ${COLORES.DOWNLOAD_FONT};
    cursor: pointer;
    :hover {
      text-decoration: underline;
    }
  }
`;
