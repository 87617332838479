import React, { useState, useEffect } from "react";
import * as S from "./styles";
import * as API from "../../api";
import { useParams, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { ModuleTareaDatosGenerales } from "../../components/ModuleTareaDatosGenerales";
import { ModuleTareaDatosFormalizacion } from "../../components/ModuleTareaDatosFormalizacion";
import { Button } from "../../components/Button";
import { Loading } from "../../components/Loading";
import {
  METADATA_TIPO_FISCALIDAD_DATOS_GENERALES,
  METADATA_TIPO_FISCALIDAD_DATOS_FORMALIZACION,
  METADATA_TIPO_FISCALIDAD_ACTIVOS,
} from "../../constants";
import { ModuleTablaBasica } from "../../components/ModuleTablaBasica";
import { usePermissions } from "../../context/PermissionsContext";

export const TipoFiscalidad = () => {
  const { permissions } = usePermissions();
  const { idTarea } = useParams();
  const navigate = useNavigate();
  const [lists, setLists] = useState({});
  const [datosGenerales, setDatosGenerales] = useState({});
  const [datosFormalizacion, setDatosFormalizacion] = useState({});
  const [activos, setActivos] = useState([]);
  const [loading, setLoading] = useState(true);
  const [values, setValues] = useState({});
  const [options, setOptions] = useState({});
  useEffect(() => {
    let isMounted = true;

    API.abrirTarea({ tarea: idTarea })
      .then((res) => {
        if (isMounted) {
          setDatosGenerales(res.datosGenerales);
          setDatosFormalizacion(res.datosFormalizacion);
          setActivos(res.activos);
        }
      })
      .catch((error) => API.DEVELOP && console.log(error))
      .finally(() => setLoading(false));

    API.obtenerListas("TipoFiscalidad", 0)
      .then((res) => isMounted && setLists(res))
      .catch((error) => API.DEVELOP && console.log(error));

    return () => (isMounted = false);
  }, [idTarea, navigate]);


  useEffect(() => {
    const options = {};
    const values = {};
    Object.entries(lists).forEach(([list, listProps]) => {
      options[list] = convertOptions(listProps.valores);
    });
    const setInitialValue = (e) => {
      switch (e.type) {
        case "list":
          values[e.name] = datosFormalizacion[e.name]?.valor || "";
          break;
        case "text":
          values[e.name] = datosFormalizacion[e.name] || "";
          break;
        default:
          values[e.name] = "";
      }
    };

    METADATA_TIPO_FISCALIDAD_DATOS_FORMALIZACION.forEach((props) => {
      if (props.name === "_group")
        props.fields.forEach((props) => setInitialValue(props));
      else setInitialValue(props);
    });

    setValues(values);
    setOptions(options);
  }, [lists, datosFormalizacion]);

  const convertOptions = (options) =>
    options.map((opt) => ({
      value: opt.valor,
      label: opt.etiqueta,
    }));

  const completarTipoFiscalidad = () => {
    setLoading(true);
    API.completarTipoFiscalidad({
      IdTarea: idTarea,
      RegimenFiscal: values["regimenFiscal"],
      ObservacionesFiscalidad: values["observacionesFiscalidad"],
    })
      .then((res) => {
        if (res.resultadoPeticion === 1) {
          toast.success("Tarea Completada");
          navigate("/");
        } else toast.error(res.mensajeUsuario);
      })
      .catch((error) => API.DEVELOP && console.log(error))
      .finally(() => setLoading(false));
  };

  return (
    <S.Wrapper>
      {loading && <Loading />}

      <ModuleTareaDatosGenerales
        data={datosGenerales}
        metadata={METADATA_TIPO_FISCALIDAD_DATOS_GENERALES}
      />

      <ModuleTareaDatosFormalizacion
        data={datosFormalizacion}
        metadata={METADATA_TIPO_FISCALIDAD_DATOS_FORMALIZACION}
        lists={lists}
        columns={4}
        values={values}
        setValues={setValues}
        options={options}
        setOptions={setOptions}
        access={permissions?.TipoFiscalidad}
      />

      <ModuleTablaBasica
        data={activos}
        metadata={METADATA_TIPO_FISCALIDAD_ACTIVOS}
        label="Activos"
      />

      <S.Buttons>
        <a
          href={`/FichaReserva/${datosGenerales.idReserva}`}
          target="_blank"
          rel="noreferrer"
        >
          <Button label="Ver Ficha" disabled={!datosGenerales.idReserva} />
        </a>
        <Button
          label="Completar"
          onClick={completarTipoFiscalidad}
          disabled={!permissions.TipoFiscalidad?.Completar}
        />
      </S.Buttons>
    </S.Wrapper>
  );
};
