import styled from "styled-components/macro";
import { COLORES } from "../../constants";

export const Wrapper = styled.div`
  width: 100%;
  background-color: ${COLORES.DEFAULT_MODULE_BACKGROUND};
  margin-bottom: 2rem;
  border-radius: 0.25rem;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 2rem;
  color: ${COLORES.DEFAULT_MODULE_HEADER_FONT};
  background-color: ${COLORES.DEFAULT_MODULE_HEADER_BACKGROUND};
  font-weight: bold;
  border-radius: 0.25rem 0.25rem 0 0;
  p {
    padding-left: 1rem;
  }
  svg {
    padding-right: 1rem;
    cursor: pointer;
  }
`;

export const Content = styled.form`
  table {
    width: 100%;
    table-layout: fixed;
    border-collapse: collapse;

    tbody {
      tr {
        height: 1.5rem;
        :hover {
          background-color: ${COLORES.ROW_HOVER};
        }

        td {
          padding-left: 0.5rem;
          text-overflow: clip;
          white-space: nowrap;
          overflow: hidden;
        }
      }
    }
  }
`;

export const TrColumnTitles = styled.tr`
  height: 1.5rem;
  font-weight: bold;
  background-color: ${COLORES.HEADER_ROW_BACKGROUND};

  th {
    text-align: left;
    padding-left: 0.5rem;
    text-overflow: clip;
    white-space: nowrap;
    overflow: hidden;
  }
`;

export const TdEmail = styled.td`
  color: ${COLORES.BUTTON_BACKGROUND};
  svg {
    padding: 0.2rem;
    cursor: pointer;
  }
`;

export const TdEye = styled.td`
  color: black;
  svg {
    padding: 0.2rem;
    cursor: pointer;
  }
`;
