import React from "react";
import PropTypes from "prop-types";
import * as S from "./styles";
import { InputTextArea } from "../InputTextArea";
export const ModuleObservaciones = ({
  metadata,
  access,
  values,
  setValues,
}) => {

  const handleChange = (prop, value) => {
    const tmpValues = { ...values };
    tmpValues[prop] = value;
    setValues(tmpValues);
  };

  return (
    <S.Wrapper>
      <S.Header>
        <span>{metadata.label}</span>
      </S.Header>
      <S.Content >
        <InputTextArea
          field={metadata}
          value={values[metadata.name] || ""}
          handleChange={handleChange}
          colSpan={metadata.colSpan}
          disabled={!access?.Editar || metadata.disabled}
          hideLabel={true}
        />

      </S.Content>
    </S.Wrapper>
  );
};


ModuleObservaciones.propTypes = {
  metadata: PropTypes.object,
  access: PropTypes.object,
  values: PropTypes.object.isRequired,
  setValues: PropTypes.func,
};
