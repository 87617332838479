import React, { useState, useEffect } from "react";
import * as S from "./styles";
import * as API from "../../api";
import { useParams, useNavigate, Link } from "react-router-dom";
import { toast } from "react-toastify";
import { Button } from "../../components/Button";
import { Loading } from "../../components/Loading";
import { METADATA_DATOS_USUARIO } from "../../constants";
import { ModuleDatosUsuario } from "../../components/ModuleDatosUsuario";

export const EdicionCreacionUsuarios = () => {
  const { idUsuario } = useParams();
  const navigate = useNavigate();
  const [lists, setLists] = useState({});
  const [datosUsuario, setDatosUsuario] = useState({});
  const [loading, setLoading] = useState(true);
  const [values, setValues] = useState({});
  const [options, setOptions] = useState({});
  useEffect(() => {
    let isMounted = true;
    const idObtenerUsuario =
      idUsuario === "Nuevo"
        ? "00000000-0000-0000-0000-000000000000"
        : idUsuario;
    API.obtenerUsuario({ idUsuario: idObtenerUsuario })
      .then((res) => {
        if (isMounted) {
          setDatosUsuario(res);
        }
      })
      .catch((error) => API.DEVELOP && console.log(error))
      .finally(() => setLoading(false));

    API.obtenerListas("EdicionCreacionUsuarios", 0)
      .then((res) => isMounted && setLists(res))
      .catch((error) => API.DEVELOP && console.log(error));

    return () => (isMounted = false);
  }, [idUsuario]);


  useEffect(() => {
    const options = {};
    const values = {};
    Object.entries(lists).forEach(([list, listProps]) => {
      options[list] = convertOptions(listProps.valores);
    });
    const setInitialValue = (e) => {
      switch (e.type) {
        case "list":
          values[e.name] = datosUsuario[e.name]?.valor || "";
          break;
        case "multiSelectList":
          const initialDataMultiSelect = [];
          datosUsuario[e.name]?.forEach((element) =>
            initialDataMultiSelect.push(element.valor)
          );
          values[e.name] = initialDataMultiSelect || [];
          break;
        case "text":
          values[e.name] = datosUsuario[e.name] || "";
          break;
        case "checkbox":
          values[e.name] = datosUsuario[e.name] || false;
          break;
        case "readOnly":
          values[e.name] = datosUsuario[e.name] || "";
          break;
        default:
          values[e.name] = "";
      }
    };

    METADATA_DATOS_USUARIO.forEach((props) => {
      if (props.name === "_group")
        props.fields.forEach((props) => setInitialValue(props));
      else setInitialValue(props);
    });

    setValues(values);
    setOptions(options);
  }, [lists, datosUsuario]);

  const convertOptions = (options) =>
    options.map((opt) => ({
      value: opt.valor,
      label: opt.etiqueta,
    }));

  const crearActualizarUsuario = () => {
    setLoading(true);
    const idObtenerUsuario =
      idUsuario === "Nuevo"
        ? "00000000-0000-0000-0000-000000000000"
        : idUsuario;
    API.crearActualizarUsuario({ idUsuario: idObtenerUsuario, ...values })
      .then((res) => {
        if (res.resultadoPeticion === 1 && idUsuario !== "Nuevo") {
          toast.success("Usuario Guardado");
        } else if (res.resultadoPeticion === 1 && idUsuario === "Nuevo") {
          toast.success("Usuario Creado");
          navigate("/BuscadorUsuarios");
        } else toast.error(res.mensajeUsuario);
      })
      .catch((error) => API.DEVELOP && console.log(error))
      .finally(() => setLoading(false));
  };

  return (
    <S.Wrapper>
      {loading && <Loading />}

      <ModuleDatosUsuario
        data={datosUsuario}
        metadata={METADATA_DATOS_USUARIO}
        lists={lists}
        columns={2}
        values={values}
        setValues={setValues}
        options={options}
        setOptions={setOptions}
        idUsuario={idUsuario}
      />

      <S.Buttons>
        <Button label="Guardar" onClick={crearActualizarUsuario} />
        <Link to={"/BuscadorUsuarios"}>
          <Button label="Volver" />
        </Link>
      </S.Buttons>
    </S.Wrapper>
  );
};
