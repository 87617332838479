import React from "react";
import PropTypes from "prop-types";
import * as S from "./styles";

export const InputTextArea = ({
  field,
  rows,
  value,
  placeholder,
  handleChange,
  colSpan,
  disabled,
  hideLabel = false,
}) => {
  const Label = () => {
    if(!hideLabel) return  <p>{field.label}</p>;
  };
  return (
    <S.Wrapper colSpan={colSpan}>
      <Label />
      <textarea
        name={field.name}
        rows={rows || 3}
        value={value}
        placeholder={placeholder}
        onChange={(e) => handleChange(field.name, e.target.value)}
        disabled={disabled}
      />
    </S.Wrapper>
  );
};

InputTextArea.propTypes = {
  field: PropTypes.object.isRequired,
  rows: PropTypes.number,
  value: PropTypes.string,
  placeholder: PropTypes.string,
  handleChange: PropTypes.func,
  colSpan: PropTypes.string,
  disabled: PropTypes.bool,
  hideLabel: PropTypes.bool,
};
