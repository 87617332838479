import React from "react";
import PropTypes from "prop-types";
import * as S from "./styles";

export const InputCheckbox = ({ field, value, handleChange, disabled }) => {
  return (
    <S.Wrapper>
      <p>{field.label}</p>
      <input
        type="checkbox"
        name={field.name}
        value={value}
        checked={value}
        onChange={(e) => handleChange(field.name, e.target.checked)}
        disabled={disabled}
      />
    </S.Wrapper>
  );
};

InputCheckbox.propTypes = {
  field: PropTypes.object.isRequired,
  value: PropTypes.bool,
  handleChange: PropTypes.func,
  disabled: PropTypes.bool,
};
