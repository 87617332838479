import React from "react";
import PropTypes from "prop-types";
import * as S from "./styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faUser } from "@fortawesome/free-solid-svg-icons";

export const RowResultAsignacionGestoria = ({
  metadata,
  data,
  action,
  icon,
  selected,
  handleChangeSelection,
  requestReasignReserva,
}) => {
  return (
    <S.Tr>
      <td>
        {handleChangeSelection && (
          <input
            type="checkbox"
            id={data.reserva?.valor || data.id}
            checked={selected}
            onChange={handleChangeSelection}
          />
        )}
      </td>
      {metadata.map((e) => {
        return <td key={e.name}>{data[e.name]?.etiqueta || data[e.name]}</td>;
      })}

      {icon === faEdit ? (
        <S.TdButtons>
          <a
            href={
              data.reserva ? `/AsignacionGestoria/${data.reserva.valor}` : null
            }
            target="_blank"
            rel="noreferrer"
          >
            <button>
              <FontAwesomeIcon icon={icon} />
            </button>
          </a>
          <button onClick={() => requestReasignReserva(data.reserva)}>
            <FontAwesomeIcon icon={faUser} size="lg" />
          </button>
        </S.TdButtons>
      ) : icon && (
        <S.TdButtons>
          <button>
            <FontAwesomeIcon icon={icon} onClick={() => action(data)} />
          </button>
        </S.TdButtons>
      )}
    </S.Tr>
  );
};

RowResultAsignacionGestoria.propTypes = {
  metadata: PropTypes.array.isRequired,
  data: PropTypes.object.isRequired,
};
