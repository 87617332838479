import React, { useState } from "react";
import PropTypes from "prop-types";
import * as S from "./styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSort,
  faSortUp,
  faSortDown,
  faEdit,
  faSquareCheck,
  faSquare,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import { RowResultAsignacionGestoria } from "../RowResultAsignacionGestoria";

export const ModuleResultBuscadorGestoria = ({
  title,
  metadata,
  data,
  moreResults,
  currentSort,
  sort,
  selectAll,
  deselectAll,
  selectedIds,
  requestReasign,
  handleChangeSelection,
}) => {
  const infoRecords = data.informacionRegistros;
  const [openedOptionsRowAsignar, setOpenedOptionsRowAsignar] = useState(false);

  const setOptionsRowAsignar = () => {
    setOpenedOptionsRowAsignar(!openedOptionsRowAsignar);
  };

  const textTitle = () => {
    const records = infoRecords
      ? `(${infoRecords.registrosMostrados} de ${infoRecords.totalRegistros})`
      : "";
    return `${title} ${records}`;
  };

  const textFoot = () => {
    if (infoRecords === undefined) return "";
    if (infoRecords?.totalRegistros === 0)
      return "No se han encontrado resultados";
    if (infoRecords?.registrosMostrados === infoRecords?.totalRegistros)
      return "No hay más resultados";
    else return "Mostrar más resultados";
  };

  const isFootButton = () => {
    if (infoRecords === undefined) return false;
    if (infoRecords?.registrosMostrados === infoRecords?.totalRegistros)
      return false;
    return true;
  };

  return (
    <S.Wrapper>
      <S.Header>
        <p>
          {textTitle()}
          <button onClick={setOptionsRowAsignar}>
            <FontAwesomeIcon icon={faUser} size="lg" />
          </button>
        </p>
      </S.Header>
      <S.Content>
        <table>
          <thead>
            {openedOptionsRowAsignar && (
              <S.TrMassiveReAsign>
                <th colSpan={metadata.length + 2}>
                  <p>Asignación masiva</p>
                  <button onClick={selectAll}>
                    <FontAwesomeIcon icon={faSquareCheck} size="lg" />
                  </button>
                  <button onClick={deselectAll}>
                    <FontAwesomeIcon icon={faSquare} size="lg" />
                  </button>
                  <button
                    onClick={()=>requestReasign()}
                    disabled={!selectedIds?.length}
                  >
                    <FontAwesomeIcon icon={faUser} size="lg" />
                  </button>
                </th>
              </S.TrMassiveReAsign>
            )}
            <S.TrColumnTitlesReserva>
              <th />
              {metadata.map((e) => (
                <S.ButtonTh
                  key={e.label}
                  scope="col"
                  active={e.name === currentSort.field}
                >
                  {e.label}
                  <button
                    onClick={() => sort(e.name)}
                    disabled={!infoRecords || infoRecords?.totalRegistros <= 1}
                  >
                    <FontAwesomeIcon
                      icon={
                        e.name !== currentSort.field
                          ? faSort
                          : currentSort.direction === 1
                          ? faSortUp
                          : faSortDown
                      }
                    />
                  </button>
                </S.ButtonTh>
              ))}
              <th />
            </S.TrColumnTitlesReserva>
          </thead>
          <tbody>
            {data.registros?.map((e, index) => (
              <RowResultAsignacionGestoria
                key={index}
                metadata={metadata}
                data={e}
                icon={faEdit}
                handleChangeSelection={handleChangeSelection}
                selected={selectedIds?.includes(e.reserva.valor)}
                requestReasignReserva={requestReasign}
              />
            ))}
          </tbody>
          <tfoot>
            <tr>
              <S.TdFoot colSpan={metadata.length + 2}>
                {isFootButton() ? (
                  <button onClick={moreResults}>{textFoot()}</button>
                ) : (
                  <p>{textFoot()}</p>
                )}
              </S.TdFoot>
            </tr>
          </tfoot>
        </table>
      </S.Content>
    </S.Wrapper>
  );
};

ModuleResultBuscadorGestoria.propTypes = {
  currentSort: PropTypes.shape({
    direction: PropTypes.number,
    field: PropTypes.any,
  }).isRequired,
  data: PropTypes.object.isRequired,
  metadata: PropTypes.array.isRequired,
  moreResults: PropTypes.any.isRequired,
  sort: PropTypes.func.isRequired,
  title: PropTypes.any.isRequired,
};
