import React from "react";
import PropTypes from "prop-types";
import * as S from "./styles";
import Select from "react-select";
import { COLORES } from "../../constants";

export const InputListMultiple = ({
  field,
  currentOptions,
  options,
  handleChange,
  disabled
}) => {
  return (
    <S.Wrapper>
      <p>{field.label}</p>
      <Select
        name={field.name}
        isMulti
        value={options.filter((opt) => currentOptions.includes(opt.value))}
        options={options}
        onChange={(value) =>
          handleChange(
            field,
            value.map((option) => option.value)
          )
        }
        styles={customStyles}
        isDisabled={disabled}
      />
    </S.Wrapper>
  );
};

const customStyles = {
  control: (provided, state) => ({
    ...provided,
    minHeight: "1rem",
    boxShadow: "none",
    borderColor: state.isFocused
      ? COLORES.MULTISELECT_LIST_ACTIVE
      : COLORES.MULTISELECT_LIGHT_BORDER,
    "&:hover": {
      borderColor: state.isFocused
        ? COLORES.MULTISELECT_LIST_ACTIVE
        : COLORES.MULTISELECT_BORDER,
    },
    backgroundColor: state.isDisabled
    ? COLORES.DEFAULT_MODULE_BACKGROUND
    : COLORES.MULTISELECT_BACKGROUND,
        borderRadius: "2px",
  }),
  menu: (provided, state) => ({
    ...provided,
    margin: 0,
  }),
  menuList: (provided, state) => ({
    ...provided,
    padding: 0,
    borderRadius: "5px",
  }),
  multiValue: (styles) => ({
    ...styles,
    margin: "1px",
  }),
  multiValueLabel: (styles, { data }) => ({
    ...styles,
    fontSize: "0.5rem",
  }),
  valueContainer: (styles) => ({
    ...styles,
    padding: "0 .2rem",
  }),
  input: (styles) => ({
    ...styles,
    margin: 0,
  }),
  indicatorSeparator: (provided, state) => ({}),
  dropdownIndicator: (provided, state) => ({
    ...provided,
    padding: 0,
  }),
  clearIndicator: (provided, state) => ({
    ...provided,
    padding: 0,
  }),
  option: (provided, state) => ({
    ...provided,
    fontSize: "0.65rem",
    padding: "6px 12px",
    borderBottom: `1px solid ${COLORES.MULTISELECT_LIGHT_BORDER}`,
    backgroundColor: state.isSelected
      ? COLORES.MULTISELECT_LIST_ACTIVE
      : "white",
    "&:hover": {
      backgroundColor: state.isSelected
        ? COLORES.MULTISELECT_LIST_ACTIVE
        : COLORES.MULTISELECT_OPTION_HOVER,
    },
    overflow: "hidden",
  }),
};

InputListMultiple.propTypes = {
  field: PropTypes.object,
  currentOptions: PropTypes.array,
  options: PropTypes.array,
  handleChange: PropTypes.func,
};
