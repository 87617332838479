import React, { useEffect, useState } from "react";
import * as S from "./styles";
import * as API from "../../api";
import { toast } from "react-toastify";
import { ModuleSearch } from "../../components/ModuleSearch";
import { ModuleResultReservas } from "../../components/ModuleResultReservas";
import { Loading } from "../../components/Loading";
import { Modal } from "../../components/Modal";
import {
  METADATA_SEARCH_RESERVAS,
  METADATA_RESULT_RESERVAS_RESERVA,
  METADATA_RESULT_RESERVAS_ACTIVO,
  RESERVAS_BY_PAGE,
} from "../../constants";
import { usePermissions } from "../../context/PermissionsContext";

export const BuscadorReservas = () => {
  const { permissions } = usePermissions();
  const [lists, setLists] = useState({});
  const [result, setResult] = useState({});
  const [formData, setFormData] = useState({});
  const [page, setPage] = useState(1);
  const [currentSort, setCurrentSort] = useState({});
  const [visibleModal, setVisibleModal] = useState("");
  const [listFilters, setListFilters] = useState([]);
  const [currentFilter, setCurrentFilter] = useState("");
  const [dataFilter, setDataFilter] = useState("");
  const [loading, setLoading] = useState(true);
  const [metadataResultado, setMetadataResultado] = useState(
    METADATA_RESULT_RESERVAS_RESERVA
  );
  const [isSearchReserva, setIsSearchReserva] = useState(true);
  useEffect(() => {
    let isMounted = true;
    API.obtenerFiltros({ modulo: "BuscadorReservas" })
      .then((res) => isMounted && setListFilters(res))
      .catch((error) => API.DEVELOP && console.log(error));

    API.obtenerListas("BuscadorReservas", 0)
      .then((res) => isMounted && setLists(res))
      .catch((error) => API.DEVELOP && console.log(error))
      .finally(() => setLoading(false));

    return () => (isMounted = false);
  }, []);

  const searchTareas = (data = structuredClone(formData)) => {
    setLoading(true);
    setFormData(data);
    if (data.tipoBusqueda === "1") {
      setMetadataResultado(METADATA_RESULT_RESERVAS_ACTIVO);
      setIsSearchReserva(false);
    } else {
      setMetadataResultado(METADATA_RESULT_RESERVAS_RESERVA);
      setIsSearchReserva(true);
    }
    const ordenTmp = data.orden;
    data.orden = order(currentSort);
    data.paginacion = pagination(1);
    API.buscadordeReservas(data)
      .then((res) => {
        setResult(res || {});
        setPage(2);
      })
      .catch((error) => API.DEVELOP && console.log(error))
      .finally(() => {
        setLoading(false);
        data.orden = ordenTmp;
      });
  };

  const moreTareas = () => {
    setLoading(true);
    const data = structuredClone(formData);
    data.orden = order();
    data.paginacion = pagination(page);
    API.buscadordeReservas(data)
      .then((res) => {
        setResult({
          informacionRegistros: res.informacionRegistros,
          registros: [...result.registros, ...res.registros],
        });
        setPage(page + 1);
        setLoading(false);
      })
      .catch((error) => API.DEVELOP && console.log(error));
  };

  const sort = (field) => {
    setCurrentFilter("");
    setLoading(true);
    let direction;
    if (field !== currentSort.field) direction = 1;
    else direction = currentSort.direction === 1 ? 2 : 1;
    const newSort = { field, direction };
    setCurrentSort(newSort);

    const data = structuredClone(formData);
    data.orden = order(newSort);
    data.paginacion = pagination(1);
    API.buscadordeReservas(data)
      .then((res) => {
        setResult(res || {});
        setPage(2);
        setLoading(false);
      })
      .catch((error) => API.DEVELOP && console.log(error));
  };

  const pagination = (page) => {
    return {
      pagina: page,
      cantidadRegistros: RESERVAS_BY_PAGE,
    };
  };

  const order = (data = currentSort) => {
    return {
      campoOrden: data.field,
      direccion: data.direction,
    };
  };

  const requestSaveFilter = (data) => {
    data.orden = order(currentSort);
    setDataFilter(data);
    setVisibleModal("saveFilter");
  };

  const saveFilter = (name) => {
    setLoading(true);
    const data = {};
    data.modulo = "BuscadorReservas";
    data.nombre = name;
    data.filtro = dataFilter;

    API.guardarFiltro(data)
      .then((res) => {
        if (res.resultadoPeticion === 1) {
          const tmpListFilters = [...listFilters];
          data.id = res.idFiltro;
          tmpListFilters.push(data);
          setListFilters(tmpListFilters);
          setCurrentFilter(res.idFiltro);
          toast.success("Filtro Guardado");
        } else
          toast.error("No se pudo guardar el filtro\n" + res.mensajeUsuario);
      })
      .catch((error) => {
        toast.error("No se pudo guardar el filtro");
        API.DEVELOP && console.log(error);
      })
      .finally(() => {
        setLoading(false);
        setVisibleModal("");
      });
  };

  const requestDeleteFilter = (data) => {
    setVisibleModal("deleteFilter");
  };

  const deleteFilter = () => {
    setLoading(true);
    const data = {};
    data.idFiltro = currentFilter;

    API.eliminarFiltro(data)
      .then((res) => {
        if (res.resultadoPeticion === 1) {
          const tmpListFilters = [...listFilters].filter(
            (filter) => filter.id !== currentFilter
          );
          setListFilters(tmpListFilters);
          toast.success("Filtro Eliminado");
        } else
          toast.error("No se pudo eliminar el filtro\n" + res.mensajeUsuario);
      })
      .catch((error) => {
        toast.error("No se pudo eliminar el filtro");
        API.DEVELOP && console.log(error);
      })
      .finally(() => {
        setLoading(false);
        setVisibleModal("");
        setCurrentFilter("");
      });
  };

  return (
    <S.Wrapper>
      {loading && <Loading />}

      {visibleModal === "saveFilter" && (
        <Modal
          type="saveFilter"
          title="Guardar Filtro"
          message="Indique el nombre del nuevo filtro"
          labelConfirm="Guardar"
          confirm={saveFilter}
          cancel={() => setVisibleModal("")}
        />
      )}
      {visibleModal === "deleteFilter" && (
        <Modal
          type="deleteFilter"
          title="Eliminar Filtro"
          message="¿Seguro que desea eliminar el filtro seleccionado?"
          labelConfirm="Eliminar"
          confirm={deleteFilter}
          cancel={() => setVisibleModal("")}
        />
      )}

      <ModuleSearch
        title="Buscador Reservas: Filtro"
        metadata={METADATA_SEARCH_RESERVAS}
        lists={lists}
        submit={searchTareas}
        listFilters={listFilters}
        currentFilter={currentFilter}
        setCurrentFilter={setCurrentFilter}
        requestSaveFilter={requestSaveFilter}
        requestDeleteFilter={requestDeleteFilter}
        setCurrentSort={setCurrentSort}
      />

      <ModuleResultReservas
        title="Resultados"
        metadata={metadataResultado}
        data={result}
        formData={formData}
        moreResults={moreTareas}
        currentSort={currentSort}
        sort={sort}
        isSearchReserva={isSearchReserva}
        setLoading={setLoading}
        access={permissions.BuscadorReservas}
      />
    </S.Wrapper>
  );
};
