import React from "react";
import PropTypes from "prop-types";
import * as S from "./styles";
import * as API from "../../api";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaperclip } from "@fortawesome/free-solid-svg-icons";

export const UploadDownload = ({
  id,
  nombreDocumento,
  uploadEnabled = true,
  uploadService,
  uploadSuccess,
  setLoading,
}) => {
  const uploadDocument = (e) => {
    setLoading(true);
    let filename;
    let base64File;
    let extFile;
    if (e.target.files.length === 0) setLoading(false);
    Array.from(e.target.files).forEach((file) => {
      var reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = function () {
        base64File = reader.result.replace(/^data:.+;base64,/, "");
        filename = file.name;
        const nameFileArray = filename.split(".");
        extFile = nameFileArray[nameFileArray.length - 1];

        const data = {};
        data.contenidobase64 = base64File;
        data.tituloDocumento = filename;
        data.tipoContenido = extFile;
        data.idDocumento = id;
        uploadService(data)
          .then((res) => {
            if (typeof res === "object") {
              toast.success("Documento actualizado");
              uploadSuccess();
            } else toast.error("Error al actualizar documento");
          })
          .catch((error) => {
            toast.error("Error al actualizar documento\n" + error.msg?.Message);
            API.DEVELOP && console.log(error);
          })
          .finally(() => setLoading(false));
      };
    });
  };

  const downloadDocument = () => {
    setLoading(true);
    const data = { id, nombreDocumento };

    API.descargarDocumento(data)
      .then((res) => {
        const url = URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", nombreDocumento);
        link.click();
      })
      .catch((error) => {
        toast.error("Error al descargar documento: " + error.msg?.Message);
        API.DEVELOP && console.log(error);
      })
      .finally(() => setLoading(false));
  };

  return (
    <S.Wrapper>
      {uploadEnabled && (
        <>
          <input id={id} type="file" onChange={uploadDocument} hidden />
          <label htmlFor={id}>
            <FontAwesomeIcon icon={faPaperclip} />
          </label>
        </>
      )}
      <span onClick={downloadDocument}>{nombreDocumento}</span>
    </S.Wrapper>
  );
};

UploadDownload.propTypes = {
  id: PropTypes.string,
  nombreDocumento: PropTypes.string,
  uploadEnabled: PropTypes.bool,
  uploadService: PropTypes.func,
  uploadSuccess: PropTypes.func,
  setLoading: PropTypes.func,
};
