import React, { useEffect, useState } from "react";
import * as S from "./styles";
import * as API from "../../api";
import { toast } from "react-toastify";
import { ModuleSearch } from "../../components/ModuleSearch";
import { Loading } from "../../components/Loading";
import { Modal } from "../../components/Modal";
import {
  METADATA_SEARCH_GESTORIAPROVINCIA,
  METADATA_RESULT_GESTORIAPROVINCIA,
} from "../../constants";

import { ModuleResultBuscadorGestoriaProvincia } from "../../components/ModuleResultBuscadorGestoriaProvincia";

export const BuscadorGestoriaProvincia = () => {
  const [lists, setLists] = useState({});
  const [result, setResult] = useState({});
  const [formData, setFormData] = useState({});
  const [currentFilter, setCurrentFilter] = useState("");
  const [loading, setLoading] = useState(true);
  const [selectedIds, setSelectedIds] = useState([]);
  const [visibleModal, setVisibleModal] = useState("");

  useEffect(() => {
    let isMounted = true;
    API.obtenerListas("BuscadorGestoriaProvincia", 0)
      .then((res) => isMounted && setLists(res))
      .catch((error) => API.DEVELOP && console.log(error))
      .finally(() => setLoading(false));

    return () => (isMounted = false);
  }, []);

  const searchGestoriaProvincia = (data = formData) => {
    setLoading(true);
    setFormData(data);
    API.obtenerGestoriaProvincia(data)
      .then((res) => {
        setResult(res || {});
        setSelectedIds([]);
      })
      .catch((error) => API.DEVELOP && console.log(error))
      .finally(() => setLoading(false));
  };

  const handleChangeSelection = (e) => {
    const { id, checked } = e.target;
    const tmpSelectedIds = [...selectedIds];
    if (checked) {
      tmpSelectedIds.push(id);
      setSelectedIds(tmpSelectedIds);
    } else {
      setSelectedIds(tmpSelectedIds.filter((e) => e !== id));
    }
  };

  const requestReasignGestoriaProvincia = () => {
    setVisibleModal("massiveReasignGestoriaProvincia");
  };

  const massiveAssignReservaGestoria = (valores) => {
    setLoading(true);
    let ok = true;
    if (valores?.length < 1) {
      ok = false;
      toast.error(`No se ha seleccionado la gestoria`);
    }

    if (ok) {
      const data = {
        Gestoria: valores,
        GestoriaTareas: selectedIds,
      };
      API.modificarMultipleGestoriaTarea(data)
        .then((res) => {
          if (res.resultadoPeticion === 1) {
            toast.success("Se han modificado los registros");
            setLoading(false);
          } else {
            toast.error(
              `No se ha podido añadir el registro \n${res.mensajeUsuario}`
            );
          }
        })
        .catch((error) => API.DEVELOP && console.log(error))
        .finally(() => {
          searchGestoriaProvincia();
          setVisibleModal("");
          deselectAll();
        });
    }
  };

  const deselectAll = () => {
    setSelectedIds([]);
  };

  const selectAll = () => {

    setSelectedIds(
      result.registros?.gesProResCartera.map((d) =>
        d.gesProResComunidad.map((c) => 
          c.gesProResRegistros.map(r=>r.id))
      ).flat(2)
    );
  };

  return (
    <S.Wrapper>
      {loading && <Loading />}
      {visibleModal === "massiveReasignGestoriaProvincia" && (
        <Modal
          values={lists.Gestoria?.valores || []}
          type="massiveReasignGestoriaProvincia"
          title="Asignar los registros a gestoria"
          message={`¿A qué gestoria quiere asignar ${selectedIds.length} registros?`}
          labelConfirm="Aceptar"
          confirm={massiveAssignReservaGestoria}
          cancel={() => setVisibleModal("")}
        />
      )}
      <ModuleSearch
        title="Buscador Gestorias Provincia: Filtro"
        metadata={METADATA_SEARCH_GESTORIAPROVINCIA}
        lists={lists}
        submit={searchGestoriaProvincia}
        currentFilter={currentFilter}
        setCurrentFilter={setCurrentFilter}
      />

      <ModuleResultBuscadorGestoriaProvincia
        title="Resultados"
        metadata={METADATA_RESULT_GESTORIAPROVINCIA}
        data={result}
        setLoading={setLoading}
        selectedIds={selectedIds}
        handleChangeSelection={handleChangeSelection}
        selectAll={selectAll}
        deselectAll={deselectAll}
        requestReasign={requestReasignGestoriaProvincia}
      />
    </S.Wrapper>
  );
};
