import React, { useState, useEffect } from "react";
import * as S from "./styles";
import * as API from "../../api";
import { useParams, useNavigate, Link } from "react-router-dom";
import { toast } from "react-toastify";
import { Loading } from "../../components/Loading";
import { InputText } from "../../components/InputText";
import { InputTextArea } from "../../components/InputTextArea";
import { Button } from "../../components/Button";
import { METADATA_DATOS_ROL } from "../../constants";

export const EdicionCreacionRoles = () => {
  const { idRol } = useParams();
  const navigate = useNavigate();
  const [datosRol, setDatosRol] = useState({});
  const [datosModulos, setDatosModulos] = useState([]);
  const [values, setValues] = useState({});
  const [tiposPermisos, setTiposPermisos] = useState([]);
  const [permisos, setPermisos] = useState([]);
  const [loading, setLoading] = useState(true);
  const UUID_EMPTY = "00000000-0000-0000-0000-000000000000";
  useEffect(() => {
    let isMounted = true;
    API.obtenerRol({ idRol })
      .then((res) => {
        if (isMounted) {
          setDatosModulos(res.datosModulos);
          idRol !== UUID_EMPTY && setDatosRol(res.datosRol);
        }
      })
      .catch((error) => API.DEVELOP && console.log(error))
      .finally(() => setLoading(false));

    API.obtenerTiposPermisos()
      .then((res) => isMounted && setTiposPermisos(res))
      .catch((error) => API.DEVELOP && console.log(error));

    return () => (isMounted = false);
  }, [idRol]);

  useEffect(() => {
    const values = {};
    METADATA_DATOS_ROL.forEach(
      (props) => (values[props.name] = datosRol[props.name] || "")
    );
    setValues(values);
  }, [datosRol]);

  const handleChange = (prop, value) => {
    const tmpValues = { ...values };
    tmpValues[prop] = value;
    setValues(tmpValues);
  };

  const handleChangePermiso = (e) => {
    const { id, checked } = e.target;
    let tmpPermisos = [];

    if (permisos.some((permiso) => permiso.idPermiso === id))
      tmpPermisos = permisos.map((permiso) => {
        if (permiso.idPermiso === id) permiso.concedido = checked;
        return permiso;
      });
    else
      tmpPermisos = [
        ...permisos,
        {
          idPermiso: id,
          concedido: checked,
        },
      ];
    setPermisos(tmpPermisos);
  };

  const crearActualizarRol = () => {
    setLoading(true);

    const data = { ...values };
    data.idRol = idRol;
    data.permisos = permisos;

    API.crearActualizarRol(data)
      .then((res) => {
        if (res.resultadoPeticion === 1) {
          if (idRol === UUID_EMPTY) {
            toast.success("Rol Creado");
            navigate("/BuscadorRoles");
          } else toast.success("Rol Guardado");
        } else Promise.reject(res.mensajeUsuario);
      })
      .catch((error) => {
        const action = idRol === UUID_EMPTY ? "crear" : "guardar";
        toast.error(`No se pudo ${action} el rol\n${error}`);
        API.DEVELOP && console.log(error);
      })
      .finally(() => setLoading(false));
  };

  return (
    <S.Wrapper>
      {loading && <Loading />}

      <S.ModuleWrapper>
        <S.Header>
          <p>Datos Rol</p>
        </S.Header>
        <S.Content columns={2}>
          {METADATA_DATOS_ROL.map((field) => {
            switch (field.type) {
              case "textarea":
                return (
                  <InputTextArea
                    key={field.name}
                    field={field}
                    rows={field.rows}
                    value={values[field.name] || ""}
                    handleChange={handleChange}
                    colSpan={field.colSpan}
                    placeholder="Introduzca la descripción del rol"
                  />
                );
              case "text":
              default:
                return (
                  <InputText
                    key={field.name}
                    field={field}
                    value={values[field.name] || ""}
                    handleChange={handleChange}
                    placeholder="Introduzca el nombre del rol"
                  />
                );
            }
          })}
        </S.Content>
        <S.Permisos>
          <thead>
            <tr>
              <th>Módulo</th>
              {tiposPermisos.map((tipo) => (
                <th key={tipo} className="header">
                  {tipo}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {Object.entries(datosModulos).map(([moduleName, permisos]) => (
              <tr key={moduleName}>
                <td>{moduleName}</td>

                {tiposPermisos.map((tipo) => (
                  <td key={tipo}>
                    {permisos[tipo] ? (
                      <input
                        type="checkbox"
                        id={permisos[tipo].idPermiso}
                        defaultChecked={permisos[tipo].concedido}
                        onChange={handleChangePermiso}
                      />
                    ) : null}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </S.Permisos>
      </S.ModuleWrapper>

      <S.Buttons>
        <Button
          label="Guardar"
          onClick={crearActualizarRol}
          disabled={!values.nombre || !values.descripcion}
        />
        <Link to={"/BuscadorRoles"}>
          <Button label="Volver" />
        </Link>
      </S.Buttons>
    </S.Wrapper>
  );
};
