import styled from "styled-components/macro";

export const Container = styled.div``;

export const Title = styled.div`
  font-weight: bold;
  margin-bottom: 0.25rem;
`;

export const SelectContainer = styled.div`
  display: block;
`;

export const Text = styled.div`
  display: block;
  padding: 7px 11px;

  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
