import styled from "styled-components/macro";
import { COLORES } from "../../constants";

export const Wrapper = styled.div`
  width: 100%;
  background-color: ${COLORES.DEFAULT_MODULE_BACKGROUND};
  margin-bottom: 2rem;
  border-radius: 0.25rem;
`;

export const Header = styled.div`
  width: 100%;
  height: 2rem;
  color: ${COLORES.DEFAULT_MODULE_HEADER_FONT};
  background-color: ${COLORES.DEFAULT_MODULE_HEADER_BACKGROUND};
  font-weight: bold;
  border-radius: 0.25rem 0.25rem 0 0;

  p {
    line-height: 2rem;
    padding-left: 1rem;
  }
`;

export const Content = styled.form`
  display: grid;
  grid-template-columns: repeat(${(props) => props.columns || 6}, 1fr);
  gap: 1rem;
  padding: 1rem;

  p {
    min-height: 1rem;
  }

  .bold {
    font-weight: bold;
  }
`;

export const ReadOnly = styled.div`
  grid-column: ${(props) => props.colSpan};
`;

export const Buttons = styled.div`
  display: flex;
  column-gap: 1rem;
  justify-content: flex-start;
  padding: 1rem;
  padding-top: 0;
`;

export const TextoSeparador = styled.div`
grid-column: ${(props) => props.colSpan};

  h2 {
    font-size:1.5em;
    width: 100%;
    text-align: center;
    border-bottom: 1px solid #000;
    line-height: 0.1em;
    margin: 10px 0 20px;
  }
  h2 span {
    background-color: ${COLORES.DEFAULT_MODULE_BACKGROUND};
    padding: 0 10px;
  }
`;
