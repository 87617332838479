import React, { useState, useEffect } from "react";
import * as S from "./styles";
import * as API from "../../api";
import { useParams, useNavigate, Link } from "react-router-dom";
import { toast } from "react-toastify";
import { ModuleTareaDatosGenerales } from "../../components/ModuleTareaDatosGenerales";
import { Button } from "../../components/Button";
import { Loading } from "../../components/Loading";
import {
  METADATA_AUTORIZACION_DATOS_GENERALES,
  METADATA_AUTORIZACION_FORMULARIO,
} from "../../constants";
import { ModuleAutorizacionFormulario } from "../../components/ModuleAutorizacionFormulario";
import { ModalAutorizacion } from "../../components/ModalAutorizacion";
import { usePermissions } from "../../context/PermissionsContext";

export const Autorizacion = () => {
  const { permissions } = usePermissions();
  const { idRevisionOP } = useParams();
  const navigate = useNavigate();
  const [datosGenerales, setDatosGenerales] = useState({});
  const [idReserva, setIdReserva] = useState("");
  const [triggerRefresh, setTriggerRefresh] = useState(false);
  const [loading, setLoading] = useState(true);
  const [values, setValues] = useState({});
  const [visibleModal, setVisibleModal] = useState("");
  useEffect(() => {
    let isMounted = true;

    API.obtenerTareaAutorizar({ idTarea: idRevisionOP })
      .then((res) => {
        if (isMounted) {
          setDatosGenerales(res.datosGenerales);
          setIdReserva(res.idReserva);
        }
      })
      .catch((error) => API.DEVELOP && console.log(error))
      .finally(() => setLoading(false));

    return () => (isMounted = false);
  }, [idRevisionOP]);

  useEffect(() => {
    if (triggerRefresh) {
      API.obtenerTareaAutorizar({ idTarea: idRevisionOP });
      setTriggerRefresh(false);
    }
  }, [idRevisionOP, triggerRefresh]);

  useEffect(() => {
    const values = {};

    const setDefaultValue = (e) => {
      switch (e.type) {
        default:
          values[e.name] = "";
      }
    };

    METADATA_AUTORIZACION_FORMULARIO.forEach((props) => {
      if (props.name === "_group")
        props.fields.forEach((props) => setDefaultValue(props));
      else setDefaultValue(props);
    });

    setValues(values);
  }, []);

  const completarTareaAutorizar = (aprobacion) => {
    setLoading(true);
    setVisibleModal("");
    API.completarTareaAutorizar({
      IdTareaRevisionOp: idRevisionOP,
      Observacion: values["observacion"],
      Aprobacion: aprobacion,
    })
      .then((res) => {
        if (res.resultadoPeticion === 1) {
          aprobacion === true
            ? toast.success("Autorización Aprobada")
            : toast.success("Autorización Denegada");
          navigate("/");
        } else toast.error(res.mensajeUsuario);
      })
      .catch((error) => API.DEVELOP && console.log(error))
      .finally(() => setLoading(false));
  };

  const renderModal = () => {
    switch (visibleModal) {
      case "aprobar":
        return (
          <ModalAutorizacion
            title="Aprobar Autorización"
            textBody="¿Está seguro de aprobar la autorización?"
            labelConfirm="Sí, Aprobar"
            lists={{}}
            confirm={() => completarTareaAutorizar(true)}
            cancel={() => setVisibleModal("")}
          />
        );

      case "denegar":
        return (
          <ModalAutorizacion
            title="Denegar Autorización"
            textBody="¿Está seguro de denegar la autorización?"
            labelConfirm="Sí, Denegar"
            lists={{}}
            confirm={() => completarTareaAutorizar(false)}
            cancel={() => setVisibleModal("")}
          />
        );

      default:
        return (
          <ModalAutorizacion
            type={visibleModal}
            title=""
            labelConfirm=""
            lists={{}}
            confirm={() => setVisibleModal("")}
            cancel={() => setVisibleModal("")}
          />
        );
    }
  };

  return (
    <S.Wrapper>
      {loading && <Loading />}
      {visibleModal && renderModal()}

      <ModuleTareaDatosGenerales
        data={datosGenerales}
        metadata={METADATA_AUTORIZACION_DATOS_GENERALES}
      />

      <ModuleAutorizacionFormulario
        metadata={METADATA_AUTORIZACION_FORMULARIO}
        columns={2}
        values={values}
        setValues={setValues}
        access={permissions.AutorizarTarea}
      />

      <S.Buttons>
        <Link to={"/FichaReserva/" + idReserva}>
          <Button label="Ver Ficha" />
        </Link>
        <Button
          label="Aprobar"
          type="approve"
          onClick={() => setVisibleModal("aprobar")}
          disabled={
            !permissions.AutorizarTarea?.Completar ||
            values["observacion"] === ""
          }
        />
        <Button
          label="Denegar"
          type="deny"
          onClick={() => setVisibleModal("denegar")}
          disabled={
            !permissions.AutorizarTarea?.Completar ||
            values["observacion"] === ""
          }
        />
      </S.Buttons>
    </S.Wrapper>
  );
};
