import React, { useEffect, useCallback } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { Layout } from "./pages/Layout";
import { Paths } from "./paths";
import { GlobalStyle } from "./styles/global";
import * as API from "./api";
import {
  AuthenticatedTemplate,
  useIsAuthenticated,
  useMsal,
} from "@azure/msal-react";
import { usePermissions } from "./context/PermissionsContext";
import { loginRequest } from "./authConfig";

const App = () => {
  const { instance: msalInstance, inProgress, accounts } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  const { setPermissions } = usePermissions();

  useEffect(() => {
    localStorage.removeItem("started");
    API.DEVELOP && console.log("%c[App] configureAxios()", "color: yellow");
    API.configureAxios(msalInstance, setPermissions);
  }, [msalInstance, setPermissions]);

  const appAutenticar = useCallback(
    (msalToken) => {
      API.DEVELOP && console.log("%c[App] appAutenticar", "color: yellow");
      const data = { tokenEntrada: msalToken };

      API.autenticar(data)
        .then((res) => {
          localStorage.setItem("appToken", res.token);
          localStorage.setItem("appTokenExpiresOn", res.refreshToken);
          setPermissions(res.accesoModulo);
        })
        .catch((error) => console.log("autenticar(app)->error", error));
    },
    [setPermissions]
  );

  useEffect(() => {
    API.DEVELOP &&
      console.log(
        "[App] inProgress:",
        inProgress,
        "| isAuthenticated:",
        isAuthenticated,
        "| accounts:",
        accounts,
        Date.now()
      );
    const currentTime = Date.now();
    let timeoutId = null;

    const isMsalTokenExtExpiresOnValid = () => {
      const msalToken = localStorage.getItem("msalToken");
      const msalTokenExtExpiresOn = Number(
        localStorage.getItem("msalTokenExtExpiresOn")
      );
      return msalToken && currentTime < msalTokenExtExpiresOn;
    };

    const isMsalTokenExpiresOnValid = () => {
      const msalToken = localStorage.getItem("msalToken");
      const msalTokenExpiresOn = Number(
        localStorage.getItem("msalTokenExpiresOn")
      );
      return msalToken && currentTime < msalTokenExpiresOn;
    };

    const isAppTokenValid = () => {
      const appToken = localStorage.getItem("appToken");
      const appTokenExpiresOn = Number(
        localStorage.getItem("appTokenExpiresOn")
      );
      return appToken && currentTime < appTokenExpiresOn;
    };

    const handleRedirect = async () => {
      API.DEVELOP && console.log("%c[App] handleRedirect", "color: yellow");
      msalInstance.handleRedirectPromise().then(async (res) => {
        API.DEVELOP && console.log("[App] handleRedirectPromise -> then", res);
        if (res) {
          if (res.account) msalInstance.setActiveAccount(res.account);
          if (res.accessToken) {
            API.DEVELOP &&
              console.log(
                "%c[App] handleRedirectPromise res.accessToken => set msalToken, msalTokenExpiresOn y msalTokenExtExpiresOn",
                "color: green"
              );
            const msalToken = res.accessToken;
            const msalTokenExpiresOn = Number(res.expiresOn);
            const msalTokenExtExpiresOn = Number(res.extExpiresOn);
            localStorage.setItem("msalToken", msalToken);
            localStorage.setItem(
              "msalTokenExpiresOn",
              msalTokenExpiresOn.toString()
            );
            localStorage.setItem(
              "msalTokenExtExpiresOn",
              msalTokenExtExpiresOn.toString()
            );
            API.DEVELOP &&
              console.log(
                "[App] handleRedirectPromise -> res => appAutenticar(msalToken)"
              );
            appAutenticar(msalToken);
          } else {
            console.error("[App] handleRedirectPromise -> !res.accessToken");
          }
        } else {
          if (!isMsalTokenExtExpiresOnValid()) {
            API.DEVELOP &&
              console.log(
                "%c[App] !isMsalTokenExtExpiresOnValid => msalInstance.loginRedirect",
                "color: pink",
                msalInstance.getActiveAccount()?.username
              );
            return msalInstance
              .loginRedirect(loginRequest)
              .catch((error) =>
                console.error("[App] loginRedirect -> error", error)
              );
          }

          if (!isMsalTokenExpiresOnValid()) {
            API.DEVELOP &&
              console.log(
                "%c[App] !isMsalTokenExpiresOnValid => msalInstance.acquireTokenSilent",
                "color: pink",
                msalInstance.getActiveAccount()?.username
              );
            const accessTokenRequest = { ...loginRequest };
            try {
              const res = await msalInstance.acquireTokenSilent(
                accessTokenRequest
              );
              const msalToken = res.accessToken;
              API.DEVELOP &&
                console.log(
                  "%c[App] Nuevo msalToken",
                  "color: green",
                  msalToken.substring(0, 100)
                );
              const msalTokenExpiresOn = Number(res.expiresOn);
              const msalTokenExtExpiresOn = Number(res.extExpiresOn);
              localStorage.setItem("msalToken", msalToken);
              localStorage.setItem(
                "msalTokenExpiresOn",
                msalTokenExpiresOn.toString()
              );
              localStorage.setItem(
                "msalTokenExtExpiresOn",
                msalTokenExtExpiresOn.toString()
              );
            } catch (error) {
              console.error(
                "[App] msalInstance.acquireTokenSilent -> error",
                error
              );
            }
          }

          if (!isAppTokenValid()) {
            API.DEVELOP &&
              console.log(
                "%c[App] !isAppTokenValid => appAutenticar()",
                "color: pink"
              );
            const msalToken = localStorage.getItem("msalToken") || "";

            if (localStorage.getItem("appToken") !== "notAuthorized")
              appAutenticar(msalToken);
          } else {
            API.DEVELOP &&
              console.log("%c[App] Tokens vigentes", "color: lightgreen");
          }
        }
        localStorage.setItem("started", "true");
      });
    };

    if (inProgress === "none") {
      timeoutId = setTimeout(() => {
        if (isAuthenticated) {
          API.DEVELOP &&
            console.log(
              '%c[App] inProgress === "none" && isAuthenticated => handleRedirect()',
              "color: lightgreen"
            );
          handleRedirect();
        } else {
          API.DEVELOP &&
            console.log(
              '%c[App] inProgress === "none" && !isAuthenticated => msalInstance.loginRedirect',
              "color: pink"
            );
          msalInstance
            .loginRedirect(loginRequest)
            .catch((error) =>
              console.error("[App] msalInstance.loginRedirect -> error", error)
            );
        }
      }, 100);
      // delay para evitar doble llamada al msalInstance.loginRedirect
      // debido a que luego de autenticarse el inProgress se pone a "none" aprox 50ms antes de que el isAuthenticated pase a true
    }

    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [msalInstance, inProgress, isAuthenticated, accounts, appAutenticar]);

  return (
    <AuthenticatedTemplate>
      <Router>
        <Layout>
          <Paths />
        </Layout>
        <GlobalStyle />
      </Router>
    </AuthenticatedTemplate>
  );
};

export default App;

export const printVigenciaToken = (unixTime, label, env = "[]") => {
  const currentTime = Date.now();
  const dif = Math.abs(unixTime - currentTime);
  const hoursExt = Math.floor(dif / 3600000); // 1 hora = 3600000 milisegundos
  const minutesExt = Math.floor((dif % 3600000) / 60000); // 1 minuto = 60000 milisegundos
  const secondsExt = Math.floor((dif % 60000) / 1000); // 1 segundo = 1000 milisegundos
  if (unixTime > currentTime)
    console.log(
      `%c[${env}] ${label} Vigente: ${hoursExt}h ${minutesExt}m ${secondsExt}s`,
      "color: green;",
      `| ${new Date(unixTime)}`
    );
  else
    console.log(
      `%c[${env}] ${label} Caducado: ${hoursExt}h ${minutesExt}m ${secondsExt}s`,
      "color: red;",
      `| ${new Date(unixTime)}`
    );
};
