import styled from "styled-components/macro";

export const Wrapper = styled.div`
  font-size: 0.75rem;
  padding: 1.5rem;

  *.highlighted {
    font-weight: normal;
    margin-top: 0;
    background-color: yellow;
  }
  *.highlighted2 {
    font-weight: normal;
    margin-top: 0;
    background-color: #87cefa;
  }
  *.highlighted3 {
    font-weight: normal;
    margin-top: 0;
    background-color: #fbb239;
  }
  *.highlighted4 {
    font-weight: normal;
    margin-top: 0;
    background-color: #ffb776;
  }
`;

export const Buttons = styled.div`
  display: flex;
  justify-content: space-between;
  a {
    text-decoration: none;
  }
  div {
    display: flex;
    column-gap: 1rem;
  }
`;

