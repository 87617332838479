import React from "react";
import PropTypes from "prop-types";
import * as S from "./styles";
import { Button } from "../../components/Button";

export const ModalAutorizacion = ({
  title,
  textBody,
  labelConfirm,
  confirm,
  cancel,
}) => {
  return (
    <S.Wrapper onClick={cancel}>
      <S.Content onClick={(event) => event.stopPropagation()}>
        <S.Header>
          <p>{title}</p>
        </S.Header>
        <S.Body>
          <S.WrapperModalBody>
            <h3>{textBody}</h3>
          </S.WrapperModalBody>
          <S.Buttons>
            <Button label={labelConfirm} onClick={confirm} />
            <Button label="Cancelar" onClick={cancel} type="cancel" />
          </S.Buttons>
        </S.Body>
      </S.Content>
    </S.Wrapper>
  );
};

ModalAutorizacion.propTypes = {
  title: PropTypes.string,
  textBody: PropTypes.string,
  labelConfirm: PropTypes.string,
  confirm: PropTypes.func,
  cancel: PropTypes.func,
};
