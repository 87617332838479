import React, { useState } from "react";
import PropTypes from "prop-types";
import * as S from "./styles";
import * as API from "../../api";
import { toast } from "react-toastify";
import { ModuleTablaBasica } from "../../components/ModuleTablaBasica";
import { ModuleTareaDocsReserva } from "../../components/ModuleTareaDocsReserva";
import { ModuleTareaDocsActivos } from "../ModuleTareaDocsActivos";
import { ModalDocumentos } from "../ModalDocumentos";
import { ModuleTareaDatosGenerales } from "../ModuleTareaDatosGenerales";
import {
  DATA_SOLAPAS,
  METADATA_SOLAPA_ACTIVOS,
  METADATA_DOCS_RESERVA,
  METADATA_DOCS_ACTIVOS,
  METADATA_SOLAPA_TAREAS,
  METADATA_SOLAPA_CANCELACION_TITULARIDAD,
  METADATA_SOLAPA_FORMALIZACION_GENERALES,
  METADATA_SOLAPA_FORMALIZACION_GESTORIA,
  METADATA_SOLAPA_FORMALIZACION_FIRMA,
  METADATA_SOLAPA_FORMALIZACION_VISTO_BUENO,
  METADATA_SOLAPA_FORMALIZACION_HISTORICO,
  METADATA_SOLAPA_FORMALIZACION_FISCALIDAD,
  METADATA_SOLAPA_EMAILS,
  METADATA_SOLAPA_INTERVINIENTES,
} from "../../constants";

export const ModuloSolapas = ({
  data,
  lists,
  showEmail,
  refresh,
  setLoading,
  access,
}) => {
  const [tabActive, setTabActive] = useState("activos");
  const [activo, setActivo] = useState({});
  const [visibleModal, setVisibleModal] = useState("");

  const addReserveDocument = (dataDocument) => {
    setLoading(true);
    setVisibleModal("");
    dataDocument.idReserva = data.datosGenerales?.idReserva;

    API.crearDocumentoReserva(dataDocument)
      .then((res) => {
        toast.success("Documento creado");
        refresh();
      })
      .catch((error) => {
        toast.error(`No se pudo crear el documento\n${error.msg}`);
        API.DEVELOP && console.log(error);
        setLoading(false);
      });
  };

  const requestAddActiveDocument = (activo) => {
    setActivo(activo);
    setVisibleModal("addActiveDocument");
  };

  const addActiveDocument = (dataDocument) => {
    setLoading(true);
    setVisibleModal("");
    dataDocument.idReserva = data.datosGenerales?.idReserva;
    dataDocument.idActivo = activo.id;

    API.crearDocumentoActivo(dataDocument)
      .then((res) => {
        refresh();
        toast.success("Documento creado");
      })
      .catch((error) => {
        toast.error(`No se pudo crear el documento\n${error.msg}`);
        setLoading(false);
        API.DEVELOP && console.log(error);
      });
  };

  const renderModal = () => {
    switch (visibleModal) {
      case "addReserveDocument":
        return (
          <ModalDocumentos
            type="addDocument"
            title="Nuevo tipo de documento"
            labelConfirm="Guardar"
            lists={lists}
            documentsListName="TipoDocumentoReserva"
            confirm={addReserveDocument}
            cancel={() => setVisibleModal("")}
          />
        );

      case "addActiveDocument":
        return (
          <ModalDocumentos
            type="addDocument"
            title="Nuevo tipo de documento"
            labelConfirm="Guardar"
            lists={lists}
            documentsListName="TipoDocumentoActivo"
            confirm={addActiveDocument}
            cancel={() => setVisibleModal("")}
          />
        );

      default:
        return null;
    }
  };

  const body = () => {
    switch (tabActive) {
      case "activos":
        return (
          <ModuleTablaBasica
            data={data.activos}
            metadata={METADATA_SOLAPA_ACTIVOS}
          />
        );

      case "docsReserva":
        return (
          <ModuleTareaDocsReserva
            data={data.documentosReserva}
            metadata={METADATA_DOCS_RESERVA}
            requestAdd={() => setVisibleModal("addReserveDocument")}
            refreshDocs={refresh}
            setLoading={setLoading}
            access={access.FichaReserva}
          />
        );

      case "docsActivos":
        return (
          <ModuleTareaDocsActivos
            data={data.documentosActivos}
            metadata={METADATA_DOCS_ACTIVOS}
            requestAdd={requestAddActiveDocument}
            requestEdit={()=>null}
            refreshDocs={refresh}
            setLoading={setLoading}
            access={access.FichaReserva}
          />
        );

      case "tareas":
        return (
          <ModuleTablaBasica
            data={data.tareas}
            metadata={METADATA_SOLAPA_TAREAS}
          />
        );

      case "cancelacionTitularidad":
        return (
          <ModuleTablaBasica
            data={data.cancelacionTitularidad}
            metadata={METADATA_SOLAPA_CANCELACION_TITULARIDAD}
          />
        );

      case "formalizacion":
        return (
          <S.Formalizacion>
            <ModuleTareaDatosGenerales
              label="Datos Formalización"
              data={data.datosFormalizacion}
              metadata={METADATA_SOLAPA_FORMALIZACION_GENERALES}
            />
            <S.ModulesRow>
              <ModuleTareaDatosGenerales
                columns={3}
                label="Datos Gestoría"
                data={data.datosGestoria}
                metadata={METADATA_SOLAPA_FORMALIZACION_GESTORIA}
              />
              <ModuleTareaDatosGenerales
                columns={3}
                label="Datos Firma"
                data={data.datosFirma}
                metadata={METADATA_SOLAPA_FORMALIZACION_FIRMA}
              />
            </S.ModulesRow>
            <ModuleTareaDatosGenerales
              columns={1}
              label="Visto Bueno Formalización"
              data={data.vistobuenoFormalizacion}
              metadata={METADATA_SOLAPA_FORMALIZACION_VISTO_BUENO}
            />
            <ModuleTablaBasica
              label="Histórico solicitudes y comentarios SSJJ"
              data={data.historicoSolicitudesSSJJ}
              metadata={METADATA_SOLAPA_FORMALIZACION_HISTORICO}
            />
            <ModuleTareaDatosGenerales
              columns={1}
              label="Datos Fiscalidad"
              data={data.datosFiscalidad}
              metadata={METADATA_SOLAPA_FORMALIZACION_FISCALIDAD}
            />
          </S.Formalizacion>
        );

      case "emails":
        return (
          <ModuleTablaBasica
            data={data.emails}
            metadata={METADATA_SOLAPA_EMAILS}
            action={showEmail}
          />
        );

      case "intervinientes":
        return (
          <ModuleTablaBasica
            data={data.intervinientes}
            metadata={METADATA_SOLAPA_INTERVINIENTES}
          />
        );

      default:
        return tabActive;
    }
  };

  return (
    <S.Wrapper>
      {visibleModal && renderModal()}

      <S.Solapas>
        {DATA_SOLAPAS.map((solapa) => (
          <S.Solapa
            key={solapa.name}
            active={tabActive === solapa.name}
            onClick={() => setTabActive(solapa.name)}
          >
            {solapa.label}
          </S.Solapa>
        ))}
      </S.Solapas>

      <S.Content>{body()}</S.Content>
    </S.Wrapper>
  );
};

ModuloSolapas.propTypes = {
  data: PropTypes.object,
  lists: PropTypes.object,
  showEmail: PropTypes.func,
  refresh: PropTypes.func,
  setLoading: PropTypes.func,
  access: PropTypes.object,
};
