import React from "react";
import PropTypes from "prop-types";
import * as S from "./styles";
import { RowResultUsuarios } from "../RowResultUsuarios";

export const ModuleResultUsuarios = ({
  title,
  metadata,
  data,
  moreResults,
  currentSort,
  sort,
}) => {
  const infoRecords = data.informacionRegistros;

  const textTitle = () => {
    const records = infoRecords
      ? `(${infoRecords.registrosMostrados} de ${infoRecords.totalRegistros})`
      : "";
    return `${title} ${records}`;
  };

  const textFoot = () => {
    if (infoRecords === undefined) return "";
    if (infoRecords?.totalRegistros === 0)
      return "No se han encontrado resultados";
    if (infoRecords?.registrosMostrados === infoRecords?.totalRegistros)
      return "No hay más resultados";
    else return "Mostrar más resultados";
  };

  const isFootButton = () => {
    if (infoRecords === undefined) return false;
    if (infoRecords?.registrosMostrados === infoRecords?.totalRegistros)
      return false;
    return true;
  };

  return (
    <S.Wrapper>
      <S.Header>
        <p>{textTitle()}</p>
      </S.Header>
      <S.Content>
        <table>
          <thead>
            <S.TrColumnTitles>
              {metadata.map((e) => (
                <S.ButtonTh
                  key={e.label}
                  scope="col"
                  active={e.name === currentSort.field}
                >
                  {e.label}
                </S.ButtonTh>
              ))}
              <th />
            </S.TrColumnTitles>
          </thead>
          <tbody>
            {data.registros?.map((e, index) => (
              <RowResultUsuarios key={index} metadata={metadata} data={e} />
            ))}
          </tbody>
          <tfoot>
            <tr>
              <S.TdFoot colSpan={metadata.length + 2}>
                {isFootButton() ? (
                  <button onClick={moreResults}>{textFoot()}</button>
                ) : (
                  <p>{textFoot()}</p>
                )}
              </S.TdFoot>
            </tr>
          </tfoot>
        </table>
      </S.Content>
    </S.Wrapper>
  );
};

ModuleResultUsuarios.propTypes = {
  title: PropTypes.string,
  metadata: PropTypes.array.isRequired,
  data: PropTypes.object.isRequired,
  moreResults: PropTypes.func,
  currentSort: PropTypes.object,
  sort: PropTypes.func,
};
