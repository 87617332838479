import React from "react";
import PropTypes from "prop-types";
import * as S from "./styles";
import { RowActive } from "../RowActive";

export const ModuleTareaDocsActivos = ({
  data = [],
  metadata = [],
  requestAdd,
  requestEdit,
  refreshDocs,
  setLoading,
  access,
  requestBorradoTarea,
}) => {
  return (
    <S.Wrapper>
      <S.Header>
        <p>Documentos de los Activos</p>
      </S.Header>
      <S.Content>
        <table>
          <thead>
            <S.TrColumnTitles>
              {metadata.map((e) => (
                <th key={e.name} title={e.label}>{e.label}</th>
              ))}
            </S.TrColumnTitles>
          </thead>
          <tbody>
            {data.map((docActivo) => (
              <RowActive
                key={docActivo.activo.id}
                data={docActivo}
                metadata={metadata}
                requestAdd={requestAdd}
                requestEdit={requestEdit}
                refreshDocs={refreshDocs}
                setLoading={setLoading}
                access={access}
                requestBorradoTarea={requestBorradoTarea}
              />
            ))}
          </tbody>
        </table>
      </S.Content>
    </S.Wrapper>
  );
};

ModuleTareaDocsActivos.propTypes = {
  data: PropTypes.array,
  metadata: PropTypes.array,
  requestAdd: PropTypes.func,
  requestEdit: PropTypes.func,
  refreshDocs: PropTypes.func,
  setLoading: PropTypes.func,
  access: PropTypes.object,
  requestBorradoTarea:PropTypes.func,
};
