import React from "react";
import PropTypes from "prop-types";
import * as S from "./styles";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faEye } from "@fortawesome/free-solid-svg-icons";

export const ModuleTablaBasica = ({
  data = [],
  metadata = [],
  label,
  action,
}) => {
  const navigate = useNavigate();

  return (
    <S.Wrapper>
      {label && (
        <S.Header>
          <p>{label}</p>
        </S.Header>
      )}
      <S.Content>
        <table>
          <thead>
            <S.TrColumnTitles>
              {metadata.map((field) => (
                <th key={field.name} title={field.label}>
                  {field.label}
                </th>
              ))}
            </S.TrColumnTitles>
          </thead>
          <tbody>
            {data.map((register, index) => (
              <tr key={index}>
                {metadata.map((field) => {
                  if (field.type === "clickable") {
                    switch (field.name) {
                      case "email":
                        return (
                          <S.TdEmail key={field.name}>
                            <FontAwesomeIcon
                              icon={faEnvelope}
                              size="lg"
                              onClick={() => action(register.idCorreo)}
                            />
                          </S.TdEmail>
                        );

                      case "mirarCarga":
                        return (
                          <S.TdEye key={field.name}>
                            <FontAwesomeIcon
                              icon={faEye}
                              size="lg"
                              onClick={() =>
                                navigate(`/Carga/${register.idCarga}`)
                              }
                            />
                          </S.TdEye>
                        );

                      default:
                    }
                  }

                  return (
                    <td key={field.name} title={register[field.name]}>
                      {register[field.name]}
                    </td>
                  );
                })}
              </tr>
            ))}
          </tbody>
        </table>
      </S.Content>
    </S.Wrapper>
  );
};

ModuleTablaBasica.propTypes = {
  data: PropTypes.array,
  metadata: PropTypes.array,
  label: PropTypes.string,
  action: PropTypes.func,
};
