import styled from "styled-components/macro";
import { COLORES } from "../../constants";

export const Wrapper = styled.div`
  display: flex;
  gap: 1rem;
  align-items: center;
  margin: 2rem;
  font-size: 1.25rem;

  svg {
    color: ${COLORES.MENU_BACKGROUND};
  }
`;
