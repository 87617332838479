import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import * as S from "./styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSquareCheck,
  faSquare,
  faUser,
  faChevronUp,
  faChevronDown,
} from "@fortawesome/free-solid-svg-icons";
import { RowResultAsignacionGestoria } from "../RowResultAsignacionGestoria";
export const ModuleResultBuscadorGestoriaProvincia = ({
  title,
  metadata,
  data,
  selectAll,
  deselectAll,
  selectedIds,
  requestReasign,
  handleChangeSelection,
}) => {
  const infoRecords = data.informacionRegistros;
  const [openedOptionsRowAsignar, setOpenedOptionsRowAsignar] = useState(false);
  const [triggerRefresh, setTriggerRefresh] = useState(false);

  useEffect(() => {
    if (triggerRefresh) {
      setTriggerRefresh(false);
    }
  }, [triggerRefresh]);

  const setOptionsRowAsignar = () => {
    setOpenedOptionsRowAsignar(!openedOptionsRowAsignar);
  };

  const textTitle = () => {
    const records = infoRecords ? `(${infoRecords.totalRegistros})` : "";
    return `${title} ${records}`;
  };

  const textFoot = () => {
    return "No hay más resultados";
  };

  const desagrupar = () => {
    data.registros?.gesProResCartera.forEach((d) => {
      d.visible = true;
      d.gesProResComunidad.forEach((c) => (c.visible = true));
    });
    setTriggerRefresh(true);
  };

  const agrupar = () => {
    data.registros?.gesProResCartera.forEach((d) => {
      d.visible = false;
      d.gesProResComunidad.forEach((c) => (c.visible = false));
    });
    setTriggerRefresh(true);
  };

  const toggleVisibilidadComunidad = (e) => {
    e.visible = !e.visible;
    setTriggerRefresh(true);
  };

  const toggleVisibilidadCartera = (e) => {
    e.visible = !e.visible;
    if (!e.visible) {
      e.gesProResComunidad.forEach((i) => {
        i.visible = false;
      });
    }
    setTriggerRefresh(true);
  };

  return (
    <S.Wrapper>
      <S.Header>
        <S.WrapperButtons>
          {textTitle()}
          <button onClick={setOptionsRowAsignar}>
            <FontAwesomeIcon icon={faUser} size="lg" />
          </button>
          <button onClick={desagrupar} className="cancel">
            {"Desagrupar todo"}
          </button>
          <button onClick={agrupar} className="cancel">
            {"Agrupar todo"}
          </button>
        </S.WrapperButtons>
      </S.Header>
      <S.Content>
        <table>
          <thead>
            {openedOptionsRowAsignar && (
              <S.TrMassiveReAsign>
                <th colSpan={metadata.length + 2}>
                  <p>Asignación masiva</p>
                  <button onClick={selectAll}>
                    <FontAwesomeIcon icon={faSquareCheck} size="lg" />
                  </button>
                  <button onClick={deselectAll}>
                    <FontAwesomeIcon icon={faSquare} size="lg" />
                  </button>
                  <button
                    onClick={() => requestReasign()}
                    disabled={!selectedIds?.length}
                  >
                    <FontAwesomeIcon icon={faUser} size="lg" />
                  </button>
                </th>
              </S.TrMassiveReAsign>
            )}
            <S.TrColumnTitlesReserva>
              <th />
              {metadata.map((e) => (
                <S.ButtonTh key={e.label} scope="col">
                  {e.label}
                </S.ButtonTh>
              ))}
              <th />
            </S.TrColumnTitlesReserva>
          </thead>
          <tbody>
            {data.registros?.gesProResCartera?.map((e) => (
              <>
                <S.TrDropdownGestoriaProvincia
                  onClick={() => toggleVisibilidadCartera(e)}
                >
                  <th />
                  <S.ButtonTh key={e.cartera} scope="col">
                    {e.cartera}
                    <button>
                      <FontAwesomeIcon
                        icon={e.visible ? faChevronUp : faChevronDown}
                      />
                    </button>
                  </S.ButtonTh>
                  <th />
                  <th />
                  <th />
                  <th />
                  <th />
                </S.TrDropdownGestoriaProvincia>
                {e.visible &&
                  e?.gesProResComunidad?.map((i, index) => (
                    <>
                      <S.TrDropdownGestoriaProvincia
                        onClick={() => toggleVisibilidadComunidad(i)}
                      >
                        <th />
                        <th />
                        <S.ButtonTh key={index}  scope="col">
                          {i.comunidad}
                          <button>
                            <FontAwesomeIcon
                              icon={i.visible ? faChevronUp : faChevronDown}
                            />
                          </button>
                        </S.ButtonTh>
                        <th />
                        <th />
                        <th />
                        <th />
                      </S.TrDropdownGestoriaProvincia>
                      {i.visible &&
                        i?.gesProResRegistros?.map((r) => (
                          <RowResultAsignacionGestoria
                            key={r.id}
                            metadata={metadata}
                            data={r}
                            handleChangeSelection={handleChangeSelection}
                            selected={selectedIds?.includes(
                              r.reserva?.valor || r.id
                            )}
                            requestReasignReserva={requestReasign}
                          />
                        ))}
                    </>
                  ))}
              </>
            ))}
          </tbody>
          <tfoot>
            <tr>
              <S.TdFoot colSpan={metadata.length + 2}>
                <p>{textFoot()}</p>
              </S.TdFoot>
            </tr>
          </tfoot>
        </table>
      </S.Content>
    </S.Wrapper>
  );
};

ModuleResultBuscadorGestoriaProvincia.propTypes = {
  data: PropTypes.object.isRequired,
  metadata: PropTypes.array.isRequired,
  moreResults: PropTypes.any,
  sort: PropTypes.func,
  title: PropTypes.any.isRequired,
};
