import React from "react";
import PropTypes from "prop-types";
import * as S from "./styles";
import * as API from "../../api";
import { InputText } from "../InputText";
import { InputNumber } from "../InputNumber";
import { InputTextArea } from "../InputTextArea";
import { InputList } from "../InputList";
import { InputDate } from "../InputDate";
import { InputCheckbox } from "../InputCheckbox";
import { Button } from "../Button";
import {
  ESTADO_CONFIRMACION_FIRMA_CONFIRMADA,
  ESTADO_CONFIRMACION_FIRMA_APLAZADA_SIN_FECHA,
  ESTADO_CONFIRMACION_FIRMA_APLAZADA_CON_FECHA,
} from "../../constants";

export const ModuleTareaDatosFormalizacion = ({
  data,
  metadata,
  lists,
  values,
  setValues,
  options,
  setOptions,
  hiddens,
  setHiddens,
  label = "Datos Formalización",
  columns,
  revisionOP = false,
  requestAsistenciaFirma,
  requestFiscalidad,
  requestVistoBuenoSSJJ,
  access,
}) => {
  const convertOptions = (options) =>
    options.map((opt) => ({
      value: opt.valor,
      label: opt.etiqueta,
    }));

  const handleChange = (prop, value) => {
    const tmpValues = { ...values };
    tmpValues[prop] = value;
    setValues(tmpValues);
  };

  const handleListChange = (field, value) => {
    const tmpOptions = { ...options };
    const tmpValues = { ...values };
    tmpValues[field.name] = value;

    if (lists[field.list].listasHijas.length === 0) {
      setValues(tmpValues);
    } else
      lists[field.list].listasHijas.forEach((list) => {
        const listaRelacional = {
          nombreLista: field.list,
          idEntidadRelacional: value,
        };
        const data = {
          lista: list,
          listaRelacional,
        };
        API.obtenerLista(data).then((res) => {
          tmpOptions[list] = convertOptions(res.valores);
          setOptions(tmpOptions);
          setValues(tmpValues);
        });
      });
  };

  const handleListEstadoFirmaChange = (field, value) => {
    switch (value) {
      case ESTADO_CONFIRMACION_FIRMA_CONFIRMADA:
        hiddens["motivoAplazado"] = true;
        hiddens["fecPrevistaFirma"] = true;
        hiddens["fechaConfirmacionFirma"] = false;
        break;
      case ESTADO_CONFIRMACION_FIRMA_APLAZADA_CON_FECHA:
        hiddens["motivoAplazado"] = false;
        hiddens["fecPrevistaFirma"] = false;
        hiddens["fechaConfirmacionFirma"] = true;
        break;
      case ESTADO_CONFIRMACION_FIRMA_APLAZADA_SIN_FECHA:
        hiddens["motivoAplazado"] = false;
        hiddens["fecPrevistaFirma"] = true;
        hiddens["fechaConfirmacionFirma"] = true;
        break;
      default:
        hiddens["motivoAplazado"] = true;
        hiddens["fecPrevistaFirma"] = true;
        hiddens["fechaConfirmacionFirma"] = true;
        break;
    }
    setHiddens(hiddens);
    const tmpOptions = { ...options };
    const tmpValues = { ...values };
    tmpValues[field.name] = value;

    if (lists[field.list].listasHijas.length === 0) {
      setValues(tmpValues);
    } else
      lists[field.list].listasHijas.forEach((list) => {
        const listaRelacional = {
          nombreLista: field,
          idEntidadRelacional: value,
        };
        const data = {
          lista: list.name,
          listaRelacional,
        };
        API.obtenerLista(data).then((res) => {
          tmpOptions[list] = convertOptions(res.valores);
          setOptions(tmpOptions);
        });
      });
  };

  return (
    <S.Wrapper>
      <S.Header>
        <p>{label}</p>
      </S.Header>
      <S.Content columns={columns}>
        {metadata.map((field) => {
          if (hiddens && hiddens[field.name]) {
            return "";
          }
          switch (field.type) {
            case "separator":
              return (
                <S.TextoSeparador key={field.name} colSpan={field.colSpan}>
                  <h2 className="bold">
                    <span>{field.label}</span>
                  </h2>
                </S.TextoSeparador>
              );
            case "text":
              return (
                <InputText
                  key={field.name}
                  field={field}
                  value={values[field.name] || ""}
                  handleChange={handleChange}
                  disabled={!access?.Editar}
                />
              );

            case "number":
              return (
                <InputNumber
                  key={field.name}
                  field={field}
                  value={values[field.name] || ""}
                  handleChange={handleChange}
                  disabled={
                    !access?.Editar || //false
                    (!values.sujetoCampanaComercial &&
                      field.name === "importeFacturaPagado") ||
                    ((values.sujetoProrrateo === "0" ||
                      values.sujetoProrrateo === "2") &&
                      (field.name === "repercusionImporte" ||
                        field.name === "importeTeorico"))
                  }
                />
              );

            case "textarea":
              return (
                <InputTextArea
                  key={field.name}
                  field={field}
                  rows={field.rows}
                  value={values[field.name] || ""}
                  handleChange={handleChange}
                  colSpan={field.colSpan}
                  disabled={!access?.Editar || field.disabled}
                />
              );

            case "list":
              return (
                <InputList
                  key={field.name}
                  field={field}
                  value={values[field.name] || ""}
                  options={options[field.list] || []}
                  handleChange={handleListChange}
                  isClearable={lists[field.list]?.mostrarVacio || false}
                  disabled={
                    !access?.Editar ||
                    (field.name === "gestoria" &&
                      data.confirmacionAsistenciaFirmaPendiente) ||
                    (field.name === "notario" && values.provincia === "")
                  }
                />
              );

            case "listEstadoFirma":
              return (
                <InputList
                  key={field.name}
                  field={field}
                  value={values[field.name] || ""}
                  options={options[field.list] || []}
                  handleChange={handleListEstadoFirmaChange}
                  isClearable={lists[field.list]?.mostrarVacio || false}
                  disabled={!access?.Editar}
                />
              );

            case "date":
            case "datetime-local":
              return (
                <InputDate
                  key={field.name}
                  field={field}
                  value={values[field.name] || ""}
                  handleChange={handleChange}
                  disabled={
                    !access?.Editar ||
                    (field.name === "nuevaFechaPrevistaFirma"
                      ? values.aplazadoSinFecha
                      : false)
                  }
                />
              );

            case "checkbox":
              return (
                <InputCheckbox
                  key={field.name}
                  field={field}
                  value={values[field.name] || false}
                  handleChange={handleChange}
                  disabled={!access?.Editar}
                />
              );
            case "readOnly":
            default:
              return (
                <S.ReadOnly key={field.name} colSpan={field.colSpan}>
                  <p className="bold">{field.label}</p>
                  <p>{data[field.name]}</p>
                </S.ReadOnly>
              );
          }
        })}
      </S.Content>
      {revisionOP && (
        <S.Buttons>
          <Button
            type="cancel"
            label="Solicitar Asistencia Firma"
            onClick={requestAsistenciaFirma}
            disabled={
              !access?.Editar ||
              data.confirmacionAsistenciaFirmaPendiente ||
              values.gestoria === "0"
            }
          />
          <Button
            type="cancel"
            label="Solicitar Visto Bueno SSJJ"
            onClick={requestVistoBuenoSSJJ}
            disabled={!access?.Editar || data.vistoBuenoSSJJPendiente}
          />
          <Button
            type="cancel"
            label="Solicitar Fiscalidad"
            onClick={requestFiscalidad}
            disabled={!access?.Editar || data.solicitarFiscalidadPendiente}
          />
        </S.Buttons>
      )}
    </S.Wrapper>
  );
};

ModuleTareaDatosFormalizacion.propTypes = {
  data: PropTypes.object.isRequired,
  metadata: PropTypes.array.isRequired,
  lists: PropTypes.object.isRequired,
  values: PropTypes.object.isRequired,
  setValues: PropTypes.func,
  options: PropTypes.object.isRequired,
  setOptions: PropTypes.func,
  hiddens: PropTypes.object,
  setHiddens: PropTypes.func,
  label: PropTypes.string,
  columns: PropTypes.number,
  revisionOP: PropTypes.bool,
  requestAsistenciaFirma: PropTypes.func,
  requestFiscalidad: PropTypes.func,
  requestVistoBuenoSSJJ: PropTypes.func,
  access: PropTypes.object,
};
