import React, { useEffect, useState } from "react";
import * as S from "./styles";
import * as API from "../../api";
import { Loading } from "../../components/Loading";
import {
  METADATA_SEARCH_RESERVAGESTORIA,
  METADATA_RESULT_RESERVAGESTORIA,
  RESERVAGESTORIA_BY_PAGE,
} from "../../constants";
import { ModuleResultAsignacionGestoria } from "../../components/ModuleResultAsignacionGestoria";
import { ModuleSearchReservaGestoria } from "../../components/ModuleSearchReservaGestoria";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";

export const AsignacionGestoria = () => {
  const { idReserva } = useParams();
  const [lists, setLists] = useState({});
  const [result, setResult] = useState({});
  const [page, setPage] = useState(1);
  const [currentSort, setCurrentSort] = useState({});
  const [loading, setLoading] = useState(true);
  const [formData,setFormData] = useState({})

  useEffect(() => {
    let isMounted = true;
    API.obtenerListas("AsignacionGestoria", 0)
      .then((res) => isMounted && setLists(res))
      .catch((error) => API.DEVELOP && console.log(error))
      .finally(() => {
        const data = {};
        data.codReserva = idReserva
        data.paginacion = pagination(1);
        API.obtenerReservaGestoria(data).then((res) => {
          setResult(res || {});
          setPage(2);
        }).finally(()=>setLoading(false));
      });

    return () => (isMounted = false);
  }, [idReserva,loading]);

  const addReservaGestoria = (data = formData) => {
    setLoading(true);
    setFormData(formData);
    data.paginacion = pagination(1);
    API.crearModificarReservaGestoria(data)
      .then((res) => {
        if (res.resultadoPeticion === 1) {
          toast.success("Se ha añadido el registro");
        } else {
          toast.error(
            `No se ha podido añadir el registro \n${res.mensajeUsuario}`
          );
        }
      })
      .catch((error) => API.DEVELOP && console.log(error))

  };

  const deleteReservaGestoria = (data = formData) => {
    setLoading(true);
    setFormData(formData);
    data.paginacion = pagination(1);
    data.codReserva = idReserva;
    API.eliminarReservaGestoria(data)
      .then((res) => {
        if (res.resultadoPeticion === 1) {
          toast.success("Se ha eliminado el registro");
        } else {
          toast.error(
            `No se ha podido eliminar el registro \n${res.mensajeUsuario}`
          );
        }
      })
      .catch((error) => API.DEVELOP && console.log(error))
  };

  const moreReservaGestoria = () => {
    setLoading(true);
    const data = formData;
    data.orden = order();
    data.codReserva = idReserva;
    data.paginacion = pagination(page);
    API.obtenerReservaGestoria(data)
      .then((res) => {
        setResult({
          informacionRegistros: res.informacionRegistros,
          registros: [...result.registros, ...res.registros],
        });
        setPage(page + 1);
        setLoading(false);
      })
      .catch((error) => API.DEVELOP && console.log(error));
  };

  const sort = (field) => {
    setLoading(true);
    let direction;
    if (field !== currentSort.field) direction = 1;
    else direction = currentSort.direction === 1 ? 2 : 1;
    const newSort = { field, direction };
    setCurrentSort(newSort);

    const data = formData;
    data.orden = order(newSort);
    data.paginacion = pagination(1);
    API.obtenerReservaGestoria(data)
      .then((res) => {
        setResult(res || {});
        setPage(2);
        setLoading(false);
      })
      .catch((error) => API.DEVELOP && console.log(error));
  };

  const pagination = (page) => {
    return {
      pagina: page,
      cantidadRegistros: RESERVAGESTORIA_BY_PAGE,
    };
  };

  const order = (data = currentSort) => {
    return {
      campoOrden: data.field,
      direccion: data.direction,
    };
  };

  return (
    <S.Wrapper>
      {loading && <Loading />}

      <ModuleSearchReservaGestoria
        title="Añadir registros"
        metadata={METADATA_SEARCH_RESERVAGESTORIA}
        lists={lists}
        add={addReservaGestoria}
        allowSave={false}
        codReserva={result?.codReserva}
      />

      <ModuleResultAsignacionGestoria
        title="Resultados"
        metadata={METADATA_RESULT_RESERVAGESTORIA}
        data={result}
        moreResults={moreReservaGestoria}
        currentSort={currentSort}
        eliminar={deleteReservaGestoria}
        sort={sort}
      />
    </S.Wrapper>
  );
};
