import React, { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import * as S from "./styles";
import { InputText } from "../InputText";
import { InputList } from "../InputList";

import { Button } from "../Button";
import { InputListMultiple } from "../InputListMultiple";

export const ModuleSearchReservaGestoria = ({
  codReserva,
  title,
  metadata,
  lists,
  add,
}) => {
  const [values, setValues] = useState({});
  const [options, setOptions] = useState({});
  const [initValues, setInitValues] = useState({});

  const form = useRef();

  useEffect(() => {
    const values = {};
    const options = {};
    Object.entries(lists).forEach(([list, listProps]) => {
      options[list] = convertOptions(listProps.valores);
    });

    const setDefaultValue = (e) => {
      switch (e.type) {
        case "list":
          values[e.name] = lists[e.list]?.valorPorDefecto || "";
          break;
        case "readOnly":
          values[e.name] = codReserva;
          break;

        case "multiSelectList":
          values[e.name] = lists[e.list]?.valorPorDefecto.split(",")[0]
            ? lists[e.list]?.valorPorDefecto.split(",")
            : [];
          break;

        default:
          values[e.name] = "";
      }
    };

    metadata.forEach((props) => {
      if (props.name === "_group")
        props.fields.forEach((props) => setDefaultValue(props));
      else setDefaultValue(props);
    });

    setValues(values);
    setOptions(options);
    setInitValues(values);
  }, [metadata, lists,codReserva]);

  const handleChange = (prop, value) => {
    const tmpValues = { ...values };
    tmpValues[prop] = value;
    setValues(tmpValues);
  };

  const handleListChange = (field, value) => {
    const tmpValues = { ...values };
    tmpValues[field.name] = value;
 
    setValues(tmpValues);
  };

  const convertOptions = (options) =>
    options.map((opt) => ({
      value: opt.valor,
      label: opt.etiqueta,
    }));

 
  const addReservaGestoria = (e) => {
    e.preventDefault();
    add(values);
  };

  return (
    <S.Wrapper>
      <S.Header>
        <p className="first">{title}</p>
      </S.Header>
      <S.Content ref={form} id="form">
        {metadata.map((field) => {
          switch (field.type) {
            case "separator":
              return (
                <S.TextoSeparador key={field.name} colSpan={field.colSpan}>
                  <h2 className="bold">
                    <span>{field.label}</span>
                  </h2>
                </S.TextoSeparador>
              );
              case "multiSelectList":
                return (
                  <InputListMultiple
                    key={field.name}
                    field={field}
                    currentOptions={values[field.name] || []}
                    options={options[field.list] || []}
                    handleChange={handleListChange}
                    disabled={
                      (field.name === "bloqueTarea" && values.TipoTareaGestoria?.length ) 
                      ||
                      (field.name === "TipoTareaGestoria" && values.bloqueTarea?.length)
                    }
                  />
                );
            case "list":
            case "list-filter":
              return (
                <InputList
                  key={field.name}
                  field={field}
                  value={values[field.name] || ""}
                  options={options[field.list] || []}
                  handleChange={handleListChange}
                  isClearable={
                    field.type === "list-filter" ||
                    lists[field.list]?.mostrarVacio
                  }
                  disabled={
                    field.name === "gestoriaTarea" &&
                    (values.tipoTarea === "" ||
                      options.GestoriaTarea?.length === 0)
                  }
                />
              );
            case "readOnly":
              return (
                <S.ReadOnly key={field.name} colSpan={field.colSpan}>
                  <p className="bold">{field.label}</p>
                  <p>{values[field.name]}</p>
                </S.ReadOnly>
              );
            case "text":
            default:
              return (
                <InputText
                  key={field.name}
                  field={field}
                  value={values[field.name] || ""}
                  handleChange={handleChange}
                />
              );
          }
        })}
      </S.Content>
      <S.Buttons>
        <Button
          label="Añadir"
          onClick={addReservaGestoria}
          form="form"
          type="submit"
        />
        <Button
          type="cancel"
          label="Limpiar"
          onClick={() => {
            setValues(initValues);
          }}
        />
      </S.Buttons>
    </S.Wrapper>
  );
};

ModuleSearchReservaGestoria.propTypes = {
  add: PropTypes.func.isRequired,
  lists: PropTypes.object.isRequired,
  metadata: PropTypes.array.isRequired,
  title: PropTypes.any.isRequired,
};
