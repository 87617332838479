import React, { useState, useEffect } from "react";
import * as S from "./styles";
import * as API from "../../api";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Loading } from "../../components/Loading";
import { ModuleSearch } from "../../components/ModuleSearch";
import { ModuleResultReclamaciones } from "../../components/ModuleResultReclamaciones";
import { ModalTareaReclamacion } from "../../components/ModalTareaReclamacion";
import { Button } from "../../components/Button";
import {
  METADATA_SEARCH_RECLAMACION,
  METADATA_RESULT_RECLAMACION,
} from "../../constants";

export const TareaReclamacion = () => {
  const { idTarea } = useParams();
  const [tareaReclamacion, setTareaReclamacion] = useState({});
  const [tareaReclamacionFiltered, setTareaReclamacionFiltered] = useState({});
  const [filter, setFilter] = useState({});
  const [lists, setLists] = useState({});
  const [selectedIds, setSelectedIds] = useState([]);
  const [allSelected, setAllSelected] = useState(0);
  const [visibleModal, setVisibleModal] = useState("");
  const [loading, setLoading] = useState(true);
  const [documentName, setDocumentName] = useState("");
  useEffect(() => {
    let isMounted = true;

    API.obtenerTareaReclamacion({ idTarea })
      .then((res) => {
        if (isMounted) {
          setTareaReclamacion(res);
          setTareaReclamacionFiltered(res);
          API.obtenerListasReclamacion({
            idTipoDoc: res.idTipoDocumento,
            idCartera: "0",
          })
            .then((res) => isMounted && setLists(res))
            .catch((error) => API.DEVELOP && console.log(error));
        }
      })
      .catch((error) => API.DEVELOP && console.log(error))
      .finally(() => setLoading(false));

    return () => (isMounted = false);
  }, [idTarea]);

  const handleChangeSelection = (e) => {
    const { id, checked } = e.target;
    let tmpSelectedIds = [...selectedIds];
    if (checked) {
      tmpSelectedIds.push(id);
    } else {
      tmpSelectedIds = tmpSelectedIds.filter((e) => e !== id);
    }
    setSelectedIds(tmpSelectedIds);

    const totalRegistros = tareaReclamacionFiltered.reclamaciones.length;
    tmpSelectedIds.length !== totalRegistros && setAllSelected(2);
    tmpSelectedIds.length === totalRegistros && setAllSelected(1);
    tmpSelectedIds.length === 0 && setAllSelected(0);
    if (tmpSelectedIds.length === 1) {
      let reclamacionSelected = tareaReclamacion.reclamaciones.find(
        (a) => a.idTarea === tmpSelectedIds[0]
      );
      if (typeof reclamacionSelected !== "undefined") {
        setDocumentName(reclamacionSelected.nombreDocumento);
      }
    } else {
      setDocumentName("");
    }
  };

  const handleChangeAllSelection = (e) => {
    const { checked } = e.target;
    setAllSelected(checked ? 1 : 0);
    if (checked) {
      let tmpSelectedIds = tareaReclamacionFiltered.reclamaciones.map(
        (reclamacion) => reclamacion.idTarea
      );
      setSelectedIds(tmpSelectedIds);
      if (tmpSelectedIds.length === 1) {
        let reclamacionSelected = tareaReclamacion.reclamaciones.find(
          (a) => a.idTarea === tmpSelectedIds[0]
        );
        if (typeof reclamacionSelected !== "undefined") {
          setDocumentName(reclamacionSelected.nombreDocumento);
        }
      } else {
        setDocumentName("");
      }
    } else {
      setSelectedIds([]);
      setDocumentName("");
    }
  };

  const complete = (fields) => {
    setLoading(true);
    setVisibleModal("");
    const data = { ...fields };
    data.idTareas = selectedIds;
    API.completarTareaReclamacion(data)
      .then((res) => {
        API.obtenerTareaReclamacion({ idTarea })
          .then((res) => {
            setTareaReclamacion(res);
            filtrarReclamaciones(filter, res);
          })
          .catch((error) => API.DEVELOP && console.log(error))
          .finally(() => {
            setLoading(false);
          });
        if (res.resultadoPeticion === 1) {
          toast.success("Tarea(s) completada(s)");
        } else {
          toast.error(res.mensajeUsuario);
        }
      })
      .catch((error) => {
        toast.error("No se pudo completar la(s) tarea(s)");
        setLoading(false);
        API.DEVELOP && console.log(error);
      });
  };

  const filtrarReclamaciones = (filter, data = tareaReclamacion) => {
    setFilter(filter);
    setAllSelected(0);
    setSelectedIds([]);
    let tmpTareaReclamacion = { ...data };
    tmpTareaReclamacion.reclamaciones =
      tmpTareaReclamacion.reclamaciones.filter((reclamacion) => {
        return METADATA_SEARCH_RECLAMACION.every((field) => {
          if (!filter[field.name]) return true;
          if (typeof reclamacion[field.name] === "object") {
            return reclamacion[field.name].valor === filter[field.name];
          } else {
            return reclamacion[field.name]
              .toLowerCase()
              .includes(filter[field.name].toLowerCase());
          }
        });
      });
    setTareaReclamacionFiltered(tmpTareaReclamacion);
  };

  const ordenarReclamaciones = (data) => {
    let tmpTareaReclamacion = { ...tareaReclamacion };
    const compareAsc = (a, b) => (a[data.field] > b[data.field] ? 1 : -1);
    const compareDesc = (a, b) => (a[data.field] > b[data.field] ? -1 : 1);
    tmpTareaReclamacion.reclamaciones = tareaReclamacion.reclamaciones.sort(
      data.direction === 1 ? compareAsc : compareDesc
    );
    setTareaReclamacionFiltered(tmpTareaReclamacion);
  };

  const renderModal = () => {
    switch (visibleModal) {
      case "confirm":
        return (
          <ModalTareaReclamacion
            type={visibleModal}
            title="Confirmación"
            labelConfirm="Sí, Continuar"
            numSelected={selectedIds.length.toString()}
            lists={{}}
            confirm={() => setVisibleModal(tareaReclamacion.desTipoDocumento)}
            cancel={() => setVisibleModal("")}
          />
        );

      default:
        return (
          <ModalTareaReclamacion
            type={visibleModal}
            title={`Completar - ${visibleModal}`}
            labelConfirm="Completar"
            lists={lists}
            data={
              selectedIds.length === 1
                ? tareaReclamacionFiltered.reclamaciones.find((i)=>i.idTarea === selectedIds[0])
                : null
            }
            confirm={complete}
            cancel={() => setVisibleModal("")}
            documentName={documentName}
          />
        );
    }
  };

  return (
    <S.Wrapper>
      {loading && <Loading />}
      {visibleModal && renderModal()}

      <ModuleSearch
        title="Filtro"
        metadata={METADATA_SEARCH_RECLAMACION}
        lists={lists || {}}
        submit={filtrarReclamaciones}
        listFilters={[]}
        setCurrentFilter={() => null}
        allowSave={false}
      />

      <ModuleResultReclamaciones
        title="Tareas de Reclamación"
        metadata={METADATA_RESULT_RECLAMACION}
        data={tareaReclamacionFiltered}
        selectedIds={selectedIds}
        allSelected={allSelected}
        handleChangeSelection={handleChangeSelection}
        handleChangeAllSelection={handleChangeAllSelection}
        sorting={ordenarReclamaciones}
      />

      <S.Buttons>
        <Button
          label="Completar"
          onClick={() => setVisibleModal("confirm")}
          disabled={selectedIds.length === 0}
        />
      </S.Buttons>
    </S.Wrapper>
  );
};
