import styled from "styled-components/macro";
import { COLORES } from "../../constants";

export const Wrapper = styled.div`
  width: 100%;
  background-color: ${COLORES.DEFAULT_MODULE_BACKGROUND};
  margin-bottom: 2rem;
  border-radius: 0.25rem;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Header = styled.div`
  width: 100%;
  height: 2rem;
  color: ${COLORES.DEFAULT_MODULE_HEADER_FONT};
  background-color: ${COLORES.DEFAULT_MODULE_HEADER_BACKGROUND};
  font-weight: bold;
  border-radius: 0.25rem 0.25rem 0 0;

  p {
    line-height: 2rem;
    padding-left: 1rem;
  }
`;

export const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1.5rem 0;
`;

export const Names = styled.div`
  width: ${(props) => props.divWidth};

  display: flex;
  flex-direction: row;
  align-items: center;
  div {
    flex: 1 0 0;
    text-align: center;
    p.highlighted {
      font-weight: bold;
    }
  }
`;

export const States = styled.div`
  width: ${(props) => props.divWidth};
  height: ${(props) => props.divHeight};

  svg {
    width: 100%;
    overflow: visible;

    text {
      font-size: 10;
      font-weight: bold;
    }
    circle,
    text {
      cursor: pointer;
    }
  }
`;

export const StateInfo = styled.div`
  width: ${(props) => props.divWidth};

  display: flex;
  flex-direction: row;
  align-items: center;
  div {
    flex: 1 0 0;
    padding: 0.5rem;

    svg {
      margin-right: 0.25rem;
    }
    p.title {
      font-weight: bold;
      text-align: center;
      margin-bottom: 0.5rem;
    }
    p {
      text-align: left;
      margin: 0.1rem 0;
    }

    &.opened {
      border: thin solid grey;
      border-radius: 0.2rem;
    }

    div#documentos {
      padding: 0;
      svg {
        margin-left: 0.25rem;
        color: ${COLORES.DEFAULT_MODULE_HEADER_BACKGROUND};
        cursor: pointer;
      }
    }
  }
`;
