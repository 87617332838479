import React, { useState, useEffect } from "react";
import * as S from "./styles";
import * as API from "../../api";
import { useParams, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { ModuleTareaDatosGenerales } from "../../components/ModuleTareaDatosGenerales";
import { ModuleTareaDatosFormalizacion } from "../../components/ModuleTareaDatosFormalizacion";
import { ModalConfirmacionFirma } from "../../components/ModalConfirmacionFirma";
import { Button } from "../../components/Button";
import { Loading } from "../../components/Loading";
import {
  METADATA_CONFIRMACION_FIRMA_DATOS_GENERALES,
  METADATA_CONFIRMACION_FIRMA_DATOS_FORMALIZACION,
  METADATA_DOCS_RESERVA,
  ESTADO_CONFIRMACION_FIRMA_CONFIRMADA,
  ESTADO_CONFIRMACION_FIRMA_APLAZADA_SIN_FECHA,
  ESTADO_CONFIRMACION_FIRMA_APLAZADA_CON_FECHA,
} from "../../constants";
import { ModuleTareaDocsReserva } from "../../components/ModuleTareaDocsReserva";
import { usePermissions } from "../../context/PermissionsContext";

export const ConfirmacionFirma = () => {
  const { permissions } = usePermissions();
  const { idTarea } = useParams();
  const navigate = useNavigate();
  const [lists, setLists] = useState({});
  const [datosGenerales, setDatosGenerales] = useState({});
  const [datosFormalizacion, setDatosFormalizacion] = useState({});
  const [docsReserva, setDocsReserva] = useState([]);
  const [triggerRefresh, setTriggerRefresh] = useState(false);
  const [loading, setLoading] = useState(true);
  const [values, setValues] = useState({});
  const [hiddens, setHiddens] = useState({});
  const [options, setOptions] = useState({});
  const [completar, setCompletar] = useState(false);
  const [datosFecPrev, setDatosFecPrev] = useState({});
  const [visibleModal, setVisibleModal] = useState("");
  useEffect(() => {
    let isMounted = true;
    API.abrirTarea({ tarea: idTarea })
      .then((res) => {
        if (isMounted) {
          setDatosGenerales(res.datosGenerales);
          setDatosFormalizacion(res.datosFormalizacion);
          setDocsReserva(res.documentosReserva);
        }
      })
      .catch((error) => API.DEVELOP && console.log(error))
      .finally(() => setLoading(false));

    API.obtenerListas("ConfirmacionFirma", 0)
      .then((res) => isMounted && setLists(res))
      .catch((error) => API.DEVELOP && console.log(error));

    return () => (isMounted = false);
  }, [idTarea]);

  useEffect(() => {
    if (triggerRefresh) {
      API.abrirTarea({ tarea: idTarea }).then((res) => {
        setDatosFormalizacion(res.datosFormalizacion);
        setDocsReserva(res.documentosReserva);
      });
      setTriggerRefresh(false);
    }
  }, [idTarea, triggerRefresh]);

  useEffect(() => {
    const options = {};
    const values = {};
    const hiddens = {};
    Object.entries(lists).forEach(([list, listProps]) => {
      options[list] = convertOptions(listProps.valores);
    });
    const setInitialValue = (e) => {
      switch (e.type) {
        case "listEstadoFirma":
          values[e.name] = datosFormalizacion[e.name]?.valor || "";
          break;
        case "textarea":
        case "text":
        case "date":
        case "datetime-local":
          values[e.name] = datosFormalizacion[e.name] || "";
          break;
        default:
          values[e.name] = "";
      }
    };

    METADATA_CONFIRMACION_FIRMA_DATOS_FORMALIZACION.forEach((props) => {
      if (props.name === "_group")
        props.fields.forEach((props) => setInitialValue(props));
      else setInitialValue(props);
    });
    if (datosFormalizacion["estadosFirma"]) {
      switch (datosFormalizacion["estadosFirma"]?.valor) {
        case ESTADO_CONFIRMACION_FIRMA_CONFIRMADA:
          hiddens["motivoAplazado"] = true;
          hiddens["fecPrevistaFirma"] = true;
          hiddens["fechaConfirmacionFirma"] = false;
          break;
        case ESTADO_CONFIRMACION_FIRMA_APLAZADA_CON_FECHA:
          hiddens["motivoAplazado"] = false;
          hiddens["fecPrevistaFirma"] = false;
          hiddens["fechaConfirmacionFirma"] = true;
          break;
        case ESTADO_CONFIRMACION_FIRMA_APLAZADA_SIN_FECHA:
          hiddens["motivoAplazado"] = false;
          hiddens["fecPrevistaFirma"] = true;
          hiddens["fechaConfirmacionFirma"] = true;
          break;
        default:
          hiddens["motivoAplazado"] = true;
          hiddens["fecPrevistaFirma"] = true;
          hiddens["fechaConfirmacionFirma"] = true;
          break;
      }
    } else {
      hiddens["motivoAplazado"] = true;
      hiddens["fecPrevistaFirma"] = true;
      hiddens["fechaConfirmacionFirma"] = true;
    }
    setValues(values);
    setOptions(options);
    setHiddens(hiddens);
  }, [lists, datosFormalizacion]);

  const convertOptions = (options) =>
    options.map((opt) => ({
      value: opt.valor,
      label: opt.etiqueta,
    }));

  const especificarMotivoCambioFechaPrevista = (data) => {
    setVisibleModal("");
    const tmpValues = { ...values };
    tmpValues["observacionesMotivoCambio"] = data.observacionesMotivoCambio;
    tmpValues["llegadaDeApoderado"] = data.llegadaDeApoderado;
    tmpValues["tipoAplazamiento"] = data.tipoAplazamiento;
    setValues(tmpValues);
    GuardarCompletarTareaConfirmacionFirma(completar, false, data);
  };

  const GuardarCompletarTareaConfirmacionFirma = (
    esCompletar,
    crearTareaFiscalidad,
    dataFechaPrev = datosFecPrev,
    fiscalidadSolicitada = false
  ) => {
    if (dataFechaPrev !== null) setDatosFecPrev(dataFechaPrev);
    else dataFechaPrev = datosFecPrev;

    let fn = values["fecPrevistaFirma"] ?? "";
    if (fn && fn != null) fn = values["fecPrevistaFirma"].split("T").shift();
    setCompletar(esCompletar);
    if (
      fn != null &&
      values["estadosFirma"] !== ESTADO_CONFIRMACION_FIRMA_CONFIRMADA &&
      visibleModal !== "solicitarMotivoCambioFechaFirma" &&
      fiscalidadSolicitada === false &&
      values["fechaConfirmacionFirma"] !== fn
    ) {
      setVisibleModal("solicitarMotivoCambioFechaFirma");
    } else {
      if (
        esCompletar === true &&
        values["estadosFirma"] ===
          ESTADO_CONFIRMACION_FIRMA_APLAZADA_CON_FECHA &&
        visibleModal !== "solicitarTipoFiscalidad"
      ) {
        setVisibleModal("solicitarTipoFiscalidad");
      } else {
        if (values["fechaConfirmacionFirma"] === fn.toString())
          values["fecPrevistaFirma"] = null;

        setLoading(true);
        setVisibleModal("");
        API.guardarCompletarTareaConfirmacionFirma({
          IdTarea: idTarea,
          Completar: esCompletar,
          EstadosFirma: values["estadosFirma"],
          MotivoAplazado: values["motivoAplazado"],
          FechaConfirmacionFirma: values["fechaConfirmacionFirma"],
          FecPrevistaFirma: values["fecPrevistaFirma"],
          CrearTareaFiscalidad: crearTareaFiscalidad,
          ObservacionesMotivoCambio:
            dataFechaPrev?.observacionesMotivoCambio ?? "",
          TipoAplazamiento: dataFechaPrev?.tipoAplazamiento ?? null,
          LlegadaDeApoderado: dataFechaPrev?.llegadaDeApoderado ?? null,
        })
          .then((res) => {
            if (res.resultadoPeticion === 1 && esCompletar === true) {
              toast.success("Tarea Completada");
              navigate("/");
            } else if (res.resultadoPeticion === 1 && esCompletar === false) {
              toast.success("Tarea Guardada");
              setTriggerRefresh(true);
            } else toast.error(res.mensajeUsuario);
          })
          .catch((error) => API.DEVELOP && console.log(error))
          .finally(() => setLoading(false));
      }
    }
  };

  const renderModal = () => {
    switch (visibleModal) {
      case "solicitarMotivoCambioFechaFirma":
        return (
          <ModalConfirmacionFirma
            type={visibleModal}
            title="Especificar motivo aplazamiento confirmación firma"
            labelConfirm="Guardar"
            lists={lists}
            confirm={especificarMotivoCambioFechaPrevista}
            cancel={() => setVisibleModal("")}
          />
        );
      case "solicitarTipoFiscalidad":
        return (
          <ModalConfirmacionFirma
            type={visibleModal}
            title="Completar Confirmación Firma"
            labelConfirm="Solicitar"
            confirm={() =>
              GuardarCompletarTareaConfirmacionFirma(true, true, null, true)
            }
            cancel={() =>
              GuardarCompletarTareaConfirmacionFirma(true, false, null, true)
            }
          />
        );

      default:
        return null;
    }
  };

  return (
    <S.Wrapper>
      {loading && <Loading />}
      {visibleModal && renderModal()}
      <ModuleTareaDatosGenerales
        data={datosGenerales}
        metadata={METADATA_CONFIRMACION_FIRMA_DATOS_GENERALES}
      />
      <ModuleTareaDatosFormalizacion
        data={datosFormalizacion}
        metadata={METADATA_CONFIRMACION_FIRMA_DATOS_FORMALIZACION}
        lists={lists}
        columns={6}
        values={values}
        setValues={setValues}
        options={options}
        setOptions={setOptions}
        hiddens={hiddens}
        setHiddens={setHiddens}
        access={permissions.ConfirmacionFirma}
      />
      <ModuleTareaDocsReserva
        data={docsReserva}
        metadata={METADATA_DOCS_RESERVA}
        refreshDocs={() => setTriggerRefresh(true)}
        setLoading={setLoading}
      />
      <S.Buttons>
        <a
          href={`/FichaReserva/${datosGenerales.idReserva}`}
          target="_blank"
          rel="noreferrer"
        >
          <Button label="Ver Ficha" disabled={!datosGenerales.idReserva} />
        </a>
        <div>
          <Button
            label="Guardar"
            onClick={() => GuardarCompletarTareaConfirmacionFirma(false, false)}
            disabled={!permissions.ConfirmacionFirma?.Editar}
          />
          <Button
            label="Completar"
            onClick={() => GuardarCompletarTareaConfirmacionFirma(true, false)}
            disabled={!permissions.ConfirmacionFirma?.Completar}
          />
        </div>
      </S.Buttons>
    </S.Wrapper>
  );
};
