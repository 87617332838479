import React, { useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import * as S from "./styles";
import * as API from "../../api";
import { Button } from "../Button";
import { InputList } from "../InputList";
import { InputText } from "../InputText";
import { InputDate } from "../InputDate";
import {
  METADATA_MODAL_TAREA_ANALISIS_CANCELACION,
  METADATA_MODAL_TAREA_ANALISIS_FONDOS,
  METADATA_MODAL_TAREA_ANALISIS_TITULARIDAD,
} from "../../constants";
import { InputNumber } from "../InputNumber";

export const ModalTareaAnalisis = ({
  type,
  title,
  labelConfirm,
  lists,
  confirm,
  cancel,
  idreserva,
  cargas,
}) => {
  const [listsProps, setListsProps] = useState({});
  const [observations, setObservations] = useState("");
  const [values, setValues] = useState({});

  const initLists = useCallback(() => {
    const tmpListsProps = {};
    Object.entries(lists).forEach(([listName, listProps]) => {
      tmpListsProps[listName] = {
        value: listProps.valorDefecto,
        options: convertOptions([...listProps.valores]),
      };
    });
    setListsProps(tmpListsProps);
  }, [lists]);
  useEffect(() => initLists(), [initLists]);

  const handleChange = (field, value) => {
    const tmpValues = { ...values };
    tmpValues[field] = value;
    setValues(tmpValues);
  };


  const handleListChange = (field, value) => {
    const tmpListsProps = { ...listsProps };
      tmpListsProps[field.list].value = value;

    if (lists[field.list].listasHijas.length === 0)
    {
      setListsProps(tmpListsProps);
    }
    else
    {
      lists[field.list].listasHijas.forEach((list) => {
        const listaRelacional = {
          nombreLista: field.list,
          idEntidadRelacional: value,
        };
        const reserva = idreserva;      
        const data = {
          lista: list,
          listaRelacional,
          reserva,
        };
        API.obtenerLista(data).then((res) => {
          tmpListsProps[list].options = convertOptions(res.valores);
          setListsProps(tmpListsProps);
        });
      });
    }
    const objetoEncontrado = cargas.find(item => item.idActivo === listsProps?.ActivosCancelacion?.value && item.idTipoCargas === listsProps?.TipoTareaCancelado?.value);
     if(objetoEncontrado)
       {
        values.fechaPrevision=objetoEncontrado.fechaPrevision;
       }
  };

  const convertOptions = (options) =>
    options.map((opt) => ({
      value: opt.valor,
      label: opt.etiqueta,
    }));

    const body = () => {
      switch (type) {
      case "addNewCarga":
        return (
          <S.WrapperModalBody columns={1}>
            {lists.ActivosCancelacion?.valores.length > 0 ? (
              METADATA_MODAL_TAREA_ANALISIS_CANCELACION.map((field) => {
                if (field.name !== 'idSubCarga' || (field.name === 'idSubCarga' && listsProps[field.list]?.options.length !== 0)) {
                  switch (field.type) {
                    case "list":
                      return (
                        <InputList
                          key={field.name}
                          field={field}
                          value={listsProps[field.list]?.value || ""}
                          options={listsProps[field.list]?.options || []}
                          handleChange={handleListChange}
                          isClearable={lists[field.list]?.mostrarVacio || false}
                        />
                      );
                      case "date":
                          return (
                           
                          <InputDate
                            key={field.name}
                            field={field}
                            value={values[field.name] || ""}
                            handleChange={handleChange}
                          />
                        );
                    default:
                      return <InputText key={field.name} field={field} />;
                  }

                }
                else {
                  return null;

                }
              })
            ) : (
              <p>No hay activos</p>
            )}
          </S.WrapperModalBody>
        );

      case "addFondoReserva":
        return (
          <S.WrapperModalBody columns={1}>
            {lists.Fondos?.valores.length > 0 ? (
              METADATA_MODAL_TAREA_ANALISIS_FONDOS.map((field) => {
                switch (field.type) {
                  case "list":
                    return (
                      <InputList
                        key={field.name}
                        field={field}
                        value={listsProps[field.list]?.value || ""}
                        options={listsProps[field.list]?.options || []}
                        handleChange={handleListChange}
                        isClearable={lists[field.list]?.mostrarVacio || false}
                      />
                    );
                  case "number":
                    return (
                      <InputNumber
                      key={field.name}
                      field={field}
                      value={values[field.name] || ""}
                      handleChange={handleChange}
                      />
                    );

                  default:
                    return <InputText key={field.name} field={field} />;
                }
              })
            ) : (
              <p>No hay fondos</p>
            )}
          </S.WrapperModalBody>
        );


      case "addNewTitularidad":
        return (
          <S.WrapperModalBody columns={1}>
            {lists.ActivosTitularidad?.valores.length > 0 ? (
              METADATA_MODAL_TAREA_ANALISIS_TITULARIDAD.map((field) => {
                switch (field.type) {
                  case "list":
                    return (
                      <InputList
                        key={field.name}
                        field={field}
                        value={listsProps[field.list]?.value || ""}
                        options={listsProps[field.list]?.options || []}
                        handleChange={handleListChange}
                        isClearable={lists[field.list]?.mostrarVacio || false}
                      />
                    );
                    case "date":
                        return (
                            <InputDate
                                key={field.name}
                                field={field}
                                value={values[field.name] || ""}
                                handleChange={handleChange}
                            />
                        );
                  default:
                    return <InputText key={field.name} field={field} />;
                }
              })
            ) : (
              <p>No hay activos</p>
            )}
          </S.WrapperModalBody>
        );

      case "cancelCarga":
        return (
          <S.WrapperModalBody columns={1}>
            <h3>¿Está seguro que quiere cancelar la tarea seleccionada?</h3>
            <div>Debe agregar el motivo de la cancelación</div>
            <input onChange={(e) => setObservations(e.target.value)} />
          </S.WrapperModalBody>
        );

      case "confirmarBorradoTarea":
        return (
          <S.WrapperModalBody columns={1}>
            <h3>
              ¿Desea borrar el documento y su tarea correspondiente?
            </h3>
          </S.WrapperModalBody>
        );


      default:
        return <p>Unknown type</p>;
    }
  };

  const confirmAddCarga = (list) => {
    const data = {};
    data.idActivo = listsProps[list].value;
    data.idTipoCarga = idTipoCarga();
    data.idSubTipoCarga = idSubTipoCarga();
    data.fechaPrevision = values?.fechaPrevision;
    confirm(data);
    };

  const idTipoCarga = () => {
    switch (type) {
      case "addNewCarga":
        return listsProps.TipoTareaCancelado.value;

      case "addNewTitularidad":
        return listsProps.TipoTareaTitularidad.value;

      default:
        return null;
    }
  };
  const idSubTipoCarga = () => {
    switch (type) {
      case "addNewCarga":
        return listsProps.SubTipoTareaCancelado.value;


      default:
        return null;
    }
  };
  const confirmCancelCarga = () => {
    const data = {};
    data.observaciones = observations;
    confirm(data);
  };
  const confirmAddFondo = () => {
    const data = {};
    data.campo = listsProps.Fondos.value;
    data.valor = values["importeFondo"];
    confirm(data);
  };

  const buttons = () => {
    switch (type) {
      case "addNewCarga":
        return lists.ActivosCancelacion?.valores.length > 0 ? (
          <>
            <Button
              label={labelConfirm}
              onClick={() => confirmAddCarga("ActivosCancelacion")}
              disabled={
                !listsProps.ActivosCancelacion?.value ||
                !listsProps.TipoTareaCancelado?.value  || 
                !(listsProps.TipoTareaCancelado?.value === "1" ||  values.fechaPrevision )
              }
            />
            <Button label="Cancelar" onClick={cancel} type="cancel" />
          </>
        ) : (
          <Button label="Aceptar" onClick={cancel} type="cancel" />
        );

      case "addNewTitularidad":
        return lists.ActivosTitularidad?.valores.length > 0 ? (
          <>
            <Button
              label={labelConfirm}
              onClick={() => confirmAddCarga("ActivosTitularidad")}
              disabled={
                !listsProps.ActivosTitularidad?.value ||
                !listsProps.TipoTareaTitularidad?.value ||
                !( listsProps.TipoTareaTitularidad?.value === "4" || values.fechaPrevision)
              }
            />
            <Button label="Cancelar" onClick={cancel} type="cancel" />
          </>
        ) : (
          <Button label="Aceptar" onClick={cancel} type="cancel" />
        );

      case "addFondoReserva":
        return (
          <>
            <Button
              label={labelConfirm}
              onClick={confirmAddFondo}
              disabled={
                !listsProps.Fondos?.value ||
                !values["importeFondo"]
              }
            />
            <Button label="Cancelar" onClick={cancel} type="cancel" />
          </>
        );

      case "cancelCarga":
        return (
          <>
            <Button
              label={labelConfirm}
              onClick={confirmCancelCarga}
              disabled={observations.length < 3}
            />
            <Button label="Cancelar" onClick={cancel} type="cancel" />
          </>
        );
      case "confirmarBorradoTarea":
        return (
          <>
            <Button
              label={labelConfirm}
              onClick={confirmCancelCarga} />
            <Button label="Cancelar" onClick={cancel} type="cancel" />
          </>
        );

      default:
        return <Button label="Cancelar" onClick={cancel} type="cancel" />;
    }
  };

  return (
    <S.Wrapper onClick={cancel}>
      <S.Content onClick={(event) => event.stopPropagation()}>
        <S.Header>
          <p>{title}</p>
        </S.Header>
        <S.Body>
          {body()}
          <S.Buttons>{buttons()}</S.Buttons>
        </S.Body>
      </S.Content>
    </S.Wrapper>
  );
};

ModalTareaAnalisis.propTypes = {
  type: PropTypes.string,
  title: PropTypes.string,
  labelConfirm: PropTypes.string,
  lists: PropTypes.object,
  confirm: PropTypes.func,
  cancel: PropTypes.func,
  idreserva: PropTypes.string,
  cargas:PropTypes.object,
};
