import React from "react";
import PropTypes from "prop-types";
import * as S from "./styles";

export const Button = ({ label, type, onClick, disabled, form }) => {
  return (
    <S.Wrapper>
      <button
        className={type}
        onClick={onClick}
        disabled={disabled}
        form={form}
      >
        {label}
      </button>
    </S.Wrapper>
  );
};

Button.propTypes = {
  label: PropTypes.string.isRequired,
  type: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  form: PropTypes.string,
};
