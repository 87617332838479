import React, { useState } from "react";
import PropTypes from "prop-types";
import * as S from "./styles";
import { InputList } from "../InputList";
import { Button } from "../Button";

export const Modal = ({
  type,
  title,
  message,
  labelConfirm,
  values,
  summary,
  confirm,
  cancel,
}) => {
  const [user, setUser] = useState("");
  const [newFilterName, setNewFilterName] = useState("");

  const TablaCambios = () => {
    return (
      <table>
        <tbody>
          {Object.entries(summary).map(([name, count]) => (
            <tr key={name}>
              <td>{name}</td>
              <td>{count}</td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  };

  const body = () => {
    switch (type) {
      case "asignTarea":
      case "reabrirTarea":
      case "deleteFilter":
        return <p className="centered">{message}</p>;

      case "massiveAsignTarea":
        return (
          <>
            <p className="centered">
              Detalle de tareas seleccionadas por el usuario:
            </p>
            <TablaCambios />
          </>
        );
      case "reasignTarea":
      case "massiveReasignGestoriaProvincia":
        return (
          <InputList
            field={{ label: message }}
            value={user || ""}
            options={convertOptions(values) || []}
            handleChange={(_field, value) => setUser(value)}
            isClearable={false}
          />
        );
     

      case "massiveReasignTarea":
        return (
          <>
            <p className="centered">
              Detalle de tareas seleccionadas por el usuario:
            </p>
            <TablaCambios />
            <InputList
              field={{ label: message }}
              value={user || ""}
              options={convertOptions(values) || []}
              handleChange={(_field, value) => setUser(value)}
              isClearable={false}
            />
          </>
        );
      case "saveFilter":
        return (
          <>
            <p>{message}</p>
            <input
              autoFocus={true}
              value={newFilterName}
              onChange={(e) => setNewFilterName(e.target.value)}
            />
          </>
        );

      default:
        return <p>{message}</p>;
    }
  };

  const convertOptions = (options) =>
    options.map((opt) => ({ value: opt.valor, label: opt.etiqueta }));

  const buttons = () => {
    switch (type) {
      case "asignTarea":
      case "reabrirTarea":
      case "deleteFilter":
        return (
          <>
            <Button label={labelConfirm} onClick={confirm} />
            <Button label="Cancelar" onClick={cancel} type="cancel" />
          </>
        );
      case "massiveAsignTarea":
        return (
          <>
            <Button label={labelConfirm} onClick={() => confirm()} />
            <Button label="Cancelar" onClick={cancel} type="cancel" />
          </>
        );

      case "reasignTarea":
      case "massiveReasignGestoriaProvincia":
        return (
          <>
            <Button
              label={labelConfirm}
              onClick={() => confirm(user)}
              disabled={user === ""}
            />
            <Button label="Cancelar" onClick={cancel} type="cancel" />
          </>
        );

      case "massiveReasignTarea":
        return (
          <>
            <Button
              label={labelConfirm}
              onClick={() => confirm(user)}
              disabled={user === ""}
            />
            <Button label="Cancelar" onClick={cancel} type="cancel" />
          </>
        );

      case "saveFilter":
        return (
          <>
            <Button
              label={labelConfirm}
              onClick={() => confirm(newFilterName)}
              disabled={newFilterName === ""}
            />
            <Button label="Cancelar" onClick={cancel} type="cancel" />
          </>
        );

      default:
        return <Button label="Cancelar" onClick={cancel} type="cancel" />;
    }
  };

  return (
    <S.Wrapper onClick={cancel}>
      <S.Content onClick={(event) => event.stopPropagation()}>
        <S.Header>
          <p>{title}</p>
        </S.Header>
        <S.Body>
          {body()}
          <S.Buttons>{buttons()}</S.Buttons>
        </S.Body>
      </S.Content>
    </S.Wrapper>
  );
};

Modal.propTypes = {
  type: PropTypes.string,
  title: PropTypes.string,
  message: PropTypes.string,
  labelConfirm: PropTypes.string,
  values: PropTypes.array,
  summary: PropTypes.object,
  confirm: PropTypes.func,
  cancel: PropTypes.func,
};
