import React from "react";
import PropTypes from "prop-types";
import * as S from "./styles";

export const RowResultReclamaciones = ({
  metadata,
  data,
  selected,
  handleChangeSelection,
  requestAsignTarea,
  requestReasignTarea,
}) => {
  return (
    <S.Tr>
      <td>
        <input
          type="checkbox"
          id={data.idTarea}
          checked={selected}
          onChange={handleChangeSelection}
        />
      </td>
      {metadata.map((e) => {
        return (
          <td key={e.name}>
            {typeof data[e.name] === "object"
              ? data[e.name]?.etiqueta
              : data[e.name]}
          </td>
        );
      })}
    </S.Tr>
  );
};

RowResultReclamaciones.propTypes = {
  metadata: PropTypes.array.isRequired,
  data: PropTypes.object.isRequired,
  selected: PropTypes.bool,
  handleChangeSelection: PropTypes.func,
  requestAsignTarea: PropTypes.func,
  requestReasignTarea: PropTypes.func,
};
