import { Buffer } from "buffer";
const { REACT_APP_ENCRYPT_KEY, REACT_APP_ENCRYPT_IV } = process.env;

export default class Encrypt {
  static encrypt = async (message) => {
    const encodedMessage = this.getEncoding(message);
    const iv = this.getEncoding(REACT_APP_ENCRYPT_IV);
    const stringKey = this.getEncoding(REACT_APP_ENCRYPT_KEY);
    const key = await this.generateKey(stringKey);

    const ciphertext = await window.crypto.subtle.encrypt(
      {
        name: "AES-CBC",
        iv,
      },
      key,
      encodedMessage
    );

    const encryptedMessage = Buffer.from(ciphertext).toString("base64");
    return encryptedMessage;
  };

  static decrypt = async (encryptedMessage) => {
    const iv = this.getEncoding(REACT_APP_ENCRYPT_IV);
    const manual_key = this.getEncoding(REACT_APP_ENCRYPT_KEY);
    const key = await this.generateKey(manual_key);

    const buffferMessage = Buffer.from(encryptedMessage, "base64");
    const decrypted = await window.crypto.subtle.decrypt(
      {
        name: "AES-CBC",
        iv,
      },
      key,
      buffferMessage
    );

    const decryptedMessage = this.getDecoding(decrypted);
    return decryptedMessage;
  };

  static getEncoding = (message) => {
    const enc = new TextEncoder();
    return enc.encode(message);
  };

  static getDecoding = (message) => {
    const enc = new TextDecoder();
    return enc.decode(message);
  };

  static generateKey = async (stringKey) => {
    return await window.crypto.subtle.importKey(
      "raw",
      stringKey,
      {
        name: "AES-CBC",
        length: 256,
      },
      true,
      ["encrypt", "decrypt"]
    );
  };
}
