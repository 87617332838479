import React, { useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import * as S from "./styles";
import * as API from "../../api";
import { Button } from "../Button";
import { InputList } from "../InputList";
import { InputText } from "../InputText";
import { METADATA_MODAL_REPORTE_GESTORIA_CHEQUES } from "../../constants";
import { InputNumber } from "../InputNumber";

export const ModalReporteGestoria = ({
  type,
  title,
  document,
  labelConfirm,
  lists,
  confirm,
  cancel,
}) => {
  const [listsProps, setListsProps] = useState({});
  const [filename, setFilename] = useState("");
  const [data64File, setData64File] = useState("");
  const [fileExt, setFileExt] = useState("");
  const [values, setValues] = useState({});

  const initLists = useCallback(() => {
    const tmpListsProps = {};
    Object.entries(lists).forEach(([listName, listProps]) => {
      tmpListsProps[listName] = {
        value: listProps.valorDefecto,
        options: convertOptions([...listProps.valores]),
      };
    });
    setListsProps(tmpListsProps);
  }, [lists]);
  useEffect(() => initLists(), [initLists]);

  const handleChange = (field, value) => {
    const tmpValues = { ...values };
    tmpValues[field] = value;
    setValues(tmpValues);
  };

  const handleListChange = (field, value) => {
    const tmpListsProps = { ...listsProps };
    tmpListsProps[field.list].value = value;

    if (lists[field.list].listasHijas.length === 0)
      setListsProps(tmpListsProps);
    else
      lists[field.list].listasHijas.forEach((list) => {
        const listaRelacional = {
          nombreLista: field.list,
          idEntidadRelacional: value,
        };
        const data = {
          lista: list,
          listaRelacional,
        };
        API.obtenerLista(data).then((res) => {
          tmpListsProps[list].options = convertOptions(res.valores);
          setListsProps(tmpListsProps);
        });
      });
  };

  const convertOptions = (options) =>
    options.map((opt) => ({
      value: opt.valor,
      label: opt.etiqueta,
    }));

  const body = () => {
    switch (type) {
      case "addCheque":
        return (
          <S.WrapperModalBody columns={2}>
            <InputList
              field={{
                label: "Tipo Documento",
                name: "tipoDocumentoCheque",
                list: "TipoDocumentoCheque",
              }}
              value={"22"}
              options={listsProps.TipoDocumentoCheque?.options || []}
              handleChange={handleListChange}
              isClearable={false}
            />
            {METADATA_MODAL_REPORTE_GESTORIA_CHEQUES.map((field) => {
              switch (field.type) {
                case "list":
                  return (
                    <InputList
                      key={field.name}
                      field={field}
                      value={listsProps[field.list]?.value || ""}
                      options={listsProps[field.list]?.options || []}
                      handleChange={handleListChange}
                      isClearable={lists[field.list]?.mostrarVacio || false}
                    />
                  );
                case "number":
                  return (
                    <InputNumber
                      key={field.name}
                      field={field}
                      value={values[field.name] || ""}
                      handleChange={handleChange}
                    />
                  );
                case "text":
                default:
                  return (
                    <InputText
                      handleChange={handleChange}
                      key={field.name}
                      field={field}
                    />
                  );
              }
            })}
            <S.InputFile>
              <input type="file" onChange={convertBase64} />
            </S.InputFile>
          </S.WrapperModalBody>
        );

      default:
        return <p>Unknown type</p>;
    }
  };

  const convertBase64 = (e) => {
    if (e.target.files.length > 0) {
      const file = e.target.files[0];
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function () {
        const base64 = reader.result.toString().replace(/^data:(.*,)?/, "");
        const nameSplit = file.name.split(".");
        setFilename(file.name);
        setData64File(base64);
        setFileExt(nameSplit[nameSplit.length - 1]);
      };
    } else {
      setFilename("");
      setData64File("");
      setFileExt("");
    }
  };

  const confirmAddCheque = () => {
    const data = {};
    const dataDocumentoReserva = {};
    dataDocumentoReserva.tipoDocumento = "22";
    dataDocumentoReserva.contenidobase64 = data64File;
    dataDocumentoReserva.tituloDocumento = filename;
    dataDocumentoReserva.tipoContenido = fileExt;
    data.importe = values["importe"];
    data.serieCheque = values["serieCheque"];
    data.sucursal = values["sucursal"];
    data.beneficiario = listsProps.Beneficiario?.value;
    data.documentoReserva = dataDocumentoReserva;
    confirm(data);
  };

  const buttons = () => {
    switch (type) {
      case "addCheque":
        return (
          <>
            <Button
              label={labelConfirm}
              onClick={confirmAddCheque}
              disabled={filename === "" || data64File === ""}
            />
            <Button label="Cancelar" onClick={cancel} type="cancel" />
          </>
        );

      default:
        return <Button label="Cancelar" onClick={cancel} type="cancel" />;
    }
  };

  return (
    <S.Wrapper onClick={cancel}>
      <S.Content onClick={(event) => event.stopPropagation()}>
        <S.Header>
          <p>{title}</p>
        </S.Header>
        <S.Body>
          {body()}
          <S.Buttons>{buttons()}</S.Buttons>
        </S.Body>
      </S.Content>
    </S.Wrapper>
  );
};

ModalReporteGestoria.propTypes = {
  type: PropTypes.string,
  title: PropTypes.string,
  document: PropTypes.object,
  labelConfirm: PropTypes.string,
  lists: PropTypes.object,
  confirm: PropTypes.func,
  cancel: PropTypes.func,
};
