import styled from "styled-components/macro";

export const Wrapper = styled.div`
  font-size: 0.75rem;
  padding: 1.5rem;
`;
export const Buttons = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-bottom: 1rem;
`;