import styled from "styled-components/macro";
import { COLORES } from "../../constants";

export const Wrapper = styled.div`
  width: 100%;
  background-color: ${COLORES.DEFAULT_MODULE_BACKGROUND};
  margin-bottom: 2rem;
  border-radius: 0.25rem;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  width: 100%;
  height: 2rem;
  color: ${COLORES.DEFAULT_MODULE_HEADER_FONT};
  background-color: ${COLORES.DEFAULT_MODULE_HEADER_BACKGROUND};
  font-weight: bold;
  border-radius: 0.25rem 0.25rem 0 0;

  .first {
    margin-left: 1rem;
  }
  .last {
    margin-right: 1rem;
  }
`;

export const SaveLoad = styled.div`
  display: flex;
  justify-content: flex-end;
  align-content: center;
  align-items: center;
  div.ml {
    margin-left: 0.5rem;

    p {
      display: none;
    }

    select {
      height: 1.25rem;
      min-width: 8rem;
      :focus {
        border-radius: 0.1rem;
        border: thin solid #858585;
        outline: none;
      }
    }

    div {
      min-width: 0;
    }
    button {
      height: 1.25rem;
      padding: 0 0.4rem;
    }
  }
`;

export const Content = styled.form`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  gap: 1rem;
  padding: 1rem;
`;

export const Buttons = styled.div`
  display: flex;
  column-gap: 1rem;
  justify-content: flex-end;
  padding: 1rem;
`;

export const InputGroup = styled.div`
  display: flex;
`;

export const TextoSeparador = styled.div`
grid-column: ${(props) => props.colSpan};

  h2 {
    font-size:1.3em;
    width: 100%;
    text-align: center;
    border-bottom: 1px solid #000;
    line-height: 0.1em;
    margin: 10px 0 20px;
  }
  h2 span {
    background-color: ${COLORES.DEFAULT_MODULE_BACKGROUND};
    padding: 0 10px;
  }
`;
