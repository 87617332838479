import styled from "styled-components/macro";
import { COLORES } from "../../constants";

export const Tr = styled.tr`
  :hover {
    background-color: ${COLORES.ROW_HOVER};
  }

  input[type="checkbox"] {
    vertical-align: bottom;
  }
`;

export const TdButtons = styled.td`
  display: flex;
  button {
    border: none;
    border-radius: 0.1rem;
    padding: 0.2rem 0.5rem;
    color: ${COLORES.ACTIONS_BUTTON_FONT};
    background-color: transparent;
    cursor: pointer;
    a {
      color: ${COLORES.ACTIONS_BUTTON_FONT};
    }

    :hover {
      color: ${COLORES.ACTIONS_BUTTON_FONT_HOVER};
      background-color: ${COLORES.ACTIONS_BUTTON_BACKGROUND_HOVER};
    }
  }
`;
