import React from "react";
import PropTypes from "prop-types";
import * as S from "./styles";
import { InputTextArea } from "../InputTextArea";

export const ModuleAutorizacionFormulario = ({
  metadata,
  values,
  setValues,
  label = "Formulario Autorización y Completado de Tarea",
  columns,
  access,
}) => {
  const handleChange = (prop, value) => {
    const tmpValues = { ...values };
    tmpValues[prop] = value;
    setValues(tmpValues);
  };

  return (
    <S.Wrapper>
      <S.Header>
        <p>{label}</p>
      </S.Header>
      <S.Content columns={columns}>
        {metadata.map((field) => {
          switch (field.type) {
            case "textarea":
              return (
                <InputTextArea
                  key={field.name}
                  field={field}
                  rows={field.rows}
                  value={values[field.name] || ""}
                  handleChange={handleChange}
                  colSpan={field.colSpan}
                  disabled={!access?.Editar || field.disabled}
                />
              );

            default:
              return (
                <S.ReadOnly key={field.name} colSpan={field.colSpan}>
                  <p className="bold">{field.label}</p>
                  <p>{values[field.name] || ""}</p>
                </S.ReadOnly>
              );
          }
        })}
      </S.Content>
    </S.Wrapper>
  );
};

ModuleAutorizacionFormulario.propTypes = {
  metadata: PropTypes.array.isRequired,
  values: PropTypes.object.isRequired,
  setValues: PropTypes.func,
  label: PropTypes.string,
  columns: PropTypes.number,
  access: PropTypes.object,
};
