import styled from "styled-components/macro";

export const Wrapper = styled.div`
  width: 100%;

  p,
  input {
    box-sizing: border-box;
  }

  p {
    height: 0.85rem;
    font-weight: bold;
  }

  input {
    height: 1.25rem;
    width: 1rem;
    border: thin solid #858585;
    border-radius: 0.1rem;
  }
`;
