import styled from "styled-components/macro";
import { COLORES } from "../../constants";

export const Nav = styled.nav`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: sticky;
  top: 0;
  background-color: ${COLORES.MENU_BACKGROUND_ENV};
  font-size: 0.75rem;
  letter-spacing: 0.1rem;
  z-index: 5;
  padding-right: 1.5rem;
`;

export const Img = styled.img`
  height: 1.5rem;
  margin: 0 0.75rem;
`;

export const MenuContainer = styled.div`
  ul {
    display: flex;
    height: 2.5rem;
    align-items: center;
  }

  li {
    list-style: none;
    line-height: 1rem;
    padding: 0.75rem 0rem;

    :hover {
      background-color: ${COLORES.MENU_HOVER_BACKGROUND};
    }

    &.active {
      font-weight: bold;
    }
  }

  a,
  div {
    display: inline;
    color: ${COLORES.MENU_FONT};
    text-decoration: none;
    padding: 0.75rem 1.5rem;
    cursor: pointer;
  }
`;

export const MenuDropdownContainer = styled.div`
  display: flex;
  align-items: center;
  color: ${COLORES.MENU_FONT};
  margin-left: auto;
`;

export const User = styled.p``;

export const ButtonsContainer = styled.div`
  align-self: center;
`;

export const Dropdown = styled.div`
  margin-left: 0.5rem;
  padding: 0.2rem 1rem;
  border: thin solid ${COLORES.MENU_USER_BORDER};
  border-radius: 0.2rem;
  cursor: pointer;
`;

export const Ul = styled.ul`
  list-style: none;
  background-color: ${COLORES.MENU_USER_BACKGROUND};
  border: thin solid ${COLORES.MENU_USER_BORDER};
  border-radius: 0.2rem;
  font-size: 0.9rem;
  position: absolute;
  right: 1.5rem;
  text-align: right;

  a {
    text-decoration: none;
  }

  #last {
    border-bottom: none;
    span {
      color: ${COLORES.MENU_USER_ICON};
      margin-right: 0.5rem;
      margin-left: 0.25rem;
    }
  }
`;

export const Li = styled.li`
  font-size: 0.75rem;
  font-weight: normal;
  margin: auto;
  height: 1.75rem;
  line-height: 1.75rem;
  padding: 0 0.5rem;
  color: ${COLORES.MENU_USER_FONT};
  border-bottom: thin solid ${COLORES.MENU_USER_BORDER};
  cursor: pointer;
  :hover {
    background-color: ${COLORES.MENU_USER_HOVER};
  }
`;
