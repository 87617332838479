const {
  NODE_ENV,
  REACT_APP_BASE_URL,
  REACT_APP_TENANT_ID,
  REACT_APP_CLIENT_ID,
} = process.env;
export const endpoint = REACT_APP_CLIENT_ID;

export const DEVELOP = NODE_ENV === "development";

export const msalConfig = {
  auth: {
    clientId: `${REACT_APP_CLIENT_ID}`,
    authority: `https://login.microsoftonline.com/${REACT_APP_TENANT_ID}`,
    redirectUri: DEVELOP ? "http://localhost:3000" : REACT_APP_BASE_URL,
  },
  cache: {
    cacheLocation: "localStorage",
    storeAuthStateInCookie: false,
  },
};

export const loginRequest = {
  scopes: ["User.Read"],
};
