import React, { useState, useEffect } from "react";
import * as S from "./styles";
import * as API from "../../api";
import { useParams, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { ModuleTareaDatosGenerales } from "../../components/ModuleTareaDatosGenerales";
import { ModuleTareaDatosFormalizacion } from "../../components/ModuleTareaDatosFormalizacion";
import { Button } from "../../components/Button";
import { Loading } from "../../components/Loading";
import {
  METADATA_CONFIRMACION_ASISTENCIA_FIRMA_DATOS_GENERALES,
  METADATA_CONFIRMACION_ASISTENCIA_FIRMA_DATOS_FORMALIZACION,
} from "../../constants";
import { usePermissions } from "../../context/PermissionsContext";

export const ConfirmacionAsistenciaFirma = () => {
  const { permissions } = usePermissions();
  const { idTarea } = useParams();
  const navigate = useNavigate();
  const [lists, setLists] = useState({});
  const [datosGenerales, setDatosGenerales] = useState({});
  const [datosFormalizacion, setDatosFormalizacion] = useState({});
  const [loading, setLoading] = useState(true);
  const [values, setValues] = useState({});
  const [options, setOptions] = useState({});
  useEffect(() => {
    let isMounted = true;

    API.abrirTarea({ tarea: idTarea })
      .then((res) => {
        if (isMounted) {
          setDatosGenerales(res.datosGenerales);
          setDatosFormalizacion(res.datosFormalizacion);
        }
      })
      .catch((error) => API.DEVELOP && console.log(error))
      .finally(() => setLoading(false));

    API.obtenerListas("ConfirmacionAsistenciaFirma", 0)
      .then((res) => isMounted && setLists(res))
      .catch((error) => API.DEVELOP && console.log(error));

    return () => (isMounted = false);
  }, [idTarea]);


  useEffect(() => {
    const options = {};
    const values = {};
    Object.entries(lists).forEach(([list, listProps]) => {
      options[list] = convertOptions(listProps.valores);
    });

    const setDefaultValue = (e) => {
      switch (e.type) {
        case "list":
          values[e.name] = lists[e.list]?.valorPorDefecto || "";
          break;

        default:
          values[e.name] = "";
      }
    };

    METADATA_CONFIRMACION_ASISTENCIA_FIRMA_DATOS_FORMALIZACION.forEach(
      (props) => {
        if (props.name === "_group")
          props.fields.forEach((props) => setDefaultValue(props));
        else setDefaultValue(props);
      }
    );

    setValues(values);
    setOptions(options);
  }, [lists]);

  const convertOptions = (options) =>
    options.map((opt) => ({
      value: opt.valor,
      label: opt.etiqueta,
    }));

  const completarConfirmacionAsistenciaFirma = () => {
    setLoading(true);
    API.completarConfirmacionAsistenciaFirma({
      IdTarea: idTarea,
      ConfirmacionAsistenciaFirma: values["confirmacionAsistenciaFirma"],
    })
      .then((res) => {
        if (res.resultadoPeticion === 1) {
          toast.success("Tarea Completada");
          navigate("/");
        } else toast.error(res.mensajeUsuario);
      })
      .catch((error) => API.DEVELOP && console.log(error))
      .finally(() => setLoading(false));
  };

  return (
    <S.Wrapper>
      {loading && <Loading />}

      <ModuleTareaDatosGenerales
        data={datosGenerales}
        metadata={METADATA_CONFIRMACION_ASISTENCIA_FIRMA_DATOS_GENERALES}
      />

      <ModuleTareaDatosFormalizacion
        data={datosFormalizacion}
        metadata={METADATA_CONFIRMACION_ASISTENCIA_FIRMA_DATOS_FORMALIZACION}
        lists={lists}
        columns={6}
        values={values}
        setValues={setValues}
        options={options}
        setOptions={setOptions}
        access={permissions.ConfirmacionAsistenciaFirma}
      />

      <S.Buttons>
        <a
          href={`/FichaReserva/${datosGenerales.idReserva}`}
          target="_blank"
          rel="noreferrer"
        >
          <Button label="Ver Ficha" disabled={!datosGenerales.idReserva} />
        </a>
        <Button
          label="Completar"
          onClick={completarConfirmacionAsistenciaFirma}
          disabled={!permissions.ConfirmacionAsistenciaFirma?.Completar}
        />
      </S.Buttons>
    </S.Wrapper>
  );
};
