import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import * as S from "./styles";
import * as API from "../../api";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import "@ckeditor/ckeditor5-build-classic/build/translations/es";
import { InputEmail } from "../InputEmail";
import { InputText } from "../InputText";
import { Button } from "../Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileLines } from "@fortawesome/free-solid-svg-icons";

export const ModalEmail = ({
  title,
  docsReserva,
  docsActivos,
  confirm,
  cancel,
}) => {
  const [values, setValues] = useState({});
  const [defaultOptions, setDefaultOptions] = useState([]);
  const [emailBody, setEmailBody] = useState("");
  const [docsReserveSelected, setDocsReserveSelected] = useState([]);
  const [docsActiveSelected, setDocsActiveSelected] = useState([]);
  const [visibleCC, setVisibleCC] = useState(false);
  const [visibleCCO, setVisibleCCO] = useState(false);
  useEffect(() => {
    const convertOptions = (options) =>
      options.map((opt) => ({ value: opt.valor, label: opt.etiqueta }));

    API.obtenerDireccionesCorreo({ filtro: "" })
      .then((res) => setDefaultOptions(convertOptions(res.valores)))
      .catch((error) => API.DEVELOP && console.log(error));
  }, []);

  const handleChange = (field, value) => {
    const tmpValues = { ...values };
    tmpValues[field] = value;
    setValues(tmpValues);
  };

  const handleReserveSelectionChange = (e) => {
    const { id, checked } = e.target;
    const tmpDocsReserveSelected = [...docsReserveSelected];

    if (checked) {
      tmpDocsReserveSelected.push(id);
    } else {
      const index = tmpDocsReserveSelected.indexOf(id);
      tmpDocsReserveSelected.splice(index, 1);
    }
    setDocsReserveSelected(tmpDocsReserveSelected);
  };

  const handleActiveSelectionChange = (e) => {
    const { id, checked } = e.target;
    const tmpDocsActiveSelected = [...docsActiveSelected];

    if (checked) {
      tmpDocsActiveSelected.push(id);
    } else {
      const index = tmpDocsActiveSelected.indexOf(id);
      tmpDocsActiveSelected.splice(index, 1);
    }
    setDocsActiveSelected(tmpDocsActiveSelected);
  };

  const toggleCC = () => setVisibleCC(!visibleCC);
  const toggleCCO = () => setVisibleCCO(!visibleCCO);

  const sendEmail = () => {
    const data = {};
    data.para = values.to?.map((e) => e.label);
    data.cc = values.cc?.map((e) => e.label) || [];
    data.cco = values.cco?.map((e) => e.label) || [];
    data.asunto = values.asunto;
    data.mensaje = emailBody;
    data.documentosReserva = docsReserveSelected;
    data.documentosActivo = docsActiveSelected;
    confirm(data);
  };

  return (
    <S.Wrapper>
      <S.Content onClick={(event) => event.stopPropagation()}>
        <S.Header>
          <p>{title}</p>
        </S.Header>
        <S.Body>
          <div className="conCopia">
            <span onClick={toggleCC}>CC</span>
            <span onClick={toggleCCO}>CCO</span>
          </div>
          <S.WrapperModalBody>
            <InputEmail
              listName="to"
              label="Para"
              defaultOptions={defaultOptions}
              selectedOptions={values.to}
              handleChange={handleChange}
              canBeEmpty={true}
            />
            {visibleCC && (
              <InputEmail
                listName="cc"
                label="CC"
                defaultOptions={defaultOptions}
                selectedOptions={values.cc}
                handleChange={handleChange}
                canBeEmpty={true}
              />
            )}
            {visibleCCO && (
              <InputEmail
                listName="cco"
                label="CCO"
                defaultOptions={defaultOptions}
                selectedOptions={values.cco}
                handleChange={handleChange}
                canBeEmpty={true}
              />
            )}
            <InputText
              field={{ name: "asunto", label: "Asunto" }}
              handleChange={handleChange}
            />

            <CKEditor
              editor={ClassicEditor}
              config={{
                placeholder: "Introduce el cuerpo del correo...",
                language: "es",
              }}
              onReady={(editor) => {
                // You can store the "editor" and use when it is needed.
                // console.log("Editor is ready to use!", editor);
              }}
              onChange={(event, editor) => {
                const data = editor.getData();
                // console.log({ event, editor, data });
                setEmailBody(data);
              }}
              onBlur={(event, editor) => {
                // console.log("Blur.", editor);
              }}
              onFocus={(event, editor) => {
                // console.log("Focus.", editor);
              }}
            />

            <S.Attachments>
              <p className="title">
                Selecciona los documentos que deseas adjuntar
              </p>
              <S.Header>
                <p>Documentos de la Reserva</p>
              </S.Header>
              <S.ContentDocs>
                {docsReserva.map((doc) => (
                  <div key={doc.id}>
                    <input
                      type="checkbox"
                      id={doc.id}
                      onChange={handleReserveSelectionChange}
                    />
                    <p className="file">{doc.nombreDocumento}</p>
                  </div>
                ))}
              </S.ContentDocs>
              <S.Header>
                <p>Documentos de los Activos</p>
              </S.Header>
              <S.ContentDocs>
                {docsActivos.map((data) => (
                  <S.Active key={data.activo.id}>
                    <p>
                      <FontAwesomeIcon icon={faFileLines} />
                      <span className="bold">Nº de PRINEX:</span>
                      <span>{data.activo.numeroPrinex}</span>
                    </p>
                    {data.documentos.map(
                      (doc) =>
                        doc.nombreDocumento && (
                          <div key={doc.id}>
                            <input
                              type="checkbox"
                              id={doc.id}
                              onChange={handleActiveSelectionChange}
                            />
                            <p className="file">{doc.nombreDocumento}</p>
                          </div>
                        )
                    )}
                  </S.Active>
                ))}
              </S.ContentDocs>
            </S.Attachments>
          </S.WrapperModalBody>
          <S.Buttons>
            <Button
              label="Enviar"
              onClick={sendEmail}
              disabled={
                !values.to ||
                values.to?.length === 0 ||
                !values.asunto ||
                !emailBody
              }
            />
            <Button label="Cancelar" onClick={cancel} type="cancel" />
          </S.Buttons>
        </S.Body>
      </S.Content>
    </S.Wrapper>
  );
};

ModalEmail.propTypes = {
  title: PropTypes.string,
  cancel: PropTypes.func,
};
