import React, { useState, useEffect } from "react";
import * as S from "./styles";
import * as API from "../../api";
import { useParams, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { ModuleTareaDatosGenerales } from "../../components/ModuleTareaDatosGenerales";
import { ModuleTareaDatosPostFirma } from "../../components/ModuleTareaDatosPostFirma";
import { Button } from "../../components/Button";
import { Loading } from "../../components/Loading";
import { ModuleTareaDocsReserva } from "../../components/ModuleTareaDocsReserva";
import { ModuleTareaDocsActivos } from "../../components/ModuleTareaDocsActivos";
import { ModuleTareaCheques } from "../../components/ModuleTareaCheques";
import { ModalReporteGestoria } from "../../components/ModalReporteGestoria";
import { ModalDocumentos } from "../../components/ModalDocumentos";
import {
  METADATA_REPORTE_GESTORIA_DATOS_GENERALES,
  METADATA_REPORTE_GESTORIA_DATOS_POST_FIRMA,
  METADATA_DOCS_RESERVA,
  METADATA_REPORTE_GESTORIA_DOCS_ACTIVOS,
  METADATA_MODULE_CHEQUES,
} from "../../constants";
import { usePermissions } from "../../context/PermissionsContext";

export const ReporteGestoria = () => {
  const { permissions } = usePermissions();
  const { idTarea } = useParams();
  const navigate = useNavigate();
  const [lists, setLists] = useState({});
  const [datosGenerales, setDatosGenerales] = useState({});
  const [datosPostFirma, setDatosPostFirma] = useState({});
  const [docsReserva, setDocsReserva] = useState([]);
  const [docsActivos, setDocsActivos] = useState([]);
  const [cheques, setCheques] = useState([]);
  const [activo, setActivo] = useState({});
  const [triggerRefresh, setTriggerRefresh] = useState(false);
  const [visibleModal, setVisibleModal] = useState("");
  const [loading, setLoading] = useState(true);
  const [values, setValues] = useState({});
  const [options, setOptions] = useState({});
  useEffect(() => {
    let isMounted = true;

    API.abrirTarea({ tarea: idTarea })
      .then((res) => {
        if (isMounted) {
          setDatosGenerales(res.datosGenerales);
          setDatosPostFirma(res.datosPostFirma);
          setDocsReserva(res.documentosReserva);
          setDocsActivos(res.documentosActivos);
          setCheques(res.cheques);
        }
      })
      .catch((error) => API.DEVELOP && console.log(error))
      .finally(() => setLoading(false));

    API.obtenerListas("ReporteGestoria", 0)
      .then((res) => isMounted && setLists(res))
      .catch((error) => API.DEVELOP && console.log(error));

    return () => (isMounted = false);
  }, [idTarea]);

  useEffect(() => {
    if (triggerRefresh) {
      API.abrirTarea({ tarea: idTarea }).then((res) => {
        setDatosPostFirma(res.datosPostFirma);
        setDocsReserva(res.documentosReserva);
        setDocsActivos(res.documentosActivos);
        setCheques(res.cheques);
      });
      setTriggerRefresh(false);
    }
  }, [idTarea, triggerRefresh]);

  useEffect(() => {
    const options = {};
    const values = {};
    Object.entries(lists).forEach(([list, listProps]) => {
      options[list] = convertOptions(listProps.valores);
    });

    const setInitialValue = (e) => {
      switch (e.type) {
        case "list":
          values[e.name] = datosPostFirma[e.name]?.valor || "";
          if (lists[e.list]?.listasHijas.length > 0) {
            const field = METADATA_REPORTE_GESTORIA_DATOS_POST_FIRMA.find(
              (field) => field.list === lists[e.list]?.nombreLista
            );
            const tmpOptions = { ...options };

            lists[field.list].listasHijas.forEach((list) => {
              const listaRelacional = {
                nombreLista: field.list,
                idEntidadRelacional: values[field.name],
              };
              const data = {
                lista: list,
                listaRelacional,
              };
              API.obtenerLista(data).then((res) => {
                tmpOptions[list] = convertOptions(res.valores);
                setOptions(tmpOptions);
              });
            });
          }
          break;
        case "textarea":
        case "text":
        case "date":
        case "datetime-local":
          values[e.name] = datosPostFirma[e.name] || "";
          break;
        case "number":
          values[e.name] = datosPostFirma[e.name] || "";
          break;
        default:
          values[e.name] = "";
      }
    };

    METADATA_REPORTE_GESTORIA_DATOS_POST_FIRMA.forEach((props) => {
      if (props.name === "_group")
        props.fields.forEach((props) => setInitialValue(props));
      else setInitialValue(props);
    });

    setValues(values);
    setOptions(options);
  }, [lists, datosPostFirma]);

  const convertOptions = (options) =>
    options.map((opt) => ({
      value: opt.valor,
      label: opt.etiqueta,
    }));

  const requestAddCheque = () => {
    setVisibleModal("addCheque");
  };

  const addCheque = (data) => {
    setLoading(true);
    setVisibleModal("");
    data.idTarea = idTarea;
    data.documentoReserva.idTarea = idTarea;
    API.crearChequeReporteGestoria(data)
      .then((res) => {
        setTriggerRefresh(true);
        toast.success("Cheque creado");
      })
      .catch((error) => {
        toast.error("No se pudo crear el cheque\n" + error.msg);
        API.DEVELOP && console.log(error);
      })
      .finally(() => setLoading(false));
  };

  const requestAddReserveDocument = () => {
    setVisibleModal("addReserveDocument");
  };

  const addReserveDocument = (data) => {
    setLoading(true);
    setVisibleModal("");
    data.idTarea = idTarea;

    API.crearDocumentoReserva(data)
      .then((res) => {
        setTriggerRefresh(true);
        toast.success("Documento creado");
      })
      .catch((error) => {
        toast.error("No se pudo crear el documento\n" + error.msg);
        API.DEVELOP && console.log(error);
      })
      .finally(() => setLoading(false));
  };

  const requestAddActiveDocument = (activo) => {
    setActivo(activo);
    setVisibleModal("addActiveDocument");
  };

  const addActiveDocument = (data) => {
    setLoading(true);
    setVisibleModal("");
    data.idTarea = idTarea;
    data.idReserva = datosGenerales.idReserva;
    data.idActivo = activo.id;

    API.crearDocumentoActivo(data)
      .then((res) => {
        setTriggerRefresh(true);
        toast.success("Documento creado");
      })
      .catch((error) => {
        toast.error("No se pudo crear el documento\n" + error.msg);
        API.DEVELOP && console.log(error);
      })
      .finally(() => setLoading(false));
  };

  const guardarCompletarTareaReporteGestoria = (esCompletar) => {
    setLoading(true);
    API.guardarCompletarTareaReporteGestoria({
      IdTarea: idTarea,
      Completar: esCompletar,
      FirmaEnHora: values["firmaEnHora"],
      IdMotivo: values["motivo"],
      ProtocoloVenta: values["protocoloVenta"],
      FechaEnvioChequeValija: values["fechaEnvioChequeValija"],
      Notaria: values["notaria"],
      Comentarios: values["comentarios"],
    })
      .then((res) => {
        if (res.resultadoPeticion === 1 && esCompletar === true) {
          toast.success("Tarea Completada");
          navigate("/");
        } else if (res.resultadoPeticion === 1 && esCompletar === false) {
          toast.success("Tarea Guardada");
        } else toast.error(res.mensajeUsuario);
      })
      .catch((error) => API.DEVELOP && console.log(error))
      .finally(() => setLoading(false));
  };

  const renderModal = () => {
    switch (visibleModal) {
      case "addCheque":
        return (
          <ModalReporteGestoria
            type={visibleModal}
            title="Nuevo cheque"
            labelConfirm="Guardar"
            lists={lists}
            confirm={addCheque}
            cancel={() => setVisibleModal("")}
          />
        );

      case "addReserveDocument":
        return (
          <ModalDocumentos
            type="addDocument"
            title="Nuevo tipo de documento"
            labelConfirm="Guardar"
            lists={lists}
            documentsListName="TipoDocumentoReserva"
            confirm={addReserveDocument}
            cancel={() => setVisibleModal("")}
          />
        );

      case "addActiveDocument":
        return (
          <ModalDocumentos
            type="addDocument"
            title="Nuevo tipo de documento"
            labelConfirm="Guardar"
            lists={lists}
            documentsListName="TipoDocumentoActivo"
            confirm={addActiveDocument}
            cancel={() => setVisibleModal("")}
          />
        );

      default:
        return null;
    }
  };

  return (
    <S.Wrapper>
      {loading && <Loading />}
      {visibleModal && renderModal()}

      <ModuleTareaDatosGenerales
        data={datosGenerales}
        metadata={METADATA_REPORTE_GESTORIA_DATOS_GENERALES}
      />

      <ModuleTareaDatosPostFirma
        data={datosPostFirma}
        metadata={METADATA_REPORTE_GESTORIA_DATOS_POST_FIRMA}
        lists={lists}
        columns={4}
        values={values}
        setValues={setValues}
        options={options}
        setOptions={setOptions}
        access={permissions.ReporteGestoria}
      />

      <ModuleTareaDocsReserva
        data={docsReserva}
        metadata={METADATA_DOCS_RESERVA}
        requestAdd={requestAddReserveDocument}
        refreshDocs={() => setTriggerRefresh(true)}
        setLoading={setLoading}
        access={permissions.ReporteGestoria}
      />

      <ModuleTareaCheques
        data={cheques}
        metadata={METADATA_MODULE_CHEQUES}
        refreshDocs={() => setTriggerRefresh(true)}
        requestAdd={requestAddCheque}
        setLoading={setLoading}
        access={permissions.ReporteGestoria}
      />

      <ModuleTareaDocsActivos
        data={docsActivos}
        metadata={METADATA_REPORTE_GESTORIA_DOCS_ACTIVOS}
        requestAdd={requestAddActiveDocument}
        requestEdit={()=>null}
        refreshDocs={() => setTriggerRefresh(true)}
        setLoading={setLoading}
        access={permissions.ReporteGestoria}
      />

      <S.Buttons>
        <a
          href={`/FichaReserva/${datosGenerales.idReserva}`}
          target="_blank"
          rel="noreferrer"
        >
          <Button label="Ver Ficha" disabled={!datosGenerales.idReserva} />
        </a>
        <div>
          <Button
            label="Guardar"
            onClick={() => guardarCompletarTareaReporteGestoria(false)}
            disabled={!permissions.ReporteGestoria?.Editar}
          />
          <Button
            label="Completar"
            onClick={() => guardarCompletarTareaReporteGestoria(true)}
            disabled={!permissions.ReporteGestoria?.Completar}
          />
        </div>
      </S.Buttons>
    </S.Wrapper>
  );
};
