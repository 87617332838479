import React, { useState, useEffect } from "react";
import * as S from "./styles";
import * as API from "../../api";
import { useParams, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { ModuleTareaDatosGenerales } from "../../components/ModuleTareaDatosGenerales";
import { ModuleTareaDocsReserva } from "../../components/ModuleTareaDocsReserva";
import { ModuleTareaDocsActivos } from "../../components/ModuleTareaDocsActivos";
import { ModuleTareaDatosFormalizacion } from "../../components/ModuleTareaDatosFormalizacion";
import { ModuleTablaBasica } from "../../components/ModuleTablaBasica";
import { ModalTareaRevisionOP } from "../../components/ModalTareaRevisionOP";
import { ModalDocumentos } from "../../components/ModalDocumentos";
import { ModalEmail } from "../../components/ModalEmail";
import { Button } from "../../components/Button";
import { Loading } from "../../components/Loading";
import {
  METADATA_REVISION_OP_DATOS_GENERALES,
  METADATA_DOCS_RESERVA,
  METADATA_REVISION_OP_DOCS_ACTIVOS,
  METADATA_REVISION_OP_DATOS_FORMALIZACION,
  METADATA_REVISION_OP_NOTAS_SIMPLES,
  MESSAGE_REVISION_OP_FISCALIDAD_COMPLETADA,
  MESSAGE_REVISION_OP_FISCALIDAD_NO_COMPLETADA_INFORMADA,
  MESSAGE_REVISION_OP_FISCALIDAD_NO_COMPLETADA_NO_INFORMADA,
  METADATA_AUDITORIA_CAMPOS,
  METADATA_OBSERVACIONES_REVOP_FIRMA,
} from "../../constants";
import { ModuleAuditoriaCampos } from "../../components/ModuleAuditoriaCampos";
import { ModuleObservaciones } from "../../components/ModuleObservaciones";
import { usePermissions } from "../../context/PermissionsContext";

export const TareaRevisionOP = () => {
  const { permissions } = usePermissions();
  const { idTarea } = useParams();
  const navigate = useNavigate();
  const [lists, setLists] = useState({});
  const [datosAuditoria, setDatosAuditoria] = useState([]);
  const [datosGenerales, setDatosGenerales] = useState({});
  const [datosFormalizacion, setDatosFormalizacion] = useState({});
  const [docsReserva, setDocsReserva] = useState([]);
  const [docsActivos, setDocsActivos] = useState([]);
  const [notasSimples, setNotasSimples] = useState([]);
  const [values, setValues] = useState({});
  const [options, setOptions] = useState({});
  const [activo, setActivo] = useState({});
  const [visibleModal, setVisibleModal] = useState("");
  const [triggerRefresh, setTriggerRefresh] = useState(false);
  const [loading, setLoading] = useState(true);
  const [messageFiscalidad, setMessageFiscalidad] = useState("");

  useEffect(() => {
    let isMounted = true;

    API.abrirTarea({ tarea: idTarea })
      .then((res) => {
        if (isMounted) {
          setDatosGenerales(res.datosGenerales);
          setDatosFormalizacion(res.datosFormalizacion);
          setDocsReserva(res.documentosReserva);
          setDocsActivos(res.documentosActivos);
          setNotasSimples(res.revisionNotasSimples);
          refreshMessageFormalizacion(
            res.datosFormalizacion.fiscalidadYaCompletada,
            res.datosFormalizacion.regimenFiscal
          );
          setDatosAuditoria(res.notificacion);
        }
      })
      .catch((error) => API.DEVELOP && console.log(error))
      .finally(() => setLoading(false));

    API.obtenerListas("RevisionOP", 0)
      .then((res) => {
        const auxLists = res;
        API.obtenerLista({
          lista: "Gestoria",
          listaRelacional: {
            nombreLista: "Tarea",
            idEntidadRelacional: idTarea,
          },
        })
          .then((res) => {
            auxLists.Gestoria = res;
          })
          .catch((error) => API.DEVELOP && console.log(error))
          .finally(() => isMounted && setLists(auxLists));
      })
      .catch((error) => API.DEVELOP && console.log(error));

    return () => (isMounted = false);
  }, [idTarea]);

  useEffect(() => {
    if (triggerRefresh) {
      API.abrirTarea({ tarea: idTarea }).then((res) => {
        setDocsReserva(res.documentosReserva);
        setDocsActivos(res.documentosActivos);
        setDatosAuditoria(res.notificacion);
      });
      setTriggerRefresh(false);
    }
  }, [idTarea, triggerRefresh]);

  useEffect(() => {
    const options = {};
    const values = {};
    Object.entries(lists).forEach(([list, listProps]) => {
      options[list] = convertOptions(listProps.valores);
    });

    const setInitialValue = (e) => {
      switch (e.type) {
        case "text":
        case "textarea":
          values[e.name] = datosFormalizacion[e.name] || "";
          break;

        case "number":
          values[e.name] = datosFormalizacion[e.name];
          break;

        case "checkbox":
          values[e.name] = datosFormalizacion[e.name] || false;
          break;

        case "list":
          values[e.name] =
            datosFormalizacion[e.name]?.valor ||
            lists[e.list]?.valorPorDefecto ||
            "";

          if (lists[e.list]?.listasHijas.length > 0) {
            const field = METADATA_REVISION_OP_DATOS_FORMALIZACION.find(
              (field) => field.list === lists[e.list]?.nombreLista
            );
            const tmpOptions = { ...options };

            const listaRelacional = {
              nombreLista: field.list,
              idEntidadRelacional: values[field.name],
            };
            lists[field.list].listasHijas.forEach((list) => {
              const data = {
                lista: list,
                listaRelacional,
              };
              API.obtenerLista(data).then((res) => {
                tmpOptions[list] = convertOptions(res.valores);
                setOptions(tmpOptions);
              });
            });
          }
          break;

        default:
          values[e.name] = "";
      }
    };

    METADATA_REVISION_OP_DATOS_FORMALIZACION.forEach((props) => {
      if (props.name === "_group")
        props.fields.forEach((props) => setInitialValue(props));
      else setInitialValue(props);
    });

    setValues(values);
    setOptions(options);
  }, [lists, datosFormalizacion]);

  const convertOptions = (options) =>
    options.map((opt) => ({
      value: opt.valor,
      label: opt.etiqueta,
    }));

  const solicitarAsistenciaFirma = () => {
    setLoading(true);
    setVisibleModal("");
    const data = { idTarea };

    API.crearTareaConfirmacionAsistenciaFirma(data)
      .then((res) => {
        if (res.resultadoPeticion === 1) {
          refreshDatosFormalizacion();
          toast.success("Tarea Asistencia Firma Creada");
        } else
          toast.error(
            `No se pudo crear la Tarea Asistencia Firma\n${res.mensajeUsuario}`
          );
      })
      .catch((error) => {
        toast.error("No se pudo crear la Tarea Asistencia Firma");
        API.DEVELOP && console.log(error);
      })
      .finally(() => setLoading(false));
  };

  const solicitarVistoBuenoSSJJ = (data) => {
    setLoading(true);
    setVisibleModal("");
    data.idTarea = idTarea;

    API.crearTareaMinutaSSJJ(data)
      .then((res) => {
        if (res.resultadoPeticion === 1) {
          refreshDatosFormalizacion();
          toast.success("Tarea Minuta SSJJ Creada");
        } else
          toast.error(
            `No se pudo crear la Tarea Minuta SSJJ\n${res.mensajeUsuario}`
          );
      })
      .catch((error) => {
        toast.error("No se pudo crear la Tarea Minuta SSJJ");
        API.DEVELOP && console.log(error);
      })
      .finally(() => setLoading(false));
  };

  const solicitarFiscalidad = () => {
    setLoading(true);
    setVisibleModal("");
    const data = { idTarea };

    API.crearTareaTipoFiscalidad(data)
      .then((res) => {
        if (res.resultadoPeticion === 1) {
          refreshDatosFormalizacion();
          toast.success("Tarea Fiscalidad Creada");
        } else
          toast.error(
            `No se pudo crear la Tarea Fiscalidad\n${res.mensajeUsuario}`
          );
      })
      .catch((error) => {
        toast.error("No se pudo crear la Tarea Fiscalidad");
        API.DEVELOP && console.log(error);
      })
      .finally(() => setLoading(false));
  };

  const solicitarAutorizacion = () => {
    setLoading(true);
    setVisibleModal("");
    const data = { idTarea };

    API.solicitarEnviarAutorizacion(data)
      .then((res) => {
        if (res.resultadoPeticion === 1) {
          toast.success("Solicitud Autorización Creada");
        } else
          toast.error(
            `No se pudo crear la Solicitud Autorización\n${res.mensajeUsuario}`
          );
      })
      .catch((error) => {
        toast.error("No se pudo crear la Solicitud Autorización");
        API.DEVELOP && console.log(error);
      })
      .finally(() => setLoading(false));
  };

  const refreshDatosFormalizacion = () => {
    API.obtenerTareaRevisionOP({ idTarea })
      .then((res) => {
        setDatosFormalizacion(res.datosFormalizacion);
        refreshMessageFormalizacion(
          res.datosFormalizacion.fiscalidadYaCompletada,
          res.datosFormalizacion.regimenFiscal
        );
      })
      .catch((error) => API.DEVELOP && console.log(error))
      .finally(() => setLoading(false));
  };

  const refreshMessageFormalizacion = (fiscalidadCompleted, regimenFiscal) => {
    if (fiscalidadCompleted === true) {
      setMessageFiscalidad(
        MESSAGE_REVISION_OP_FISCALIDAD_COMPLETADA.replace("{1}", regimenFiscal)
      );
    } else if (
      fiscalidadCompleted === false &&
      regimenFiscal !== "Fiscalidad no definida"
    ) {
      setMessageFiscalidad(
        MESSAGE_REVISION_OP_FISCALIDAD_NO_COMPLETADA_INFORMADA.replace(
          "{1}",
          regimenFiscal
        )
      );
    } else {
      setMessageFiscalidad(
        MESSAGE_REVISION_OP_FISCALIDAD_NO_COMPLETADA_NO_INFORMADA
      );
    }
  };

  const especificarMotivoCambioFechaPrevista = (data) => {
    setVisibleModal("");
    const tmpValues = { ...values };
    tmpValues["observacionesMotivoCambio"] = data.observacionesMotivoCambio;
    tmpValues["llegadaDeApoderado"] = data.llegadaDeApoderado;
    tmpValues["tipoAplazamiento"] = data.tipoAplazamiento;
    setValues(tmpValues);
    guardarCompletarTarea(data);
  };

  const requestGuardarCompletarTarea = (completar) => {
    let f = datosFormalizacion["fechaPrevistaFirma"];
    let h = datosFormalizacion["horaPrevistaFirma"];

    //Concatena la fecha y hora ya existentes
    if (f && h) {
      f = f.split("/");
      f.push(h);
    }

    let fn = values.nuevaFechaPrevistaFirma ?? "";

    //Iguala formato a otra fecha
    if (fn) {
      fn = values.nuevaFechaPrevistaFirma.replace("T", "-").split("-");
      fn = [fn[2], fn[1], fn[0], fn[3].concat(":00")];
    }

    if (
      (fn &&
        fn != null &&
        f != null &&
        f &&
        f?.toString() !== fn?.toString()) ||
      values.aplazadoSinFecha
    ) {
      setVisibleModal("especificarMotivoCambioFechaPrevista");
    } else {
      if (fn != null && f != null && f?.toString() === fn?.toString())
        values.nuevaFechaPrevistaFirma = null;
      guardarCompletarTarea(null, completar);
    }
  };

  const guardarCompletarTarea = (dataFechaPrev, completar) => {
    const data = { idTarea, completar };
    METADATA_REVISION_OP_DATOS_FORMALIZACION.forEach((field) => {
      if (field.type !== "readOnly") data[field.name] = values[field.name];
    });
    data.observacionesFirma = datosGenerales.observacionesFirma;
    data.cambioFechaGestoria =
      datosFormalizacion.gestoria !== values.gestoria ||
      (!values.aplazadoSinFecha && values.nuevaFechaPrevistaFirma !== "");
    delete data.provincia;

    data.observacionesMotivoCambio =
      dataFechaPrev?.observacionesMotivoCambio ?? "";
    data.llegadaDeApoderado = dataFechaPrev?.llegadaDeApoderado ?? null;
    data.tipoAplazamiento = dataFechaPrev?.tipoAplazamiento ?? null;

    API.guardarCompletarTareaRevisionOP(data)
      .then((res) => {
        if (res.resultadoPeticion === 1) {
          if (completar) {
            toast.success("Tarea Completada");
            navigate("/");
          } else {
            toast.success("Tarea Guardada");
            refreshDatosFormalizacion();
          }
        } else if (res.resultadoPeticion === 4 && completar === true) {
          setVisibleModal("solicitarAutorizacion");
        } else
          toast.error(
            `No se pudo ${completar ? "Completar" : "Guardar"} la Tarea\n${
              res.mensajeUsuario
            }`
          );
      })
      .catch((error) => {
        toast.error(
          `No se pudo ${completar ? "Completar" : "Guardar"} la Tarea`
        );
        API.DEVELOP && console.log(error);
      });
  };

  const requestAddReserveDocument = () => {
    setVisibleModal("addReserveDocument");
  };

  const addReserveDocument = (data) => {
    setLoading(true);
    setVisibleModal("");
    data.idTarea = idTarea;

    API.crearDocumentoReserva(data)
      .then((res) => {
        setTriggerRefresh(true);
        toast.success("Documento creado");
        if (data.tipoDocumento === "39" && datosGenerales.crmForzadas) {
          refreshDatosFormalizacion();
          toast.success("Tarea Visto BuenoSSJJ Creada");
        }
      })
      .catch((error) => {
        toast.error("No se pudo crear el documento\n" + error.msg);
        API.DEVELOP && console.log(error);
      })
      .finally(() => setLoading(false));
  };

  const requestAddActiveDocument = (activo) => {
    setActivo(activo);
    setVisibleModal("addActiveDocument");
  };

  const addActiveDocument = (data) => {
    setLoading(true);
    setVisibleModal("");
    data.idTarea = idTarea;
    data.idReserva = datosGenerales.idReserva;
    data.idActivo = activo.id;

    API.crearDocumentoActivo(data)
      .then((res) => {
        setTriggerRefresh(true);
        toast.success("Documento creado");
      })
      .catch((error) => {
        toast.error("No se pudo crear el documento\n" + error.msg);
        API.DEVELOP && console.log(error);
      })
      .finally(() => setLoading(false));
  };

  const renderModal = () => {
    switch (visibleModal) {
      case "especificarMotivoCambioFechaPrevista":
        return (
          <ModalTareaRevisionOP
            type={visibleModal}
            title="Motivo cambio de fecha prevista"
            labelConfirm="Confirmar"
            lists={lists}
            confirm={especificarMotivoCambioFechaPrevista}
            cancel={() => setVisibleModal("")}
          />
        );
      case "solicitarAsistenciaFirma":
        return (
          <ModalTareaRevisionOP
            type={visibleModal}
            title="Confirmación Asistencia Firma"
            labelConfirm="Confirmar"
            confirm={solicitarAsistenciaFirma}
            cancel={() => setVisibleModal("")}
          />
        );

      case "solicitarVistoBuenoSSJJ":
        return (
          <ModalTareaRevisionOP
            type={visibleModal}
            title="Confirmación Solicitar Visto Bueno SSJJ"
            labelConfirm="Confirmar"
            lists={lists}
            confirm={solicitarVistoBuenoSSJJ}
            cancel={() => setVisibleModal("")}
          />
        );

      case "solicitarFiscalidad":
        return (
          <ModalTareaRevisionOP
            type={visibleModal}
            title="Confirmación Solicitar Fiscalidad"
            labelConfirm="Confirmar"
            confirm={solicitarFiscalidad}
            cancel={() => setVisibleModal("")}
            messageFiscalidad={messageFiscalidad}
          />
        );

      case "solicitarAutorizacion":
        return (
          <ModalTareaRevisionOP
            type={visibleModal}
            title="Confirmación Solicitar Autorización"
            labelConfirm="Solicitar"
            confirm={solicitarAutorizacion}
            cancel={() => setVisibleModal("")}
          />
        );

      case "addReserveDocument":
        return (
          <ModalDocumentos
            type="addDocument"
            title="Nuevo tipo de documento"
            labelConfirm="Guardar"
            lists={lists}
            documentsListName="TipoDocumentoReserva"
            confirm={addReserveDocument}
            cancel={() => setVisibleModal("")}
          />
        );

      case "addActiveDocument":
        return (
          <ModalDocumentos
            type="addDocument"
            title="Nuevo tipo de documento"
            labelConfirm="Guardar"
            lists={lists}
            documentsListName="TipoDocumentoActivo"
            confirm={addActiveDocument}
            cancel={() => setVisibleModal("")}
          />
        );

      case "sendEmail":
        return (
          <ModalEmail
            title="Envío de correo"
            docsReserva={docsReserva}
            docsActivos={docsActivos}
            confirm={sendEmail}
            cancel={() => setVisibleModal("")}
          />
        );

      default:
        return null;
    }
  };

  const sendEmail = (data) => {
    setLoading(true);
    setVisibleModal("");
    data.idTarea = idTarea;
    API.enviarCorreo(data)
      .then((res) => {
        if (res.resultadoPeticion === 1) {
          toast.success("Correo enviado");
        } else
          toast.error("No se pudo enviar el correo\n" + res.mensajeUsuario);
      })
      .catch((error) => {
        toast.error("No se pudo enviar el correo\n" + error.msg?.title);
        API.DEVELOP && console.log(error);
      })
      .finally(() => setLoading(false));
  };

  const ocultarCampo = (idNotificacion, ocultar, eliminar) => {
    setLoading(true);
    const data = {};
    data.IdNotificacion = idNotificacion;
    data.Visibilidad = ocultar;
    data.Eliminado = eliminar;
    API.guardarVisibilidadNotificacionRevOp(data)
      .then((res) => {
        setTriggerRefresh(true);
        if (res.resultadoPeticion === 1) toast.success(res.mensajeUsuario);
        else toast.error(res.mensajeUsuario);
      })
      .catch((error) => API.DEVELOP && console.log(error))
      .finally(() => setLoading(false));
  };
  return (
    <S.Wrapper>
      {loading && <Loading />}
      {visibleModal && renderModal()}

      {datosGenerales.esVentaDigital && (
        <h1 className="highlighted">Venta Digital</h1>
      )}
      {datosGenerales.crmForzadas && (
        <h1 className="highlighted2">Forzada CRM</h1>
      )}

      <ModuleAuditoriaCampos
        data={datosAuditoria}
        metadata={METADATA_AUDITORIA_CAMPOS}
        ocultar={ocultarCampo}
        access={permissions.TareaAnalisis}
      />

      <ModuleTareaDatosGenerales
        data={datosGenerales}
        metadata={METADATA_REVISION_OP_DATOS_GENERALES}
      />

      <ModuleTareaDatosFormalizacion
        data={datosFormalizacion}
        metadata={METADATA_REVISION_OP_DATOS_FORMALIZACION}
        lists={lists}
        values={values}
        setValues={setValues}
        options={options}
        setOptions={setOptions}
        revisionOP={true}
        requestAsistenciaFirma={() =>
          setVisibleModal("solicitarAsistenciaFirma")
        }
        requestFiscalidad={() => setVisibleModal("solicitarFiscalidad")}
        requestVistoBuenoSSJJ={() => setVisibleModal("solicitarVistoBuenoSSJJ")}
        access={permissions.RevisionOP}
      />
      <ModuleObservaciones
        metadata={METADATA_OBSERVACIONES_REVOP_FIRMA}
        values={datosGenerales}
        setValues={setDatosGenerales}
        access={permissions.RevisionOP}
      />
      <ModuleTareaDocsReserva
        data={docsReserva}
        metadata={METADATA_DOCS_RESERVA}
        requestAdd={requestAddReserveDocument}
        refreshDocs={() => setTriggerRefresh(true)}
        setLoading={setLoading}
        access={permissions.RevisionOP}
      />
      {datosGenerales.esFastrack && <h1 className="highlighted3">Fastrack</h1>}
      <ModuleTareaDocsActivos
        data={docsActivos}
        metadata={METADATA_REVISION_OP_DOCS_ACTIVOS}
        requestAdd={requestAddActiveDocument}
        requestEdit={() => {}}
        refreshDocs={() => setTriggerRefresh(true)}
        setLoading={setLoading}
        access={permissions.RevisionOP}
      />

      <ModuleTablaBasica
        data={notasSimples}
        metadata={METADATA_REVISION_OP_NOTAS_SIMPLES}
        label="Revisión de Notas Simples"
      />

      <S.Buttons>
        <a
          href={`/FichaReserva/${datosGenerales.idReserva}`}
          target="_blank"
          rel="noreferrer"
        >
          <Button label="Ver Ficha" disabled={!datosGenerales.idReserva} />
        </a>
        <div>
          <Button label="Email" onClick={() => setVisibleModal("sendEmail")} />
          <Button
            label="Guardar"
            onClick={() => requestGuardarCompletarTarea(false)}
            disabled={!permissions.RevisionOP?.Editar}
          />
          <Button
            label="Completar"
            onClick={() => requestGuardarCompletarTarea(true)}
            disabled={!permissions.RevisionOP?.Completar}
          />
        </div>
      </S.Buttons>
    </S.Wrapper>
  );
};
