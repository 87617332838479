import styled from "styled-components/macro";
import { COLORES } from "../../constants";

export const Wrapper = styled.div`
  position: fixed;
  background-color: ${COLORES.LOADING_BACKGROUND};
  z-index: 100;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow-y: auto;
`;

export const Content = styled.div`
  font-size: 0.85rem;
  background-color: ${COLORES.MODAL_BACKGROUND};
  border-radius: 0.25rem;
  width: 50%;
  display: flex;
  flex-direction: column;
  margin: 3rem auto;
`;

export const Header = styled.div`
  font-weight: bold;
  color: ${COLORES.MODAL_HEADER_FONT};
  background-color: ${COLORES.MODAL_HEADER_BACKGROUND};
  padding: 0 1rem;
  border-radius: 0.25rem 0.25rem 0 0;
  height: 2rem;
  line-height: 2rem;
`;

export const Body = styled.div`
  margin: 2rem;
  display: flex;
  justify-content: center;
  flex-direction: column;

  .bold {
    font-weight: bold;
    margin-bottom: 0.25rem;
  }
  input {
    margin-bottom: 0.5rem;
  }
`;

export const Html = styled.div`
  margin-top: 0.5rem;
  background-color: #f8f8f8;
  border: thin solid lightgrey;
  border-radius: 0.1rem;
  height: 10rem;
  overflow-y: scroll;
`;

export const Buttons = styled.div`
  display: flex;
  margin-top: 2rem;
  justify-content: center;
  column-gap: 2rem;
`;
