import { useEffect, useState } from "react";
import { usePermissions } from "../../context/PermissionsContext";

export const ProtectedRoute = ({ permit, redirectPath = "/", children }) => {
  const { permissions } = usePermissions();
  const [showChildren, setShowChildren] = useState(false);

  useEffect(() => {
    if (Object.keys(permissions).length > 0) {
      setShowChildren(permissions[permit]?.Visualizar);
    }
  }, [permissions, permit]);

  return showChildren ? children : <></>;
};
