import styled from "styled-components/macro";
import { COLORES } from "../../constants";

export const Wrapper = styled.div`
  position: fixed;
  background-color: ${COLORES.LOADING_BACKGROUND};
  z-index: 100;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Content = styled.div`
  font-size: 0.85rem;
  background-color: ${COLORES.MODAL_BACKGROUND};
  border-radius: 0.25rem;
  width: 33%;

  display: flex;
  flex-direction: column;

  animation: BounceEffect 0.15s linear forwards;
  @keyframes BounceEffect {
    0% {
      transform: scale(0);
    }
    50% {
      transform: scale(1.1);
    }
    100% {
      transform: scale(1);
    }
  }
`;

export const Header = styled.div`
  font-weight: bold;
  color: ${COLORES.MODAL_HEADER_FONT};
  background-color: ${COLORES.MODAL_HEADER_BACKGROUND};
  padding: 0 1rem;
  border-radius: 0.25rem 0.25rem 0 0;
  height: 2rem;
  line-height: 2rem;
`;

export const Body = styled.div`
  margin: 2rem;
  display: flex;
  justify-content: center;
  flex-direction: column;
`;

export const Buttons = styled.div`
  display: flex;
  margin-top: 2rem;
  justify-content: center;
  column-gap: 2rem;
`;

export const WrapperModalBody = styled.div`
  display: grid;
  grid-template-columns: repeat(${(props) => props.columns || 1}, 1fr);
  gap: 1rem;

  p {
    margin-bottom: 0.25rem;
  }

  select {
    width: 100%;
  }

  h3 {
    margin: 0;
  }
`;

export const InputFile = styled.label`
  grid-column: 1 / 3;
  margin-top: 1rem;

  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    margin-right: 0.5rem;
    color: ${COLORES.MODAL_UPLOAD_ICON};
  }

  input[type="file"] {
    display: none;
  }
`;
