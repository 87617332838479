import React, { useState } from "react";
import PropTypes from "prop-types";
import * as S from "./styles";
import * as API from "../../api";
import { UploadDownload } from "../UploadDownload";
import { COLORES } from "../../constants";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faCaretRight,
    faCaretDown,
    faLock,
    faFileLines,
    faTrash
} from "@fortawesome/free-solid-svg-icons";
export const RowActive = ({
    data,
    metadata,
    requestAdd,
    requestEdit,
    refreshDocs,
    setLoading,
    access,
    requestBorradoTarea,
}) => {
    const [visible, setVisible] = useState(false);

    const indicator = (status) => (
        <svg
            width="16"
            height="18"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
        >
            <circle
                cx="8"
                cy="10"
                r="8"
                fill={COLORES.STATUS[status] || COLORES.STATUS_DEFAULT}
            />
        </svg>
    );
    return (
        <>
            <S.TrHeader onClick={() => setVisible(!visible)}>
                <td colSpan={metadata.length}>
                    <FontAwesomeIcon
                        icon={visible ? faCaretDown : faCaretRight}
                        size="lg"
                    />
                    {requestAdd && (
                        <S.DivButton
                            onClick={(event) => {
                                event.stopPropagation();
                                requestAdd(data.activo);
                            }}
                            disabled={!access?.Editar}
                            type="button"
                        >
                            <FontAwesomeIcon icon={faFileLines} />
                        </S.DivButton>
                    )}
                    {data.activo.numeroPrinex && (
                        <>
                            <span className="bold">Nº de PRINEX:</span>
                            <span>{data.activo.numeroPrinex}</span>
                        </>
                    )}
                    {data.activo.sociedad && (
                        <>
                            <span className="bold">Ent.:</span>
                            <span>{data.activo.sociedad}</span>
                        </>
                    )}
                    {data.activo.tipo && (
                        <>
                            <span className="bold">Tipo:</span>
                            <span>{data.activo.tipo}</span>
                        </>
                    )}
                    {data.activo.finca && (
                        <>
                            <span className="bold">Finca:</span>
                            <span>{data.activo.finca}</span>
                        </>
                    )}
                    {data.activo.importeIBI && (
                        <>
                            <span className="bold">ImpIBI:</span>
                            <span>{data.activo.importeIBI}</span>
                        </>
                    )}
                    {data.activo.registro && (
                        <>
                            <span className="bold">Registro:</span>
                            <span>{data.activo.registro}</span>
                        </>
                    )}
                    {data.activo.redComercial && (
                        <>
                            <span className="bold">Red Comercial:</span>
                            <span>{data.activo.redComercial}</span>
                        </>
                    )}
                    {data.activo.direccion && (
                        <>
                            <span className="bold">Dirección:</span>
                            <span>{data.activo.direccion}</span>
                        </>
                    )}
                    {data.activo.provincia && (
                        <>
                            <span className="bold">Prov.:</span>
                            <span>{data.activo.provincia}</span>
                        </>
                    )}
                    {data.activo.referenciaCatastral && (
                        <>
                            <span className="bold">Ref. Catastral:</span>
                            <span>{data.activo.referenciaCatastral}</span>
                        </>
                    )}
                    {data.activo.importeActivo && (
                        <>
                            <span className="bold">Importe:</span>
                            <span>{data.activo.importeActivo}</span>
                        </>
                    )}
                    {data.activo.idActivoPrinex && (
                        <>
                            <span className="bold">ID Activo Prinex:</span>
                            <span>{data.activo.idActivoPrinex}</span>
                        </>
                    )}
                    {data.activo.idActivoAnticipa && (
                        <>
                            <span className="bold">ID Activo Anticipa:</span>
                            <span>{data.activo.idActivoAnticipa}</span>
                        </>
                    )}
                    {data.activo.idCliente && (
                        <>
                            <span className="bold">ID Cliente:</span>
                            <span>{data.activo.idCliente}</span>
                        </>
                    )}
                </td>
            </S.TrHeader>
            
            {visible &&
                data.documentos.map((document) => (
                    
                    <S.TrDoc className= {document.esFastrack==="SI" ? "highlighted4" : "COLORES.ROW_HOVER"}
                        key={document.id}
                        onDoubleClick={() =>
                            document.esEditable && requestEdit(data.activo, document)
                        }
                    >
                        {metadata.map((field) => (
                            <td key={field.name} title={document[field.name]}>
                                {field.name === "tipo" && !document.esEditable && (
                                    <FontAwesomeIcon icon={faLock} />
                                )}

                                {field.name === "tipo" && document.esEliminable && requestBorradoTarea &&
                                    <S.DivButton onClick={(event) => {
                                        event.stopPropagation();
                                        requestBorradoTarea(data.activo, document);
                                    }}
                                        type="button" >
                                        <FontAwesomeIcon icon={faTrash} />
                                    </S.DivButton>}
                                {field.type === undefined && document[field.name]}
                                {field.type === "status" && indicator(document[field.name])}
                                {field.type === "file" && (
                                    <UploadDownload
                                        id={document.id}
                                        nombreDocumento={document[field.name]}
                                        uploadEnabled={access?.Editar != null && access?.Editar && document.esEditable}
                                        uploadService={API.actualizarDocumentoActivo}
                                        uploadSuccess={refreshDocs}
                                        setLoading={setLoading}
                                    />
                                )}
                            </td>
                        ))}
                    </S.TrDoc>
                ))}
        </>
    );
};

RowActive.propTypes = {
    data: PropTypes.object,
    metadata: PropTypes.array,
    requestAdd: PropTypes.func,
    requestEdit: PropTypes.func,
    refreshDocs: PropTypes.func,
    setLoading: PropTypes.func,
    access: PropTypes.object,
    requestBorradoTarea: PropTypes.func,
};
