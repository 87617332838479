import React from "react";
import PropTypes from "prop-types";
import * as S from "./styles";

export const InputNumber = ({ field, value, handleChange, disabled }) => {
  return (
    <S.Wrapper>
      <p>{field.label}</p>
      <input
        type="number"
        name={field.name}
        value={value}
        onChange={(e) => handleChange(field.name, e.target.value)}
        step={field.step}
        min={field.min}
        disabled={disabled}
      />
    </S.Wrapper>
  );
};

InputNumber.propTypes = {
  field: PropTypes.object,
  value: PropTypes.string,
  handleChange: PropTypes.func,
  disabled: PropTypes.bool,
};
